import React from 'react'
import "./InfraListings.css"
import { useState } from 'react';
import { Link } from 'react-router-dom';

const InfraListings = () => {

  const [showLiveOrders, setShowLiveOrder] = useState(true);
  const [activeButton, setActiveButton] = useState('marketplace');
  const [listingComponent, setListingComponent] = useState('marketplace');

  const handleButtonClick = (button) => {
    setListingComponent(button);
    setActiveButton(button)

  }

  const renderListingComponent = () => {
    switch (listingComponent) {
      case 'marketplace':
        return (
          <>
            {showLiveOrders ? (
              <>
                <div className="listingOrderComponent">
                  <div className="listingComponentBtnBox">
                    <button className="listingComponentBtn" onClick={()=> setShowLiveOrder(false)}>SOLD OUT</button>
                    <button className="listingComponentBtn active">LIVE ORDERS</button>
                  </div>
                  <h5 className="listingComponentHeading">LIVE ORDERS</h5>
                  <div className="ComponentTableBox">
                    <table className='listingOrderTable'>
                      <tr>
                        <th className='listingTableHeading'>Date Listed</th>
                        <th className='listingTableHeading'>Seller</th>
                        <th className='listingTableHeading'>Project Name</th>
                        <th className='listingTableHeading'>Area Sq.ft</th>
                        <th className='listingTableHeading'>Price Sq.ft</th>
                        <th className='listingTableHeading'>Action</th>
                      </tr>
                      <tr>
                        <td>DATA</td>
                        <td>DATA</td>
                        <td>DATA</td>
                        <td>DATA</td>
                        <td>DATA</td>
                        <td>View Order Detail</td>
                      </tr>
                    </table>

                  </div>

                </div>
              </>
            ) : (
              <>
                <div className="listingOrderComponent">
                  <div className="listingComponentBtnBox">
                    <button className="listingComponentBtn active">SOLD OUT</button>
                    <button className="listingComponentBtn" onClick={()=> setShowLiveOrder(true)}>LIVE ORDERS</button>
                  </div>
                  <h5 className="listingComponentHeading">SOLD ORDERS</h5>
                  <div className="ComponentTableBox">
                    <table className='listingOrderTable'>
                      <tr>
                        <th className='listingTableHeading'>Date Listed</th>
                        <th className='listingTableHeading'>Seller</th>
                        <th className='listingTableHeading'>Buyer</th>
                        <th className='listingTableHeading'>Project Name</th>
                        <th className='listingTableHeading'>Area Sq.ft</th>
                        <th className='listingTableHeading'>Price Sq.ft</th>
                        <th className='listingTableHeading'>Sold In</th>
                      </tr>
                      <tr>
                        <td>DATE</td>
                        <td>DATE</td>
                        <td>DATE</td>
                        <td>DATE</td>
                        <td>DATE</td>
                        <td>DATE</td>
                        <td>DATE</td>
                      </tr>
                    </table>

                  </div>

                </div>
              </>
            )}
          </>);

        break;
      case 'myOrders':
        return (<>
          <div className="listingOrderComponent">
            <h5 className="listingComponentHeading">MY ORDERS</h5>
            <div className="ComponentTableBox">
              <table className='listingOrderTable'>
                <tr>
                  <th className='listingTableHeading'>Date</th>
                  <th className='listingTableHeading'>ID Number</th>
                  <th className='listingTableHeading'>Project Name</th>
                  <th className='listingTableHeading'>Total Offers</th>
                  <th className='listingTableHeading'>Area sq.ft</th>
                  <th className='listingTableHeading'>Demand Ammount</th>
                  <th className='listingTableHeading'>Order Status</th>
                  <th className='listingTableHeading'>Action</th>
                </tr>
                <tr>
                  <td>DATE</td>
                  <td>DATE</td>
                  <td>DATE</td>
                  <td>DATE</td>
                  <td>DATE</td>
                  <td>DATE</td>
                  <td>DATE</td> 
                  <td>
                    <Link to='/MarketplaceOrderdetails'>
                    View Detail
                    </Link> 
                  </td>
                  
                </tr>
              </table>

            </div>


          </div>

        </>);

        break;
      case 'listArea':
        return (<>
          <div className="listingOrderComponent">
            <h5 className="listingComponentHeading">LIST AREA</h5>
            <div className="ComponentTableBox">
              <table className='listingOrderTable'>
                <tr>
                  <th className='listingTableHeading'>Date</th>
                  <th className='listingTableHeading'>ID Number</th>
                  <th className='listingTableHeading'>Project Name</th>
                  <th className='listingTableHeading'>Round Number</th>
                  <th className='listingTableHeading'>Area sq.ft</th>
                  <th className='listingTableHeading'>Ammount Invested</th>
                  <th className='listingTableHeading'>Value (Today)</th>
                  <th className='listingTableHeading'>Action</th>
                </tr>
                <tr>
                  <td>DATE</td>
                  <td>DATE</td>
                  <td>DATE</td>
                  <td>DATE</td>
                  <td>DATE</td>
                  <td>DATE</td>
                  <td>DATE</td>
                  <td>DATE</td>
                </tr>
              </table>

            </div>

          </div>

        </>);

        break;

      default:
        break;
    }
  }



  return (
    <>
      <div className="infraListingsMainBox">
        <div className="infrListingsHeadBox">
          <h4 className='infra-listings-Heading-title'> Infra Listings</h4>
          <p>Your journey to financial freedom begins here.</p>
          <div className="listingsHeadBtnBox">
            <button className={activeButton === 'marketplace' ? 'active' : 'listingsButton'} onClick={() => handleButtonClick('marketplace')}>Marketplace</button>
            <button className={activeButton === 'myOrders' ? 'active' : 'listingsButton'} onClick={() => handleButtonClick('myOrders')}>My Orders</button>
            <button className={activeButton === 'listArea' ? 'active' : 'listingsButton'} onClick={() => handleButtonClick('listArea')}>List Area</button>
          </div>
        </div>
        <div className="infraListingsBtnBox">
          <button className="listingsButtons">Flexible Area</button>
          <button className="listingsButtons">Purchase Unit</button>
        </div>

        <div className="listingsOrderComponentBox">
          {renderListingComponent()}
        </div>
      </div>
    </>
  )
}

export default InfraListings;