import React, { useState, useEffect } from "react";
// import "./PurchaseDetails.css";
import axios from 'axios';
import { useLocation } from "react-router-dom";
import { MdCloudUpload } from "react-icons/md";


const AccountDetails = () => {
  const location = useLocation();
  const { tower, order_data } = location.state || {};
  const [selectedImage, setSelectedImage] = useState(null);


  const [uploadPaymentSlip, setuploadPaymentSlip] = useState('Upload Slip')
  const [paymentSlip, setPaymentSlip] = useState(null);


  const uploadFileName = (event) => {
    const file = event.target.files[0];
    setuploadPaymentSlip(event.target.files[0].name.slice(0, 9) + "...");
    setPaymentSlip(file);
    const uploadFileName = (event) => {
      setSelectedImage(event.target.files[0]);
    };
  }



  return (

    <div className="PurchaseDetailsContainer">
      <div className="summaryHeading">
        {" "}
        <p>Payment Details</p>{" "}
      </div>
      <p
        style={{
          backgroundColor: "#F9F9F9",
          fontSize: "19px",
          fontWeight: "bold",
          paddingTop: "30px",
          paddingBottom: "30px",
          paddingLeft: "20px",
          wordWrap: "break-word",
        }}
      >
        You Have Successfully Pledged 2,222.22 Sq.ft. Area For 2 Bed
        Room Apartment in SAK Tower.<br />

      </p>

      <div className="PurchaseDetailsMakepayment">
        <div className="PurchaseDetailsMakepaymentLeftDiv">
          <div className="PurchaseDetailsMakepaymentBankName">
            <p
              style={{
                fontSize: "17px",
                fontWeight: "bold",
                paddingTop: "30px",
                wordWrap: "break-word",
              }}
            >
              {" "}
              Bank Name{" "}
            </p>
            <select id="select-option" name="select-option">
              <option value="option1">HBL</option>
              <option value="option2">ABL</option>
              <option value="option3">NBP</option>
            </select>
          </div>
        </div>
        <div className="PurchaseDetailsMakepaymentRightDiv">
          <div className="AccountDetails">
            <p className="BankDetailsParagrapgh"> </p>

            <p className="BankDetailsParagrapgh">
              <b> Account Title:</b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; SAK
            </p>

            <p className="BankDetailsParagrapgh">
              <b>Account Number:</b>
              &nbsp;&nbsp;&nbsp;&nbsp; XXXXXXXXXXXXXX
            </p>
            <p className="BankDetailsParagrapgh">
              <b>IBAN:</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

              XXXXXXXXXXXXXX </p>
            <p className="BankDetailsParagrapgh">
              <b>Branch:</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              DHA Phase 2 ISB </p>
          </div>
        </div>
      </div>
      <div className="PurchaseDetailsMakepayment">
        <div className="PurchaseDetailsMakepaymentLeftDiv">
          <p
            style={{
              fontSize: "18px",
              fontWeight: "bold",
              paddingTop: "30px",
              paddingLeft: "20px",
              wordWrap: "break-word",
            }}
          >
            1) Upload Payment Verification/ Scanned Copy
          </p>
          <h3
            style={{
              fontSize: "18px",
              fontWeight: "bold",
              paddingTop: "40px",
              paddingLeft: "80px",
              wordWrap: "break-word",
            }}
          >
            Step 1
          </h3>
          <p
            style={{
              //       fontSize: "18px",
              //       fontWeight: "bold",
              paddingTop: "10px",
              paddingLeft: "78px",
              wordWrap: "break-word",
            }}
          >
            Please click on the upload button to add your photo <br />
            or scan of the deposit confirmation slip or document.
          </p>
          <button className="upload-btn-payment">
            <MdCloudUpload style={{ fontSize: '2.2rem', marginRight: '0.5rem' }} />{uploadPaymentSlip}
            <input type="file" id="paymentSlip" name="paymentSlip" accept=".png, .jpg, .pdf" max-size="5000000" multiple="false" className='' onChange={uploadFileName} />

          </button>
        </div>
        <div className="PurchaseDetailsMakepaymentRightDiv">

          <div className="uploadImageDiv"> <p> <b>Image</b></p></div>

        </div>

      </div>
      <div className="PurchaseDetailsParaDiv">
        <p>
          For additional information or to sumbit an inquiry, please contact our
          team
        </p>
      </div>
      <div className="salesInfoBoxes">
        <div className="Box" id="saleInfoBox1">
          <p
            style={{
              fontSize: "17px",
              fontWeight: "bold",
              paddingTop: "10px",
              wordWrap: "break-word",
            }}
          >
            Sales Agent
          </p>
          <p
            style={{
              fontSize: "15px",
              fontWeight: "bold",
              paddingTop: "10px",
              wordWrap: "break-word",
            }}
          >
            Afnan
          </p>
        </div>
        <div className="Box" id="saleInfoBox2">
          <p
            style={{
              fontSize: "17px",
              fontWeight: "bold",
              paddingTop: "10px",
              wordWrap: "break-word",
            }}
          >
            Email
          </p>
          <p
            style={{
              fontSize: "15px",
              fontWeight: "bold",
              paddingTop: "10px",
              wordWrap: "break-word",
            }}
          >
            Afnan@gmail.com
          </p>
        </div>
        <div className="Box" id="saleInfoBox3">
          <p
            style={{
              fontSize: "17px",
              fontWeight: "bold",
              paddingTop: "10px",
              wordWrap: "break-word",
            }}
          >
            Phone Number
          </p>
          <p
            style={{
              fontSize: "15px",
              fontWeight: "bold",
              paddingTop: "10px",
              wordWrap: "break-word",
            }}
          >
            03164865548
          </p>
        </div>
      </div>
    </div>
  );
}

export default AccountDetails
