import React, { useState, useRef, useEffect } from 'react';
import './Signup.css';
import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai';
import logo from '../assets/infra logo-01.png';
import GooglLogo from '../assets/google.png';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../config';
import { css } from '@emotion/react';
import { SyncLoader } from 'react-spinners';
import Cookies from 'js-cookie';


export const Signup = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const emailInputRef = useRef(null); // Create a ref for the email input field


  useEffect(() => {
    const cookieValue = Cookies.get('_auth_type');
    console.log(cookieValue);

  }, []);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const signupAction = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      alert('Passwords do not match');
    }
    if (password === confirmPassword) {
      try {
        setLoading(true);

        await axios.post(`${API_URL}/api/signup`, {
          email: email,
          password: password,
        });

        alert('User created successfully');
        navigate('/');
      } catch (error) {
        if (error.response && error.response.status === 400) {
          alert('User already exists');
        } else {
          console.log(error);
        }
      } finally {
        setLoading(false);
      }
    }

  };

  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    // Focus on the email input field when the component mounts
    emailInputRef.current.focus();
  }, []);

  return (
    <>
      <div className="sNavbar__img">
        <img className="sNavbar__logo" src={logo} alt="" />
      </div>
      <div className="sContainer">
        <div className="sContainer1">
          <h1 className="form-title">Sign Up</h1>
          <form action="#">
            <div className="main-user-info">
              <div className="input-box">
                <label htmlFor="email">Email</label>
                <input
                  type="text"
                  id="email"
                  name="email"
                  placeholder="example@gmail.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  ref={emailInputRef} // Add the ref to the email input field
                  tabIndex="1" // Set the tabindex to 1
                  required
                />
              </div>

              <div className="input-box">
                <label htmlFor="pswd">Password</label>
                <input
                  type={showPassword ? 'text' : 'password'}
                  id="pswd"
                  name="pswd"
                  placeholder="Enter Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  tabIndex="2" // Set the tabindex to 2
                  required
                />
                <button
                  type="button"
                  className="eyeBtn"
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? (
                    <AiOutlineEye size={28} />
                  ) : (
                    <AiOutlineEyeInvisible size={28} />
                  )}
                </button>
              </div>

              <div className="input-box">
                <label htmlFor="confPswd">Confirm Password</label>
                <input
                  type={showPassword ? 'text' : 'password'}
                  id="confPswd"
                  name="confPswd"
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  tabIndex="3" // Set the tabindex to 3
                  required
                />
                <button
                  type="button"
                  className="eyeBtn"
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? (
                    <AiOutlineEye size={28} />
                  ) : (
                    <AiOutlineEyeInvisible size={28} />
                  )}
                </button>
              </div>
            </div>

            <div className="termSign">
              <p>
                By clicking "Sign Up," you acknowledge that you have reviewed
                and accepted our{' '}
                <a href="#">Terms and Conditions</a> and{' '}
                <a href="#">Privacy Policy</a>
              </p>
            </div>

            <div className="sign-btn">
              <button onClick={signupAction} disabled={loading} tabIndex="4">
                {loading ? (
                  <SyncLoader
                    css={css`
                      display: inline-block;
                      vertical-align: middle;
                    `}
                    size={10}
                    color={'#ffffff'}
                    loading={loading}
                  />
                ) : (
                  'Sign Up'
                )}
              </button>
            </div>

            <hr />
            <p className="orText">or</p>

            <div className="gIcon">
              <button className="gbtn" tabIndex="5" type="button" onClick={() => window.location.href = `${API_URL}/api/public/auth/google/callback`}>
                Sign up with Google
                <img className="gLogo" alt="" src={GooglLogo} />
              </button>
            </div>

            <p className="LogInlink">
              Already have an account? &nbsp; <Link to="/">Login here!&nbsp; </Link>
            </p>
          </form>
        </div>
      </div>
    </>
  );
};

export default Signup;
