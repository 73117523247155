import React from 'react'
import { useState } from 'react';

function Notification() {

  const [smsIsOn, smsSetIsOn] = useState(false);
  const [pushIsOn, pushSetIsOn] = useState(false);

  const pushHandleClick = () => {
    pushSetIsOn(!pushIsOn);
  };
  const smsHandleClick = () => {
    smsSetIsOn(!smsIsOn);
  };

  return (

    <>
      <div className="personalDetailMainBox">
        <h4 className="notificationHeading">Notifications</h4>
        <p className="notificationDiscription">
          Please set the notifications as per your convinience to recieve updates regarding projects and Investments.
        </p>
        <div className="notificationBtnMainBox">
          <div className="notificationBtnBox">
            <div className="notificationBtnItems">
              Push Notification - Toggle
            </div>
            <div className="notificationBtnItems">
              <button onClick={pushHandleClick} className={pushIsOn ? "notificationBtnON" : 'notificationBtnOFF'}>
                {pushIsOn ? 'ON' : 'OFF'}
              </button>
            </div>
          </div>
          <div className="notificationBtnBox">
            <div className="notificationBtnItems">
              SMS Notification - Toggle
            </div>
            <div className="notificationBtnItems">
              <button onClick={smsHandleClick} className={smsIsOn ? "notificationBtnON" : 'notificationBtnOFF'}>
                {smsIsOn ? 'ON' : 'OFF'}
              </button>
            </div>
          </div>
        </div>

        <div className="updateBtnBox">
          <button className='notificationUpdateBtn'>Update</button>
        </div>
      </div>

    </>
  )
}

export default Notification
