import React from "react";
import "./PaymentSummary.css";
import { Link } from "react-router-dom";

const PaymentSummary = () => {
  return (
    <div className="PaymentSummaryContainer">
      <div className="SummaryParentDiv">
        <div className="SummaryChildDivLeft">
          <div className="summaryDiv">
            {" "}
            <div className="summaryHeading">
              {" "}
              <p>Summary</p>{" "}
            </div>
            <div class="detailsRow">
              <div class="left-div">Lower Ground Floors GR-1</div>
              <div class="right-div">756 Sq.ft</div>
            </div>
            <div class="detailsRow">
              <div class="left-div">Price Per Sq.ft</div>
              <div class="right-div">23,000 PKR</div>
            </div>
            <div class="detailsRow">
              <div class="left-div1">Total Price </div>
              <div class="right-div">17,388,000 PKR</div>
            </div>
            <div className="order-checkbox">
              <input type="checkbox" />
              <p>I agree to all <Link>Terms and Conditions </Link> of this Project.</p>
            </div>
            <div className="buttonDiv">
              {" "}
              <Link to="/accountDetails">
                <button className="purchaseNow-button">Purchase Now</button>
              </Link>
            </div>
          </div>
          {/* <hr style={{ borderColor: '#066b6e' }} /> */}
        </div>
        {/* <div className="SummaryChildDivRight">Child 2</div> */}
      </div>
    </div>
  );
};

export default PaymentSummary;
