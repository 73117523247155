import React, { useEffect, useState } from 'react';
import vector from '../../assets/Vector (1).svg';
import assets from '../../assets/rectanglelast.png';
import flag from '../../assets/flgimg.png';
// import { Link, Route, Routes } from 'react-router-dom';
import { Link, Route, Routes } from 'react-router-dom';
import Adduser from "./Adduser";
import axios from 'axios';
import './info.css';
import { MdCloudUpload } from "react-icons/md";
import { API_URL } from '../../config';
import Cookies from 'js-cookie';


function Infopage() {

    const [showAddKen, setshowAddKen] = useState(false);
    const [Cnic, setCnic] = useState('');
    const [FbrTaxFiler, setFbrTaxFiler] = useState('');
    const [CnicFront, setCnicFront] = useState(null);
    const [CnicBack, setCnicBack] = useState(null);
    const [uploadTxtF, setuploadTxtF] = useState('Upload Front')
    const [uploadTxtB, setuploadTxtB] = useState('Upload Back')
    const [uploadKenText, setuploadKenTxt] = useState('Upload Pic')


    const [Fullname, setFullname] = useState('');
    const [Photo, setPhoto] = useState('');
    const [Email, setEmail] = useState('');
    // const [PhoneCode, setPhoneCode] = useState('');
    const [Phone, setPhone] = useState('');
    const [Address, setAddress] = useState('');
    const [kenCNIC, setKenCNIC] = useState('');
    const [Gender, setGender] = useState('');
    const [kenProfilePic, setKenProfilePic] = useState('');

    useEffect(() => {
        loadLegalInfo();
        loadNextOfKin();
    }, []);

    const handleAddKenClick = () => {
        setshowAddKen(true);
    };

    const handleAddKenCancelClick = () => {
        setshowAddKen(false);
    };

    const loadNextOfKin = async () => {
        const token = Cookies.get("_auth");

        const config = {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        };

        // Make a request with the config object for next of Kin
        axios.get(`${API_URL}/api/user_next_of_kin`, config)
            .then(response => {
                const nextOfKin = response.data;
                if (typeof nextOfKin === 'object' && Object.keys(nextOfKin).length > 0) {
                    setFullname(response.data.full_name);
                    setPhoto(response.data.photo);
                    setEmail(response.data.email);
                    setPhone(response.data.phone);
                    setAddress(response.data.address);
                    setKenCNIC(response.data.cnic);
                    setGender(response.data.gender);
                }
            })
            .catch(error => {
                console.log(error);
            });
    };

    const handleNextOfKinSubmit = async (e) => {
        e.preventDefault();

        try {
            const token = Cookies.get("_auth");
            const response = await axios.patch(`${API_URL}/api/user_next_of_kin`, {
                full_name: Fullname,
                email: Email,
                address: Address,
                gender: Gender,
                phone: Phone,
                cnic: kenCNIC,
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            alert(response.data.message);
        } catch (error) {
            if (error.response) {
                const status = error.response.status;
                if (status === 400 || status === 403 || status === 404 || status === 500) {
                    alert(error.response.data.message);
                }
            } else {
                console.error(error);
            }
        }
    };

    const loadLegalInfo = async () => {
        const token = Cookies.get("_auth");

        const config = {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        };

        // Make a request with the config object
        axios.get(`${API_URL}/api/currentuser`, config)
            .then(response => {
                setCnic(response.data.cnic);
                setFbrTaxFiler(response.data.fbr_tax_filer);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleLegalInfoSubmit = async (e) => {
        e.preventDefault();

        try {
            const token = Cookies.get("_auth");
            const formData = new FormData(); // Create a FormData object to send the file
            formData.append('cnic_front_picture', CnicFront); // Append the CnicFront file to the form data
            formData.append('cnic_back_picture', CnicBack); // Append the CnicBack file to the form data
            formData.append("cnic", Cnic);      //CNIC text
            formData.append("fbr_tax_filer", FbrTaxFiler);  //FBR tax filer status

            const response = await axios.patch(`${API_URL}/api/updateCurrentUser`, formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            alert(response.data.message);
        } catch (error) {
            if (error.response) {
                const status = error.response.status;
                if (status === 400 || status === 403 || status === 404 || status === 500) {
                    alert(error.response.data.message);
                }
            } else {
                console.error(error);
            }
        }
    };

    const uploadFileNameF = (event) => {
        const file = event.target.files[0];
        setuploadTxtF(event.target.files[0].name.slice(0, 9) + "...")
        setCnicFront(file);
        // console.log(event, file, CnicFront);
    }

    const uploadFileNameB = (event) => {
        const file = event.target.files[0];
        setuploadTxtB(event.target.files[0].name.slice(0, 9) + "...");
        setCnicBack(file);
    }
    // const uploadKenProfile = (event) => {
    //     const file = event.target.files[0];
    //     setuploadKenTxt(event.target.files[0].name.slice(0, 9) + "...");
    //     setKenProfilePic(file);
    //   }

    return (
        <>

            <div className='personalDetailMainBox'>
                <h4 className="legalInfoHeading">Legal Information</h4>
                <p className="legalInfoDicription">Your Personal Information is completely secure and we don't share it with anyone</p>
                <div className="legalInfoIDcardUploadBox">

                    <div className="legalInfoInputsMainBox">
                        <div className="legalInfoUploadBox">
                            <label className='legalInfoLabel'>CNIC:</label>
                            <input type="text" value={Cnic} onChange={(e) => setCnic(e.target.value)} placeholder='00000-0000000-0' className='legalInfoInputs' />
                        </div>

                        <div className="legalInfoUploadBox">
                            <label className='legalInfoLabel'>FBR Tax Filer:</label>
                            <select value={FbrTaxFiler} onChange={(e) => setFbrTaxFiler(e.target.value)}
                                className='legalInfoInputs' >
                                <option value="0">Not Provided</option>
                                <option value="1">Filer</option>
                                <option value="2">Non Filer</option>
                            </select>
                        </div>

                        <div className="legalInfoUploadBox">
                            <label className='legalInfoLabel'>CNIC Front:</label>
                            <button className='upload-btn-f'>
                                <MdCloudUpload style={{ fontSize: '2.2rem', marginRight: '0.5rem' }} />{uploadTxtF}
                                <input type="file" id="cnicF" name="cnicF" accept=".png, .jpg, .pdf" max-size="5000000" multiple="false" className='order-inputs-upload' onChange={uploadFileNameF} />
                            </button>
                        </div>

                        <div className="legalInfoUploadBox">
                            <label className='legalInfoLabel'>CNIC Back:</label>
                            <button className='upload-btn-f'>
                                <MdCloudUpload style={{ fontSize: '2.2rem', marginRight: '0.5rem' }} />{uploadTxtB}
                                <input type="file" id="cnicB" name="cnicB" accept=".png, .jpg, .pdf" max-size="5000000" multiple="false" className='order-inputs-upload' onChange={uploadFileNameB} />
                            </button>
                        </div>
                    </div>
                    <div className="legaInfosubmittBtnBox">
                        <button className='LegalInfoCNICSubmitBtn' type='submit' onClick={handleLegalInfoSubmit}>Save</button>
                    </div>

                </div>
                <div className="legalinfoKenBox">
                    <h4 className='legalInfoKenHeading'>Next Of Kin</h4>
                    <p className="legalInfoKenDiscription">Your Personal information is completely secure and we don't share it with anyone.</p>
                    {showAddKen ? (
                        <>

                            <div className="nextOFKenAddUserMain">
                                {/* <h4 className="nextofKenHeading">Add Next of Kin</h4>
                                <p className="discriptionNextOfKen">Your Data is Completly Secure..</p> */}
                                <div className="nextOfKenDataMainBox">
                                {/* <div className="nextOfKenInputBox">
                                        <label className='nextOfKenLable'>Picture(Optional):</label>
                                        <div className="kenImageBox">
                                            <div className="kenUploadImgBox">
                                                <img src={flag} alt="Img" className='nextOfKenImg' />
                                            </div>
                                            <button className='profile-pic-upload-btn'>
                                                <MdCloudUpload style={{ fontSize: '2.2rem', marginRight: '0.5rem' }} />{uploadKenText}
                                                <input type="file" id="profilePic" name="profilePic" accept=".png, .jpg, .pdf" max-size="5000000" multiple="false" className='profile-pic-upload' onChange={uploadKenProfile}/>
                                            </button>
                                        </div>
                                    </div> */}
                                    <div className="nextOfKenInputBox">
                                        <label className='nextOfKenLable'>Full Name:</label>
                                        <input value={Fullname} onChange={(e) => setFullname(e.target.value)} className='nextOfKenInputs' placeholder='Full Name' type='text' />
                                    </div>
                                    <div className="nextOfKenInputBox">
                                        <label className='nextOfKenLable'>Email:</label>
                                        <input value={Email} onChange={(e) => setEmail(e.target.value)} className='nextOfKenInputs' placeholder='info@gmail.com' type='text' />
                                    </div>
                                    <div className="nextOfKenInputBox">
                                        <label className='nextOfKenLable'>Phone Number:</label>
                                        {/* <div className='personalDetailPhoneNumberBox'>
                                            <select className="personalDetailSelect" value={(e)=> setPhoneCode(e.target.value)}>
                                                <option value="00">00</option>
                                                <option value="+92">92</option>
                                            </select> */}
                                            <input value={Phone} onChange={(e) => setPhone(e.target.value)} type="number" id="nextOfKenPhone" className='nextOfKenInputs' pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" placeholder='000-0000000' />
                                        {/* </div> */}

                                    </div>
                                    <div className="nextOfKenInputBox">
                                        <label className='nextOfKenLable'>Address:</label>
                                        <input value={Address} onChange={(e) => setAddress(e.target.value)} className='nextOfKenInputs' placeholder='Enter Address' type='text' />
                                    </div>
                                    <div className="nextOfKenInputBox">
                                        <label className='nextOfKenLable'>CNIC No:</label>
                                        <input value={kenCNIC} onChange={(e) => setKenCNIC(e.target.value)} className='nextOfKenInputs' placeholder='00000-0000000-0' type='number' />

                                    </div>
                                    <div className="nextOfKenInputBox">
                                        <label className='nextOfKenLable'>Gender:</label>
                                        <select value={Gender} onChange={(e) => setGender(e.target.value)} className='nextOfKenInputs'>
                                            <option value="" disabled selected hidden>Select</option>
                                            <option value="1">Male</option>
                                            <option value="2">Female</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="nextOfKenBtnBox">
                                    <button className='NextOfKenButton' id='nextOfkenCancel' onClick={handleAddKenCancelClick}>Cancel</button>

                                    <button className='NextOfKenButton' id='nextOfkenSubmit' onClick={handleNextOfKinSubmit}  >Submit</button>
                                </div>
                            </div>

                        </>
                    ) : (
                        <>
                            <button onClick={handleAddKenClick}>Add Next Of Kin</button>
                        </>
                    )}

                </div>

            </div>
        </>
    );
}

export default Infopage;