import React, { useState, useEffect, useRef } from 'react'
import "./NavBarAndSideBar.css"
import { Link, useLocation, useNavigate } from 'react-router-dom'
import logo from "../assets/infra logo-01.png";
import { MdOutlineWest } from "react-icons/md";
import { MdOutlineMenu } from "react-icons/md";
import { CgProfile } from "react-icons/cg";
import { TbBuildingSkyscraper } from "react-icons/tb";
import { AiOutlineFileText } from "react-icons/ai";
import { SlGraduation } from "react-icons/sl";
import { IoWalletOutline } from "react-icons/io5";
import { FiBell } from "react-icons/fi";
import { IoSettingsOutline } from "react-icons/io5";
import { BsViewList } from "react-icons/bs";
import { useSignOut } from "react-auth-kit";
import Cookies from 'js-cookie';
import { ReactComponent as UserIcon } from "../images/user-profile-circle-solid-svgrepo-com.svg";
import { ReactComponent as LogOutIcon } from "../images/log-out-svgrepo-com.svg";
import { ReactComponent as SettingsIcon } from "../images/settings-svgrepo-com.svg";
import { API_URL, API_PUBLIC_PATH } from '../config';
import axios from 'axios';



const NavBarAndSideBar = () => {
  const [sideDisplay, setsideDisplay] = useState('0')
  const [sideContent, setsideContent] = useState('0')

  let location = useLocation();
  React.useEffect(() => {
  }, [location]);

  const handleBackspaceClick = () => {
    window.history.back();
  }

  // const handleLogout = async (e) => {
  //   Remove the 'token' variable from local storage
  //   localStorage.removeItem('token');
  //   Cookies.remove('_auth');
  //   alert("User logged out successfully");
  //   signOut();    //signed out using react-auth-kit
  //   Navigate to "/"
  //   navigate("/");
  // }


  const displayMenu = () => {
    if (sideDisplay === '0') {
      setsideDisplay('25rem');
      setsideContent('flex');
    }
    else {
      setsideDisplay('0');
      setsideContent('none');

    }
  }

  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [dob, setDob] = useState('');
  const [gender, setGender] = useState('');
  const [ProfilePic, setProfilePic] = useState(null);
  const [uploadTxt, setuploadTxt] = useState('Upload Picture')
  const [profileimageUrl, setprofileImageUrl] = useState('');

  useEffect(() => {
    loadPersonalData();
  }, []);

  const loadPersonalData = async () => {
    const token = Cookies.get("_auth");

    const config = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    };

    // Make a request with the config object
    axios.get(`${API_URL}/api/currentuser`, config)
      .then(response => {
        setFullName(response.data.full_name);
        setEmail(response.data.email);
        setPhone(response.data.phone);
        setDob(response.data.date_of_birth);
        setGender(response.data.gender);
        setProfilePic(`${API_PUBLIC_PATH}${response.data.profile_picture}`);
        setprofileImageUrl(`${API_PUBLIC_PATH}${response.data.profile_picture}`);
      })
      .catch(error => {
        console.log(error);
      });
  }


  return (
    <>

      {/* NAV BAR */}
      <div className="navbarMain">
        <MdOutlineMenu className='navBarMobileMenu' onClick={displayMenu} />
        <div className='navBarHeading'>
          <Link to="/" className="logoLink">
            <img className="navBarLogo" src={logo} alt="InfraTech" />
          </Link>
          <MdOutlineWest className='navBarBackIcon' onClick={handleBackspaceClick} />

        </div>
        <div className="navbarBtnBox">
          {/* <button className='logoutBtn' onClick={handleLogout}>LogOut</button> */}
          <Navbar_Menu>
            <span className="user-name-div">
              {fullName}
            </span>
          </Navbar_Menu>

          <Navbar_Menu>
            <NavItems icon={<UserIcon />}>
              <DropDownMenu />
            </NavItems>
          </Navbar_Menu>


        </div>

      </div>

      {/* SIDE BAR */}
      <div className="sideBarMain" style={{ width: sideDisplay }}>

        <div className="sideBarItemsListBox" style={{ display: sideContent }}>
          <Link to="/dashboard" className="sideBarLink">
            <div className="sideBarItem" onClick={displayMenu}>
              <CgProfile className='sideBarIcon' />
              <span className='sideBarListName'> Dashboard</span>
            </div>
          </Link>
          <Link to="/currentProjects" className="sideBarLink">
            <div className="sideBarItem" onClick={displayMenu}>
              <TbBuildingSkyscraper className='sideBarIcon' />
              <span className='sideBarListName'> Projects</span>
            </div>
          </Link>


          {/* <div className="sideBarItem">
                        <AiOutlineCreditCard className='sideBarIcon' />
                        <span className='sideBarListName'> Projects</span>
                    </div> */}

          <Link to="transactions" className="sideBarLink">
            <div className="sideBarItem" onClick={displayMenu}>
              <AiOutlineFileText className='sideBarIcon' />
              <span className='sideBarListName'> Transactions</span>
            </div>
          </Link>

          <Link to="/learningCenter" className="sideBarLink">
            <div className="sideBarItem" onClick={displayMenu}>
              <SlGraduation className='sideBarIcon' />
              <span className='sideBarListName'> E-Learing</span>
            </div>
          </Link>

          <Link to="/profile/wallet" className="sideBarLink">
            <div className="sideBarItem" onClick={displayMenu}>
              <IoWalletOutline className='sideBarIcon' />
              <span className='sideBarListName'> Wallet</span>
            </div>
          </Link>

          <Link to="/profile/notification" className="sideBarLink">
            <div className="sideBarItem" onClick={displayMenu}>
              <FiBell className='sideBarIcon' />
              <span className='sideBarListName'> Notifications</span>
            </div>
          </Link>

          <Link to="/infralistings" className="sideBarLink">
            <div className="sideBarItem" onClick={displayMenu}>
              <BsViewList className='sideBarIcon' />
              <span className='sideBarListName'> Infra Listings</span>
            </div>
          </Link>

          <Link to="/profile" className="sideBarLink">
            <div className="sideBarItem" onClick={displayMenu}>
              <IoSettingsOutline className='sideBarIcon' />
              <span className='sideBarListName'> Settings</span>
            </div>
          </Link>
          <div className="copyright">
            <hr style={{ borderColor: '#066b6e' }} />
            A product of <b> InfraTech DAO </b> <br />
            <span>&copy; 2023 All rights reserved <br /></span>
            <span>Release 2.1.1</span>

          </div>
        </div>

      </div>
    </>
  )
}


function Navbar_Menu(props) {
  return (
    <ul className="navbar-nav">{props.children}</ul>

  )

}

function NavItems(props) {

  const [open, setOpen] = useState(false);
  const dropdownRef = useRef(null);

  // Close the dropdown when clicking anywhere outside of it
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpen(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <li className="nav-item" ref={dropdownRef}>
      <a className="icon-main-button" onClick={() => setOpen(!open)}>
        {props.icon}
      </a>
      {open && props.children}
    </li>
  );
}

function DropDownMenu() {
  const [activeMenu, setActiveMenu] = useState('main');
  const navigate = useNavigate();
  const signOut = useSignOut();

  const handleLogout = async (e) => {
    try {
      // Remove the 'token' variable from local storage
      // localStorage.removeItem('token');
      Cookies.remove('_auth');
      alert("User logged out successfully");
      await signOut(); // Make sure signOut is an async function
      // Navigate to "/"
      navigate("/");
    } catch (error) {
      console.error("Error logging out:", error);
    }
  }

  function DropdownItem(props) {
    return (
      <a className="menu-item" >
        <span className="icon-button">{props.leftIcon}</span>
        {props.children}
        <span className="icon-right">{props.rightIcon}</span>
      </a>
    );
  }

  return (
    <div className="dropdown-navbar">
      {/* <span className="menu-item">
            <span className="icon-main-button"><UserIcon/></span>
              Username
            
            </span> */}
      <Link to="/profile" className="menu-item">
        <span className="icon-button"><SettingsIcon /></span>
        Settings
        <span className="icon-right"></span>
      </Link>
      <button onClick={handleLogout} className="menu-item-button">
        <span className="icon-button"><LogOutIcon /></span>
        Log Out
        <span className="icon-right"></span>
      </button>
    </div>
  );
}

export default NavBarAndSideBar;