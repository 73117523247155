import React, { useState , useEffect} from 'react';
import './Signup.css';
import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai';
import logo from '../assets/infra logo-01.png';
import GooglLogo from '../assets/google.png';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { css } from '@emotion/react';
import { SyncLoader } from 'react-spinners';
import { API_URL } from '../config';
import { useSignIn } from 'react-auth-kit';
import Cookies from 'js-cookie';

export const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const signIn = useSignIn();


  const redirecToGoogleSSO = async () => {
    const googleLoginURL = "http://localhost:5000/api/public/auth/google/callback";
    const newWindow = window.open(googleLoginURL);
    window.close()
  };

  useEffect(() => {
    const cookieValue = Cookies.get('_auth_type');
    // console.log(cookieValue);
    
  }, []);


  const loginApi = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);

      const response = await axios.post(`${API_URL}/api/login`, {
        email,
        password,
      });

      signIn({
        token: response.data.token,
        expiresIn: 3600,
        tokenType:"Bearer",
        authState: {email:email},

      })

      if (response.status === 200) {
        localStorage.setItem('token', response.data.token);
        Cookies.set('_auth', response.data.token);
        // console.log(response.data.token);
        navigate('/dashboard');
      } else if (response.status === 400) {
        alert('Invalid credentials');
      } else if (response.status === 500) {
        alert('Server error');
      }
    } catch (error) {
      console.log(error);
      setError('Invalid Credentials. Please Try Again');
    } finally {
      setLoading(false);
    }
  };


  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <div className="sNavbar__img">
        <img className="sNavbar__logo" src={logo} alt="" />
      </div>
      <div className="sContainer">
        <div className="sContainer1 lcont">
          <h1 className="form-title">LOG IN</h1>
          <form action="#">
            <div className="main-user-info">
              <div className="input-box">
                <label htmlFor="email">Email</label>
                <input
                  type="text"
                  id="email"
                  name="email"
                  placeholder="example@gmail.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  tabIndex="0"
                />
              </div>

              <div className="input-box">
                <label htmlFor="pswd">Password</label>
                <input
                  type={showPassword ? 'text' : 'password'}
                  id="pswd"
                  name="pswd"
                  placeholder="Enter Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  tabIndex="1"
                />
                <button type="button" className="eyeBtn" onClick={togglePasswordVisibility}>
                  {showPassword ? <AiOutlineEye size={28} /> : <AiOutlineEyeInvisible size={28} />}
                </button>
              </div>
            </div>

            <div className="forgLog">
              <p>
                <a href="/forgotPassword">Forgot Password?</a>
              </p>
            </div>
            <div className="error-message-display">
              {error && <p className="error-message">{error}</p>}
            </div>
            <div className="sign-btn">
              <button onClick={loginApi} disabled={loading} tabIndex="2">
                {loading ? (
                  <SyncLoader
                    css={css`
                      display: inline-block;
                      vertical-align: middle;
                    `}
                    size={10}
                    color={'#ffffff'}
                    loading={loading}
                  />
                ) : (
                  'Log In'
                )}
              </button>
            </div>

            <hr />
            <p className="orText">OR</p>

            <div className="gIcon" > 
            <button className="gbtn" type="button" onClick={() => window.location.href = `${API_URL}/api/public/auth/google/callback`}>
                        Log In with Google
                        <img className="gLogo" alt="" src={GooglLogo} />
                      </button>
            </div>

            <p className="LogInlink">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Join us today &nbsp;
              <Link to="/Signup">Sign Up here!&nbsp; </Link>
            </p>
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
