import React, { useState, useEffect } from 'react'
import './unitPaymentPlan.css'
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import { API_URL } from '../../config';
import Loading from '../../components/Loading';

const UnitPaymentPlan = () => {
  const { orderId } = useParams()
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [message , setMessage] = useState('')
  const [ischecked, setIsChecked] = useState(false);
  const [payementCheck, setPayementCheck] = useState(false);
  const [orderDetail, setOrderDetail] = useState();
  const [payementOption, setPayementOption] = useState();
  const [pricePercentage, setPricePercentage] = useState(0);
  const [pendingAmount, setPendingAmount] = useState(0);

  const fetchUnitOrder = async () => {
    const token = Cookies.get('_auth');
    try {
      const response = await axios.get(`${API_URL}/api/unit_order/${orderId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      setOrderDetail(response.data)

    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const handleCheckboxChange = (payementOption) => {
    setPayementOption(payementOption)
    setPayementCheck(true)

    let percentage = (payementOption / 100) * orderDetail.total_bill;
    let remainingAmount = orderDetail.total_bill - orderDetail.amount_paid;
    
    if (remainingAmount <= percentage) {
      if (orderDetail.amount_paid !== orderDetail.total_bill) {
        setPricePercentage(remainingAmount);
        setPendingAmount(0)
        return;
      }

      setPricePercentage(remainingAmount)
      setPendingAmount(0)
      return;

    }
      setPricePercentage(percentage);
      let amountPending = remainingAmount - percentage;
      setPendingAmount(amountPending);

  };

  const handleUpdate = async () => {
    if (payementCheck) {
      try {
        const token = Cookies.get("_auth");
        const formData = new FormData();
        formData.append('amount_to_pay', pricePercentage);
        formData.append('amount_left', pendingAmount);
        if (pendingAmount === 0) {
          formData.append("payment_status", "Being Verified");
        }

        const response = await axios.patch(`${API_URL}/api/unit_order/${orderId}`, formData, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        alert(response.data.message);
        navigate(`/unitOrderDetails/${orderId}`)
      } catch (error) {
        console.log(error);
      }

    } else {

      setMessage("Please Select Payment Plan First")
      setInterval(() => {
        setMessage("")
      }, 2000);
    }

  }


  useEffect(() => {
    fetchUnitOrder();

  }, [])

  return (
    <div className='unitPayemntPlanMainBox'>

    <p style={{color: 'red'}}>{message}</p>

      <h4>Discovering Ecosystem Interconnections: Unit Plan</h4>

      {loading ? (
        <Loading  loading={loading}/>
      ) : (
        <>
          <div className="paymentPlanMainBox">
            <div className="chooseOrderPayementPlanBox">
              <h4 className='orderPayemnetPlanHeading'>Choose Your Unit Payement Plan</h4>
              <div className="orderPayementPlanDataBox">
                <input type="checkbox"
                  checked={payementOption === 100}
                  onChange={() => handleCheckboxChange(100)}
                />
                <span>100% Advance Payement Plan</span>
              </div>

              <div className="orderPayementPlanDataBox">
                <input type="checkbox"
                  checked={payementOption === 75}
                  onChange={() => handleCheckboxChange(75)}
                />
                <span>75% Advance Payement Plan</span>
              </div>

              <div className="orderPayementPlanDataBox">
                <input type="checkbox"
                  checked={payementOption === 50}
                  onChange={() => handleCheckboxChange(50)}
                />
                <span>50% Advance Payement Plan</span>
              </div>

              <div className="orderPayementPlanDataBox">
                <input type="checkbox"
                  checked={payementOption === 25}
                  onChange={() => handleCheckboxChange(25)}
                />
                <span>25%  Advance Payement Plan</span>
              </div>
            </div>

            <div className="summaryUnitOrderPlanBox">
              <h4 className='orderPayemnetPlanHeading'>Summary</h4>
              <div className='unitOrderSummaryDataBox'>
                <span>ID No.</span>
                <span>{orderDetail.unit_type.unit_unique_specifier}</span>
              </div>
              <div className='unitOrderSummaryDataBox'>
                <span>Project Name</span>
                <span>{orderDetail.project.name}</span>
              </div>
              <div className='unitOrderSummaryDataBox'>
                <span>Total Area (sq.ft)</span>
                <span>{orderDetail.area_sq_feet} (sq.ft)</span>
              </div>
              <div className='unitOrderSummaryDataBox'>
                <span>Total Price</span>
                <span>{orderDetail.total_bill && orderDetail.total_bill.toLocaleString()} PKR</span>
              </div>
              <div className='unitOrderSummaryDataBox'>
                <span>Total Amount Paid</span>
                <span>{orderDetail.amount_paid && orderDetail.amount_paid.toLocaleString()} PKR</span>
              </div>
              <div className='unitOrderSummaryDataBox'>
                <span>Total Remaining Amount</span>
                <span>{orderDetail.amount_left && orderDetail.amount_left.toLocaleString()} PKR</span>
              </div>
              <div className='unitOrderSummaryDataBox'>
                <span>Current Amount To Pay</span>
                <span style={{ fontWeight: "bold" }}>{pricePercentage.toLocaleString()} PKR</span>
              </div>
              <div className='unitOrderSummaryDataBox'>
                <span>Current Remaining Amount</span>
                <span style={{ fontWeight: "bold" }}>{pendingAmount.toLocaleString()} PKR</span>
              </div>
            </div>
          </div>
          <div className="unitOrderBookNowBtnBoxMain">
            <div className='unitOrderTermsAndConditonBox'>
              <input type="checkbox"
                checked={ischecked}
                onChange={(e) => setIsChecked(e.target.checked)} />
              <span>I accept to terms & condition of SAK Tower.</span>
            </div>
            <div className="unitOrderBookNowBtnBox">
              <button disabled={!ischecked} onClick={handleUpdate}>Update Order</button>
            </div>

          </div>
        </>
      )}


    </div>
  )
}

export default UnitPaymentPlan