import React from "react";
import { useState, useEffect } from "react";
import axios from 'axios';
import "./Purchase.css";
import Cookies from 'js-cookie';

import Line from "../assets/Line 7.png";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { API_URL } from '../config';

const options = [
  { value: "Kharian", label: "Kharian" },
  { value: "SAK Tower", label: "SAK Tower" },
];



const Purchase = () => {

  useEffect(() => {
    loadPersonalData();
  }, []);


  const loadPersonalData = async () => {
    const token = Cookies.get("_auth");

    const config = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    };

  }

  const [tower, setTower] = useState("SAK tower");
  const [round, setRound] = useState("round 1");
  const navigate = useNavigate();
  //   if (tower === 'Kharian' && round !== 'round 2') {
  //    setRound('round 2');
  // }

  const [order, setOrder] = useState({
    order_id: '',
    area_sq_feet: '',
    amount_paid: '',
    payment_slip: '',
    transaction_hash: '',
    bank_branch: '',
    project_id: null, // Initially set project ID to null
    bank_name_id: '',
    round_id: '',
    purchase_date: '',
    user_id: '',
  });

  const handleProjectChange = (event) => {
    setOrder({
      ...order,
      project_id: event.target.value // Update project ID when project is selected
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post(`/api/orders`, order);
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (event) => {
    setOrder({
      ...order,
      [event.target.name]: event.target.value
    });
  };

  const handleTowerChange = (event) => {
    setTower(event.target.value);
    // setRound(''); // Reset round when tower is changed
  };

  const handleRoundChange = (event) => {
    setRound(event.target.value);
  };

  const createOrderAPI = async (e) => {
    e.preventDefault();

    try {
      const token = Cookies.get("_auth");
      const response = await axios.post(`${API_URL}/api/order`, {
        project_name: tower,
        area_sq_feet: Area,
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      alert(response.data.message);
      navigate("/purchase/purchaseDetails");
    } catch (error) {

    }

  }

  const roundData = {
    "round 1": {
      "Round Price": "8000pkr",
      "Total Area": "7000sq.ft",
      "Area Left": "5000sq.ft",
      "Area Locked": "4000sq.ft",
      "Expected Duration": "2 months",
    },
    "round 2": {
      "Round Price": "9000pkr",
      "Total Area": "8000sq.ft",
      "Area Left": "7000sq.ft",
      "Area Locked": "300sq.ft",
      "Expected Duration": "3 months",
    },
    "round 3": {
      "Round Price": "10000pkr",
      "Total Area": "10000sq.ft",
      "Area Left": "7000sq.ft",
      "Area Locked": "300sq.ft",
      "Expected Duration": "4 months",
    },
    "round 4": {
      "Round Price": 9000,
      "Total Area": "8000sq.ft",
      "Area Left": "6000sq.ft",
      "Area Locked": "3000sq.ft",
      "Expected Duration": "5 months",
    },
  };

  const kharianRoundData = {
    "round 1": {
      "Round Price": 9000,
      "Total Area": "8000sq.ft",
      "Area Left": "6000sq.ft",
      "Area Locked": "3000sq.ft",
      "Expected Duration": "5 months",
    },
    "round 2": {
      "Round Price": "10000pkr",
      "Total Area": "10000sq.ft",
      "Area Left": "7000sq.ft",
      "Area Locked": "300sq.ft",
      "Expected Duration": "9 months",
    },
    "round 3": {
      "Round Price": "8000pkr",
      "Total Area": "7000sq.ft",
      "Area Left": "5000sq.ft",
      "Area Locked": "4000sq.ft",
      "Expected Duration": "2 months",
    },
  };
  const [Area, setArea] = useState("");
  const [field1, setField1] = useState("");
  const [field2, setField2] = useState("");
  const handleNumberChange = (event) => {
    const enteredNumber = event.target.value;
    if (enteredNumber <= 49) {
      setArea("50"); // Set the number state to 50 if the entered number is less than or equal to 49
      setField1("50");
      setField2(" ");
    }

    else {
      setArea(enteredNumber);
      // setField1(enteredNumber); // Set field1 state to the entered value
      setField2(enteredNumber * 8500);
    }
  }



  // const handleNumberChange = (event) => {
  //   const enteredNumber = event.target.value;
  //   if (enteredNumber <50) {
  //     setArea("50"); // Set the number state to 1 if the entered number is less than or equal to 0
  //     // setField1("");
  //     setField2("");
  //   } else {
  //     setArea(enteredNumber);
  //     // setField1(enteredNumber);
  //     setField2(enteredNumber * 8500);
  //   }

  // };
  const roundDetails =
    tower === "SAK tower" ? roundData[round] : kharianRoundData[round];
  return (
    <div className="purchaseMainContainer">
      <div id="purchaseHeadContanier">
        <div className="selectProjectHeadButton">
          {/* <label htmlFor="tower-select"></label> */}
          <select id="tower-select" value={tower} onChange={handleTowerChange}>
            <p>Development Round</p>
            <option value="">Select Project</option>
            <option value="SAK tower">SAK tower</option>
            <option value="Kharian">Kharian</option>
          </select>
        </div>

        <div className="purchaseBtnMainBox">

          <div className="purchaseInnerBtnBoxs" id="selectBtn">
            Select Area
          </div>
          <div className="lineBox">
            <img className="btnLine" src={Line} alt="" />
          </div>
          <div className="purchaseInnerBtnBoxs">
            Summary
          </div>
          <div className="lineBox">
            <img className="btnLine" src={Line} alt="" />
          </div>
          <div className="purchaseInnerBtnBoxs">
            Confirmation
          </div>
        </div>
      </div>

      {/* MID BOX START FROM HERE */}

      <div className="purchaseMidBox">

        {/* LEFT SIDE BOX */}
        <div className="purchaseMidBoxInsideLeft">
          <div className="roundselctionContainer">
            <div className="leftMidBoxDevelopmentBtn">
              Development Round
            </div>

            {tower && (
              <div className="leftMidBoxSelectOption">
                {/* <label htmlFor="round-select">Select a round:</label> */}
                <select id="roundSelect" value={round} onChange={handleRoundChange}>
                  <option value="">Select Development Round</option>
                  {tower === 'SAK tower' ? (
                    <>
                      <option value="round 1"> Development Round 1 (Current) </option>
                      <option value="round 2"> Development Round 2</option>
                      <option value="round 3"> Development Round 3</option>
                      <option value="round 4"> Development Round 4</option>
                    </>
                  ) : (
                    <>
                      <option value="round 1"> Development Round 1</option>
                      <option value="round 2"> Development Round 2 (Current)</option>
                      <option value="round 3"> Development Round 3</option>
                    </>
                  )}
                </select>


                {roundDetails && (
                  <table id="tableRoundSelection">
                    <thead>
                      <tr>
                        <th className='head'>Round Price</th>
                        <th>Total Area</th>
                        <th>Area Left</th>
                        <th>Area Locked</th>
                        <th>Expected Duration</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{roundDetails['Round Price']}</td>
                        <td>{roundDetails['Total Area']}</td>
                        <td>{roundDetails['Area Left']}</td>
                        <td>{roundDetails['Area Locked']}</td>
                        <td>{roundDetails['Expected Duration']}</td>
                      </tr>
                    </tbody>
                  </table>
                )}

              </div>

            )}
          </div>

          <div className="selectAppartmentInsideLeft">
            <div className="startJurneyButtonInsideLeft">
              Start Your New Jurney
            </div>
            <button className="amountSqftInsideLeft">8,500 PKR / sq.ft</button>
            <div className="selcetAppartmentBox">
              <h5 className="selcetUnitHeading">Select Unit</h5>
              <div className="slectAppartmentOption">
                <div className="selectAppartmentItems">
                  2+1 Bed Apartment
                  1,978 sq.ft.
                </div>
                <div className="selectAppartmentItems">
                  2 Bed Apartment
                  2,196 sq.ft.
                </div>
                <div className="selectAppartmentItems">
                  2+1 Bed Apartment
                  1,978 sq.ft.
                </div>
                <div className="selectAppartmentItems">
                  3+1 Bed Apartment
                  2,806 sq.ft.
                </div>

              </div>
            </div>
          </div>
        </div>



        {/* RIGHT SIDE OF THE BOX */}
        <div className="purchaseMidBoxInsideRight">
          <div className="areaToPledgeBox">
            <div className="areaToPledgeItem">
              <h4 className="areaToPledgeHeading">Area To Pledge</h4>
            </div>
            <div className="areaToPledgeItem">
              <label className="areaToPledgeSqFtInput">
                Enter Sq.ft (Must be {">"} 50) <br></br>
                <input
                  type="number"
                  placeholder="sq.ft"
                  // value={number}
                  style={{
                    WebkitAppearance: "none",
                    margin: 0,
                  }}
                  onChange={handleNumberChange}
                  required />
              </label>
            </div>

            <div className="areaToPledgeItem">
              <label id="areaToPledgeTotalPrice">
                Total Price (PKR): <br />
                <input type="text" placeholder="sq.ft must be > 50" value={field2} readOnly />
              </label>
            </div>

            <div className="areaToPledgeItem">
              <button id="areaToPledgePurchaseButton" onClick={createOrderAPI}>
                Purchase Now
              </button>
            </div>


          </div>
        </div>

      </div>

      {/* <div className="areaToPledgeContainer">

        <p className="areaToPledge">Area To Pledge </p>

        <br />
        <label className="showSquareFt">
      Enter Sq.ft (Must be {">"} 50) <br></br>
          <input
            type="number"
            placeholder="sq.ft"
            // value={number}
            style={{
              WebkitAppearance: "none",
              margin: 0,
            }}
            onChange={handleNumberChange}
            required />
        </label>
        <br />
        <label id="totalPrice">
          Total Price (PKR): <br />
          <input type="text" placeholder="sq.ft must be > 50" value={field2} readOnly />
          <p className="pkr"> Pakistani Rupees</p>
        </label>
        
          <button id="purchaseNowButton" onClick={createOrderAPI}>
            Purchase Now
          </button>


      </div> */}




      {/* MAIN END HERE */}
    </div>
  );
};

export default Purchase;
