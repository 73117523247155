import React from "react";
import "./UserPortfolio.css";
import SakLogo from '../assets/SakLogo.png'
import { FaUserAlt } from "react-icons/fa";
import { BsFillCalendarWeekFill } from "react-icons/bs";
import { MdLocationPin } from "react-icons/md";
import { MdLoop } from "react-icons/md";
import { FaWallet } from "react-icons/fa";
import { FaCoins } from "react-icons/fa";
import { GiWallet } from "react-icons/gi";

const UserPortfolio = () => {
  return (
    <div className="reportsContainer">
      <div className="latestReport">Portfolio Value - 0</div>

      <div className="sakTowerCardBox">

        <div className="sakTowerCard">
          <div className="towerCardImageBox">
            <img className="sakLogoCardImage" src={SakLogo} alt="logo" />
            <h3 className="towerCardHeadingName">Project Name:</h3>
            <h4 className="towerCardCoHeading"></h4>
          </div>

          <div className="towerCardDataBox">
            <div className="towerCardData" >
              <label className="cardDataLabel">ID Number:</label>
              <div className="towerCardInformationBox">
                <FaUserAlt className="cardDataIcons" />
                <label className="towerCardInformation" id="IdNumber"></label>
              </div>

            </div>
            <div className="towerCardData" >
              <label className="cardDataLabel">Date:</label>
              <div className="towerCardInformationBox">
                <BsFillCalendarWeekFill className="cardDataIcons" />
                <p className="towerCardInformation" id="Date"></p>
              </div>

            </div>

            <div className="towerCardData" >
              <label className="cardDataLabel">Location:</label>
              <div className="towerCardInformationBox">
                <MdLocationPin className="cardDataIcons" />
                <p className="towerCardInformation" id="Location"></p>
              </div>

            </div>

            <div className="towerCardData" >
              <label className="cardDataLabel">Round ID:</label>
              <div className="towerCardInformationBox">
                <MdLoop className="cardDataIcons" />
                <p className="towerCardInformation" id="RoundNumber"></p>
              </div>
            </div>

            <div className="towerCardData">
              <label className="cardDataLabel">Area Sqft:</label>
              <div className="towerCardInformationBox">
                <FaWallet className="cardDataIcons" />
                <p className="towerCardInformation" id="AreaSqft"></p>
              </div>

            </div>
          </div>

          <div className="towerCardInvestmentBox">
            <div className="towerCardInvestmentAmmount">
              <FaCoins className="cardDataIcons" />
              <h5 className="amountInvestedInfo">Amount Invested (Total) </h5>
            </div>
            <div className="towerCardInvestmentAmmountToday">
              <GiWallet className="cardDataIcons" />
              <h5 className="amountInvestedInfo">Investment Value (Today) </h5>
            </div>
          </div>
        </div>

      <div className="sakTowerCard">
          <div className="towerCardImageBox">
            <img className="sakLogoCardImage" src={SakLogo} alt="logo" />
            <h3 className="towerCardHeadingName">Project Name:</h3>
            <h4 className="towerCardCoHeading"></h4>
          </div>

          <div className="towerCardDataBox">
            <div className="towerCardData" >
              <label className="cardDataLabel">ID Number:</label>
              <div className="towerCardInformationBox">
                <FaUserAlt className="cardDataIcons" />
                <p className="towerCardInformation" id="IdNumber"></p>
              </div>

            </div>
            <div className="towerCardData" >
              <label className="cardDataLabel">Date:</label>
              <div className="towerCardInformationBox">
                <BsFillCalendarWeekFill className="cardDataIcons" />
                <p className="towerCardInformation" id="Date"></p>
              </div>

            </div>

            <div className="towerCardData" >
              <label className="cardDataLabel">Location:</label>
              <div className="towerCardInformationBox">
                <MdLocationPin className="cardDataIcons" />
                <p className="towerCardInformation" id="Location"></p>
              </div>

            </div>

            <div className="towerCardData" >
              <label className="cardDataLabel">Round ID:</label>
              <div className="towerCardInformationBox">
                <MdLoop className="cardDataIcons" />
                <p className="towerCardInformation" id="RoundNumber"></p>
              </div>
            </div>

            <div className="towerCardData">
              <label className="cardDataLabel">Area Sqft:</label>
              <div className="towerCardInformationBox">
                <FaWallet className="cardDataIcons" />
                <p className="towerCardInformation" id="AreaSqft"></p>
              </div>

            </div>
          </div>

          <div className="towerCardInvestmentBox">
            <div className="towerCardInvestmentAmmount">
              <FaCoins className="cardDataIcons" />
              <h5 className="amountInvestedInfo">Amount Invested (Total)</h5>
            </div>
            <div className="towerCardInvestmentAmmountToday">
              <GiWallet className="cardDataIcons" />
              <h5 className="amountInvestedInfo">Amount Invested (Today)</h5>
            </div>
          </div>
        </div>
      </div>

      {/* TABLE CONTAINER CODE */}

      <div className="table-container">
        <div className="table-header">
          <div className="cell" >Date</div>
          <div className="cell"  >Order Id</div>
          <div className="cell" >Project Name</div>
          <div className="cell" >Round No</div>
          <div className="cell" >Area (sq.ft)</div>
          <div className="cell" >Amount Invested</div>
          <div className="cell" >Amount Value (Today)</div>
        </div>
        {/* <div className="table-row">
          <div className="cell">05/10/2020</div>
          <div className="cell">#SAK123</div>
          <div className="cell">SAK TOWER</div>
          <div className="cell">03</div>
          <div className="cell">55,123(sq.ft)</div>
          <div className="cell">1235$</div>
          <div className="cell"></div>
        </div>
        <div className="table-row">
          <div className="cell">01/10/2019</div>
          <div className="cell">#SAK123</div>
          <div className="cell">SAK TOWER</div>
          <div className="cell">04</div>
          <div className="cell">20,321(sq.ft)</div>
          <div className="cell">400$</div>
          <div className="cell"></div>
        </div>
        <div className="table-row">
          <div className="cell">04/06/2020</div>
          <div className="cell">#SAK123</div>
          <div className="cell">SAK TOWER</div>
          <div className="cell">05</div>
          <div className="cell">23,456(sq.ft)</div>
          <div className="cell">7000$</div>
          <div className="cell"></div>
        </div>
        <div className="table-row">
          <div className="cell"></div>
          <div className="cell"></div>
          <div className="cell"></div>
          <div className="cell"></div>
          <div className="cell"></div>
          <div className="cell"></div>
          <div className="cell"></div>
        </div> */}
      </div>
    </div>
  );
};

export default UserPortfolio;
