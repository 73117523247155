import React from 'react';
import { FaStar, FaStarHalfAlt } from 'react-icons/fa';
import './RatingStars.css';

function RatingStars({ rating }) {
  const renderStars = () => {
    const totalStars = 5;
    const filledStars = Math.floor(rating);
    const hasHalfStar = rating % 1 !== 0;

    const stars = [];
    for (let i = 0; i < totalStars; i++) {
      if (i < filledStars) {
        stars.push(<FaStar key={i} className="filled" />);
      } else if (i === filledStars && hasHalfStar) {
        stars.push(<FaStarHalfAlt key={i} className="half-filled" />);
      } else {
        stars.push(<FaStar key={i} className="empty" />);
      }
    }

    return stars;
  };

  return <span>{renderStars()}</span>;
}

export default RatingStars;



