import React from "react";
import { Route, Navigate } from "react-router-dom";
import Cookies from 'js-cookie';

const PrivateRoute = ({ children }) => {
    const isAuthenticated = Cookies.get("_auth");
    // console.log(isAuthenticated);
    // const isAuthenticated = true;
    return isAuthenticated ? children : <Navigate to="/"></Navigate>
};


export default PrivateRoute;
