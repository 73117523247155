import React, { useState } from 'react'
import './Signup.css'
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import logo from "../assets/infra logo-01.png";
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { API_URL } from '../config';
import Cookies from 'js-cookie';



export const ForgotPassword = () => {



    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState("");
    const navigate = useNavigate();

    const loginApi = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${API_URL}/api/login`, {
                email,
                password,
            });
            // If the request was successful and returned a status of 200, display a success message indicating that the user is logged in
            if (response.status === 200) {
                // localStorage.setItem('token', response.data.token);
                Cookies.set('_auth', response.data.token);
                console.log(response.data.token);
                navigate("/dashboard");
            } else if (response.status === 400) {
                // If the request failed and returned a status of 400, display an error message indicating that the credentials are invalid
                alert("Invalid credentials");
            } else if (response.status === 500) {
                // If the request failed and returned a status of 500, display an error message indicating that there was a server error
                alert("Server error");
            }

        } catch (error) {
            console.log(error);
            setError("Email Not Found. Try Again");
        }
    }



    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    }


    return (
        <>

            <div className="sNavbar__img">
                <img className="sNavbar__logo" src={logo} alt="" />
            </div>
            <div className="sContainer">
                <div className="sContainer1 lcont">
                    <h1 className="form-title">FORGOT PASSWORD?</h1>
                    <form action="#">
                        <div className="main-user-info">

                            <div className="input-box">
                                <label htmlFor="email">Enter The Email You Used To Create Account On Infratech DAO platform</label>
                                <input type="text" id="email" name='email' placeholder='example@gmail.com' value={email} onChange={(e) => setEmail(e.target.value)} />
                            </div>

                            <div className='send-password' >
                                <p><a href="#" >Send Code</a></p>
                            </div>
                            <div className='error-message-display'>
                                {error && <p className="error-message">{error}</p>}
                            </div>

                            <div className="input-box">
                                <label htmlFor="pswd">Enter The New Password We Have Sent On Your Email</label>
                                <input type={showPassword ? 'text' : 'password'} id="pswd" name='pswd' placeholder='Enter Password' value={password} onChange={(e) => setPassword(e.target.value)} />
                                <button type="button" className="eyeBtn" onClick={togglePasswordVisibility}>
                                    {showPassword ? <AiOutlineEye size={28} /> : <AiOutlineEyeInvisible size={28} />}
                                </button>



                            </div>

                        </div>



                        <div className="sign-btn">
                            <button onClick={loginApi}>Log In</button>
                        </div>

                        <hr />
                        <p class="orText">OR</p>


                        <p className="LogInlink">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Join us today &nbsp;<Link to="/Signup">Sign Up here!&nbsp; </Link>
                        </p>

                        <p className="LogInlink">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Back To &nbsp;<Link to="/">Login Page!&nbsp; </Link>
                        </p>
                    </form>
                </div>
            </div>


        </>
    )
}

export default ForgotPassword
