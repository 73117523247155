import React, { useEffect, useState } from "react";
import "./transaction.css";
import { Link } from "react-router-dom";
import { API_URL } from "../../config";
import axios from "axios";
import Cookies from "js-cookie";
import Loading from "../../components/Loading";

function Transaction() {
  const [loading, setLoading] = useState(true);
  const [orderComponent, setOrderComponent] = useState("unitOrders");
  const [Orders, setOrders] = useState([]);
  const [unitOrders, setUnitOrders] = useState([]);
  const [activeButton, setActiveButton] = useState('unitOrders');
  const rate = 8500;

  const handleOrderBtnClick = (value) => {
    setOrderComponent(value);
    setActiveButton(value);
  };

  const loadTransaction = async () => {
    const token = Cookies.get('_auth');

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await axios.get(`${API_URL}/api/currentuser`, config);
      const orders = response.data.orders;
      const unit_orders = response.data.unit_orders;
      setOrders(orders);
      setUnitOrders(unit_orders);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false)
    }
  };

  useEffect(() => {
    loadTransaction();
  }, []);

  const renderOrdersComponent = () => {
    switch (orderComponent) {
      case "flexibleOrders":
        return (
          <>
            <h4 className="ordersComponentHeading">Flexible Orders Summary</h4>
            <div className="ordersComponentTableBox">
              <table className="ordersComponentTable">
                <tr className="ordersComponentTableRow">
                  <th className="ordersComponentTableHeading">Date</th>
                  <th className="ordersComponentTableHeading">Order ID</th>
                  <th className="ordersComponentTableHeading">Project Name</th>
                  <th className="ordersComponentTableHeading">Round No</th>
                  <th className="ordersComponentTableHeading">Area (sq.ft)</th>
                  <th className="ordersComponentTableHeading">
                    Amount To Pay (PKR)
                  </th>
                  <th className="ordersComponentTableHeading">
                    Order Details
                  </th>
                </tr>
                {/* By default the data will be sorted in descending order */}
                {loading ? (<>

                </>) : (<>
                  {Orders && Orders.map((order) => (
                    <tr key={order.id} className="ordersComponentTableRow">
                      <td className="ordersComponentTableColumn">{new Date(order.createdAt).toLocaleDateString()}</td>
                      <td className="ordersComponentTableColumn">{order.id}</td>
                      <td className="ordersComponentTableColumn">{order.project.name}</td>
                      <td className="ordersComponentTableColumn">Current</td>
                      <td className="ordersComponentTableColumn">{order.area_sq_feet}</td>
                      <td className="ordersComponentTableColumn">{order.area_sq_feet * rate}</td>
                      {/* <td>{order.amount_paid}</td> */}
                      <td className="ordersComponentTableColumn">
                        <Link to={`/flexibleOrderDetails/${order.id}`}>
                          View Order Details
                        </Link>
                      </td>
                    </tr>
                  ))}
                </>)

                }
              </table>
              {loading && <Loading loading={loading} />}

            </div>
          </>
        );
        break;

      case "unitOrders":
        return (
          <>
            <h4 className="ordersComponentHeading">Unit Orders Summary</h4>
            <div className="ordersComponentTableBox">
              <table className="ordersComponentTable">
                <tr className="ordersComponentTableRow">
                  <th className="ordersComponentTableHeading">Date</th>
                  <th className="ordersComponentTableHeading">Order ID</th>
                  <th className="ordersComponentTableHeading">Project Name</th>
                  <th className="ordersComponentTableHeading">Unit Type Id</th>
                  <th className="ordersComponentTableHeading">Area (sq.ft)</th>
                  <th className="ordersComponentTableHeading">Total Amount (PKR)</th>
                  <th className="ordersComponentTableHeading">Amount Paid (PKR)</th>
                  <th className="ordersComponentTableHeading">Amount to pay(PKR)</th>
                  <th className="ordersComponentTableHeading">Order Details</th>
                </tr>

                {/* By default the data will be sorted in descending order */}
                {unitOrders && unitOrders.map((order) => (
                  <tr key={order.id} className="ordersComponentTableRow">
                    <td className="ordersComponentTableColumn">{new Date(order.createdAt).toLocaleDateString()}</td>
                    <td className="ordersComponentTableColumn">{order.id}</td>
                    <td className="ordersComponentTableColumn">{order.project.name}</td>
                    <td className="ordersComponentTableColumn">{order.unit_type.unit_unique_specifier}</td>
                    <td className="ordersComponentTableColumn">{order.area_sq_feet}</td>
                    <td className="ordersComponentTableColumn">{order.total_bill &&  order.total_bill.toLocaleString()}</td>
                    <td className="ordersComponentTableColumn">{order.amount_paid && order.amount_paid.toLocaleString()}</td>
                    <td className="ordersComponentTableColumn">{order.amount_to_pay && order.amount_to_pay.toLocaleString()}</td>
                    <td>
                      <Link to={`/unitOrderDetails/${order.id}`}>
                        View Order Details
                      </Link>
                    </td>
                  </tr>
                ))}
              </table>
              {loading && <Loading loading={loading} />}
            </div>
          </>
        );

        break;

      default:
        break;
    }
  };

  return (
    <>
      <div className="transectionMainBox">
        <div className="transectionHeadSection">
          <h4 className="transectionSectionHeading">Transactions Section</h4>
          <p className="transectionSectionDescription">
            Here you can find of all your confirmed, pending, and upcoming
            transactions from the orders you placed.
          </p>
          <div className="transectionSectionBtnBox">
            <Link to="/globaltransactions">
              <button className="transectionHeadSectionBtn">
                Public Transactions
              </button>
            </Link>
            <Link to="/currentProjects">
              <button className="transectionHeadSectionBtn">Purchase Now</button>
            </Link>
          </div>
        </div>

        {/* ORDERS SECTION */}
        <div className="transectionOrderSectionMainBox">
          <div className="orderSectionBtnBox">
            <button
              className={activeButton === "unitOrders" ? "buttonActive" : "orderSectionbtns"}
              onClick={() => handleOrderBtnClick("unitOrders")}
            >
              Unit Orders
            </button>
            <button
              className={activeButton === "flexibleOrders" ? "buttonActive" : "orderSectionbtns"}
              onClick={() => handleOrderBtnClick("flexibleOrders")}
            >
              Flexible Orders
            </button>

          </div>
          <div className="orderComponentMainBox">{renderOrdersComponent()}</div>
        </div>
      </div>
    </>
  );
}
export default Transaction;
