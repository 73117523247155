import React, { useEffect } from "react";
import "./PurchaseUnitPlan.css";
import "./PayementUnitPlan.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import { API_URL } from "../config";

const PurchaseUnitPlan = () => {
  const [payementOption, setPayementOption] = useState(0);
  const [isProceed, setIsProceed] = useState(false);
  const [pricePerSqft, setPricePerSqft] = useState(0);
  const [selectedArea, setSelectedArea] = useState("circularArea");
  const [CircularSqftInput, setCircularSqftInput] = useState(null);
  const [CoveredSqftInput, setCoveredSqftInput] = useState(null);
  const [totalPrice, setTotalPrice] = useState(0);
  const [AreaBill, setAreaBill] = useState(0); // defines the bill user has to pay
  const location = useLocation();
  const navigate = useNavigate();
  const {
    unit_type_id,
    unit_type_name,
    covered_area,
    floor_name,
    price_per_sqft,
    total_price,
    unit_type,
    project_id,
  } = location.state || {};
  const [totalPercentage, setTotalPercentrage] = useState(totalPrice);

  const handlePurchaseNow = () => {
    window.alert("Sucessfully Done");
    navigate("/transactions");
  };

  const handleCheckboxChange = (payementOption) => {
    let percentage = (payementOption / 100) * totalPrice;
    setTotalPercentrage(percentage);
    setPayementOption(payementOption);
  };

  const handleSelectAreaBtnClick = (button) => {
    setSelectedArea(button);
    calculateTotalPrice(button);
  };

  const calculateTotalPrice = (selectedArea) => {
    if (selectedArea === "circularArea") {
      setTotalPrice(
        CircularSqftInput * unit_type.unit_price_per_sqft_circulation_area
      );
      setPricePerSqft(unit_type.unit_price_per_sqft_circulation_area);
    } else if (selectedArea === "coveredArea") {
      setTotalPrice(
        CoveredSqftInput * unit_type.unit_price_per_sqft_covered_area
      );
      setPricePerSqft(unit_type.unit_price_per_sqft_covered_area);
    }
  };

  

  useEffect(() => {
  }, [selectedArea, CircularSqftInput, CoveredSqftInput]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("token");
      const formData = new FormData(); // Create a FormData object to send the file
      formData.append("unit_id", unit_type_id);
      if (selectedArea === "circularArea") {
        formData.append(
          "total_price",
          CircularSqftInput * unit_type.unit_price_per_sqft_circulation_area
        );
      } else if (selectedArea === "coveredArea") {
        formData.append(
          "total_price",
          CoveredSqftInput * unit_type.unit_price_per_sqft_covered_area
        );
      }

      // formData.append('total_price', totalPrice);

      // console.log(formData);
      // return;

      const response = await axios.post(`${API_URL}/api/unit_order`, {
        area_sq_feet: CircularSqftInput || CoveredSqftInput, //for now, sending any selected option 
        total_bill: AreaBill,
        unit_type_id:  unit_type_id,
        amount_left: AreaBill,
        project_id: project_id,
      },{
        headers: {
          Authorization: `Bearer ${token}`,
        },
    });

      calculateTotalPrice(selectedArea);

      alert(response.data.message);
      setIsProceed(true);
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        if (
          status === 400 ||
          status === 403 ||
          status === 404 ||
          status === 500
        ) {
          alert(error.response.data.message);
        }
      } else {
        console.error(error);
      }
    }
  };

  // SWITCH SELECT AREA
  const renderAreaDiv = () => {
    const handleCircularSqftInputChange = (e) => {
      const inputValue = e.target.value;
      const maxArea = unit_type.unit_remaining_circulation_area;
      const numericInput = parseFloat(inputValue);
      const validInput = isNaN(numericInput) ? 0 : numericInput;
      const restrictedInput = Math.min(validInput, maxArea);
      setCircularSqftInput(restrictedInput);
      setAreaBill(inputValue * unit_type.unit_price_per_sqft_circulation_area);
    };

    const handleCoveredSqftInputChange = (e) => {
      const inputValue = e.target.value;
      const maxArea = unit_type.unit_remaining_covered_area;
      const numericInput = parseFloat(inputValue);
      const validInput = isNaN(numericInput) ? 0 : numericInput;
      const restrictedInput = Math.min(validInput, maxArea);
      setCoveredSqftInput(restrictedInput);
      setAreaBill(inputValue * unit_type.unit_price_per_sqft_circulation_area);

    };

    switch (selectedArea) {
      case "circularArea":
        return (
          <>
            <div className="unitDetailsDIv">
              <h4 className="unitDetailDivHeading">Circular Area</h4>
              <div class="detailsRow">
                <div class="left-div">Total ciruclar area available: </div>
                <div class="right-div">
                  {unit_type.unit_remaining_circulation_area} Sq.ft
                </div>
              </div>
              <div class="detailsRow">
                <div class="left-div">Price Per Sq.ft: </div>
                <div class="right-div">
                  {unit_type.unit_price_per_sqft_circulation_area} PKR
                </div>
              </div>
              <div class="detailsRow">
                <div class="left-div">Enter your sq.ft: </div>
                <div class="right-div">
                  {" "}
                  <input
                  className="purchaseUnitPlanInput"
                    type="number"
                    id="circular-area-sqft"
                    placeholder="Enter sq ft"
                    min="0"
                    max={unit_type.unit_remaining_circulation_area}
                    onChange={handleCircularSqftInputChange}
                    value={CircularSqftInput}
                  ></input>{" "}
                </div>
              </div>
              <div class="detailsRow">
                <div class="left-div">Total Price</div>
                <div class="right-div">
                  {CircularSqftInput *
                    unit_type.unit_price_per_sqft_circulation_area}{" "}
                  PKR
                </div>
              </div>
            </div>
          </>
        );
        break;
      case "coveredArea":
        return (
          <>
            <div className="unitDetailsDIv">
              <h4 className="unitDetailDivHeading">Covered Area</h4>
              <div class="detailsRow">
                <div class="left-div">Total Covered Area available: </div>
                <div class="right-div">
                  {unit_type.unit_remaining_covered_area} Sq.ft
                </div>
              </div>
              <div class="detailsRow">
                <div class="left-div">Price Per Sq.ft</div>
                <div class="right-div">
                  {unit_type.unit_price_per_sqft_covered_area} PKR
                </div>
              </div>
              <div class="detailsRow">
                <div class="left-div">Enter your sq.ft: </div>
                <div class="right-div">
                  {" "}
                  <input
                    className="purchaseUnitPlanInput"
                    type="number"
                    id="covered-area-sqft"
                    placeholder="Enter sq ft"
                    min="0"
                    max={unit_type.unit_remaining_covered_area}
                    onChange={handleCoveredSqftInputChange}
                    value={CoveredSqftInput}
                  ></input>{" "}
                </div>
              </div>
              <div class="detailsRow">
                <div class="left-div">Total Price</div>
                <div class="right-div">
                  {CoveredSqftInput *
                    unit_type.unit_price_per_sqft_covered_area}{" "}
                  PKR
                </div>
              </div>
            </div>
          </>
        );
        break;

      default:
        break;
    }
  };

  // console.log("Data we got is: ", project_id, unit_type_id, unit_type_name, covered_area, floor_name, price_per_sqft, total_price);
  return (
    <>
      {!isProceed ? (
        <>
          <div className="payementUnitPlanMainBox">
            <h4 className="payementPlanHeading">{unit_type_name}</h4>

            <div className="projectUnitlanBtnBox">
              <button
                className="purchaseUnitPlanBtn"
                onClick={() => handleSelectAreaBtnClick("circularArea")}
              >
                Circular Area
              </button>
              <button
                className="purchaseUnitPlanBtn"
                onClick={() => handleSelectAreaBtnClick("coveredArea")}
              >
                Covered Area
              </button>
            </div>

            <div>{renderAreaDiv()}</div>
            <div className="buttonDiv">
              <button className="purchaseNow-button" onClick={handleSubmit}>
                Purchase Now
              </button>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="payementUnitPlanMainBox">
            <h4 className="payementPlanHeading">Choose Payment Plan</h4>
            <div className="payementPlanDetailMainBox">
              <div className="selectPayementPlanBox">
                <h4>Choose Payment Plan</h4>
                <div className="checkPayementPlanBox">
                  <input
                    type="checkbox"
                    id="payement100"
                    className="payementPlanCheckBox"
                    checked={payementOption === 100}
                    onChange={() => handleCheckboxChange(100)}
                  />
                  <label for="payement100">Payment Plan 100%</label>
                </div>

                <div className="checkPayementPlanBox">
                  <input
                    type="checkbox"
                    id="payement75"
                    className="payementPlanCheckBox"
                    checked={payementOption === 75}
                    onChange={() => handleCheckboxChange(75)}
                  />
                  <label for="payement75">Payment Plan 75%</label>
                </div>

                <div className="checkPayementPlanBox">
                  <input
                    type="checkbox"
                    id="payement50"
                    className="payementPlanCheckBox"
                    checked={payementOption === 50}
                    onChange={() => handleCheckboxChange(50)}
                  />
                  <label for="payement50">Payment Plan 50%</label>
                </div>

                <div className="checkPayementPlanBox">
                  <input
                    type="checkbox"
                    id="payement25"
                    className="payementPlanCheckBox"
                    checked={payementOption === 25}
                    onChange={() => handleCheckboxChange(25)}
                  />
                  <label for="payement25">Payment Plan 25%</label>
                </div>
              </div>
              <div className="payementPlanSummaryBox">
                <h4 className="payementPlanHeading">SUMMARY</h4>
                <div className="summaryDetailMainBox">
                  <div className="summaryDetailDataBox">
                    <p className="summaryPayementLabel">Lower GR-Floors</p>
                    <p className="summaryPayementData">{}</p>
                  </div>
                  <div className="summaryDetailDataBox">
                    <p className="summaryPayementLabel">Price Per Sq.ft</p>
                    <p className="summaryPayementData">{pricePerSqft} PKR</p>
                  </div>
                  <div className="summaryDetailDataBox">
                    <p className="summaryPayementLabel">Total Price </p>
                    <p className="summaryPayementData">{totalPrice} PKR</p>
                  </div>
                  <div className="summaryDetailDataBox">
                    <p className="summaryPayementLabel">Ammount To Pay</p>
                    <p className="summaryPayementData">{totalPercentage} PKR</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="payementPlanBtnBox">
              <div className="payementTermsAndConditonsBox">
                <input type="checkbox" id="termsAndConditons" />
                <label for="termsAndConditons">
                  I accept to terms & condition of Infratech DAO.
                </label>
              </div>
              <div className="payementDoneBtnBox">
                <button
                  className="payementSubmitBtn"
                  onClick={handlePurchaseNow}
                >
                  PURCHASE NOW
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default PurchaseUnitPlan;
