import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import UsersProfile from "./pages/UserProfile/UsersProfile";
import Purchase from "./pages/Purchase.jsx";
import Signup from "./components/Signup.jsx";
import Login from "./components/Login.jsx";
import Welcomeinfratech from "./components/Welcomeinfratech";
import Almostdone from "./components/Almostdone";
import Verifylogin from "./components/Verifylogin";
import Transactions from "./pages/Transection/Transactions.jsx";
import LearningCenter from "./pages/LearningCenter.jsx";
import Ownershipjouney from "./components/Ownershipjourney";
import PurchaseN from "./components/PurchaseN.jsx";
import Reports from "./pages/Reports";
import GlobalTransaction from "./pages/GlobalTransaction";
import PurchaseDetails from "./pages/PurchaseDetails";
import OrderSummary from "./pages/OrderSummary";
import OrdersSummary from "./pages/OrdersSummary";
import PurchaseArea from "./pages/PurchaseArea";
import UserPortfolio from "./pages/UserPortfolio";
import PurchaseUnit from "./components/PurchaseUnit";
import ForgotPassword from "./components/ForgotPassword";
import FlexiblePaymentDetails from "./pages/Transection/FlexiblePaymentDetails";
import PurchaseUnitPlan from "./components/PurchaseUnitPlan";
import PaymentSummary from "./components/PaymentSummary";
import AccountDetails from "./components/AccountDetails";
import PrivateRoute from "./PrivateRoute";
import LogInRoute from "./loginRoute";
import ProjectDetailss from "./pages/ProjectDetailss";
import CurrentProjects from "./pages/CurrentProjects";
import MaintenanceMode from "./pages/MaintenanceMode";
import { API_URL } from "./config";
import { useEffect, useState } from "react";
import PayementUnitPlan from "./components/PayementUnitPlan";
import Dashboard from "./pages/Dashboard";
import UnitPaymentDetails from "./pages/Transection/UnitPaymentDetails";
import NavBarAndSideBar from "./components/NavBarAndSideBar";
import InfraListings from "./pages/MarketPlace/InfraListings";
import MarketplaceOrderDetails from "./pages/MarketPlace/MarketplaceOrderDetails";
import GoogleDashboard from "./pages/GoogleDashboard";
import PurchaseUnitPlanNew from "./components/PurchaseUnitPlanNew";
import jwtDecode from 'jwt-decode';
import Cookies from 'js-cookie';
import { useSignOut } from "react-auth-kit";
import UnitOrderDetails from "./pages/Transection/UnitOrderDetails";
import UnitPaymentPlan from "./pages/Transection/UnitPaymentPlan";
import FlexibleOrderDetails from "./pages/Transection/FlexibleOrderDetails";

function App() {

  // const isLogin = localStorage.getItem('token');
  const [maintenanceMode, setMaintenanceMode] = useState(false);
  const signOut = useSignOut();


  useEffect(() => {
    const checkTokenExpiration = () => {
      const storedToken = Cookies.get('_auth'); // Change '_auth' to your cookie name

      if (storedToken) {
        const decodedToken = jwtDecode(storedToken);
        const currentTime = Date.now() / 1000;

        if (decodedToken.exp < currentTime) {
          // Token has expired, perform logout and redirect
          // You can implement your logout logic and redirection here
          Cookies.remove('_auth'); // Remove the expired token cookie
          signOut();
          window.location.href = '/'; // Redirect to the login page
        } else {
          // Schedule the next check after 1 minute
          setTimeout(checkTokenExpiration, 60000); // 1 minute
        }
      }
    };

    checkTokenExpiration(); // Start the initial check
     // Reload the page every hour (3600000 milliseconds)
     setInterval(() => {
      window.location.reload();
    }, 3600000); // 1 hour
  });



 

  useEffect(() => {
    fetch(`${API_URL}/api/config`)
      .then(response => {
        if (response.status === 503) {
          setMaintenanceMode(true);
        }
      })
      .then(data => {
        if (data) {
          setMaintenanceMode(data.maintenanceMode);
        }
      })
      .catch(error => console.error(error));
  }, []);


  if (maintenanceMode) {
    return (
      <div>
        <MaintenanceMode />
      </div>
    );
  }

  // const removeTokenAfterOneHour = () => {
  //   localStorage.removeItem('token');
  // };

  // // Start the timer when the user logs in
  // setTimeout(removeTokenAfterOneHour, 1 * 1 * 1000);


  
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            path="/*"
            element={
              <>
                <NavBarAndSideBar />
                <Routes>
                  <Route path="/dashboard/:token" element={ <GoogleDashboard/> } />
                  <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
                  <Route path="/profile/*" element={<PrivateRoute><UsersProfile /></PrivateRoute>} />
                  <Route path="/currentProjects" element={<PrivateRoute><CurrentProjects /></PrivateRoute>} />
                  <Route path="/projectDetails/:projectId" element={<PrivateRoute><ProjectDetailss /></PrivateRoute>} />
                  <Route path="/purchase" element={<PrivateRoute><Purchase /></PrivateRoute>} />
                  <Route path="/transactions" element={<PrivateRoute><Transactions /></PrivateRoute>} />
                  <Route path="/learningCenter" element={<PrivateRoute><LearningCenter /></PrivateRoute>} />
                  <Route path="/purchaseArea" element={<PrivateRoute><PurchaseArea /></PrivateRoute>} />
                  <Route path="/OrdersSummary" element={<PrivateRoute><OrdersSummary /></PrivateRoute>} />
                  <Route path="/ownershipjourney/:projectId" element={<PrivateRoute><Ownershipjouney /></PrivateRoute>} />
                  <Route path="/purchaseN" element={<PrivateRoute><PurchaseN /></PrivateRoute>} />
                  <Route path="/reports" element={<PrivateRoute><Reports /></PrivateRoute>} />
                  <Route path="/UserPortfolio" element={<PrivateRoute><UserPortfolio /></PrivateRoute>} />
                  <Route path="/globaltransactions" element={<PrivateRoute><GlobalTransaction /></PrivateRoute>} />
                  <Route path="/purchaseArea/purchaseDetails" element={<PrivateRoute><PurchaseDetails /></PrivateRoute>} />
                  <Route path="/purchaseUnit" element={<PrivateRoute><PurchaseUnit /></PrivateRoute>} />
                  <Route path="/payementUnitPlan" element={<PayementUnitPlan />} />
                  <Route path="/purchaseUnitPlanN" element={<PurchaseUnitPlanNew/>} />
                  <Route path="/purchaseUnitPlan" element={<PrivateRoute><PurchaseUnitPlan /></PrivateRoute>} />
                  <Route path="/infralistings" element={<PrivateRoute> <InfraListings /> </PrivateRoute>} />
                  <Route path="/MarketplaceOrderdetails" element={<PrivateRoute> <MarketplaceOrderDetails /> </PrivateRoute>}/>
          
                  <Route path="/unitOrderDetails/:orderId" element={<PrivateRoute><UnitOrderDetails/> </PrivateRoute>} />
                  <Route path="/unitPaymentPlan/:orderId" element={<PrivateRoute><UnitPaymentPlan/> </PrivateRoute>} />
                  <Route path="/unitpaymentDetails/:unitId" element={<PrivateRoute><UnitPaymentDetails /></PrivateRoute>} />
                  <Route path="/flexibleOrderDetails/:orderId" element={<PrivateRoute><FlexibleOrderDetails/> </PrivateRoute>} />
                  <Route path="/flexiblePaymentDetails/:orderId" element={<PrivateRoute><FlexiblePaymentDetails /></PrivateRoute>} />
                </Routes>
              </>
            }
          />
          <Route path="/ordersummary" element={<PrivateRoute><OrderSummary /></PrivateRoute>} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/" element={<LogInRoute loginPath="/">  <Login /> </LogInRoute>} />
          <Route path="/Welcomeinfratech" element={<PrivateRoute><Welcomeinfratech /></PrivateRoute>} />
          <Route path="/almostdone" element={<PrivateRoute><Almostdone /></PrivateRoute>} />
          <Route path="/verifylogin" element={<Verifylogin />} />
          <Route path="/paymentSummary" element={<PrivateRoute><PaymentSummary /></PrivateRoute>} />
          <Route path="/accountDetails" element={<PrivateRoute><AccountDetails /></PrivateRoute>} />
          <Route path="/forgotPassword" element={<ForgotPassword />} />
        </Routes>
      </BrowserRouter>

    </>
  );
}

export default App;
