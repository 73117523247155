import React from "react";
import "./UsersProfile.css";
import personalDetail from "../../assets/Settings_Personal_Details.png";
import userAddress from "../../assets/Settings_Address.png";
import legalInfo from "../../assets/Settings_LegalInfo.png";
import NotificationsIcon from "../../assets/Settings_Notification.png";
import ChangePasswordIcon from "../../assets/Settings_Password.png";
import bankDetailsIcon from "../../assets/Settings_Bank.png";
import WalletIcon from "../../assets/Settings_Wallet.png";
import { Link, Route, Routes, NavLink } from "react-router-dom";
import PersonalDetail from "../UserProfile/PersonalDetail";
import Address from "../UserProfile/Address";
import Notification from "../UserProfile/Notification";
import Chanepassword from "../UserProfile/Chanepassword";
import Bankdetail from "../UserProfile/Bankdetail";
import Wallet from "../UserProfile/Wallet";
// import Legalinfo from "./infopage";
import Infopage from "../UserProfile/infopage";


const UserProfile = () => {
  return (
    <>
      <div className="userProfileMainBox">
        <div className="userProfileSettingsBox">
          <Link
           to="/profile/personal" 
           className="Link">
            <div className="userProfileDataBox">
              <img src={personalDetail} alt="" className="userProfileDataImage" />
              <span className="userProfileDataHeading">Personal Details</span>
            </div>
          </Link>

          <Link to="/profile/address" className="Link">
            <div className="userProfileDataBox">
              <img src={userAddress} alt="" className="userProfileDataImage" />
              <span className="userProfileDataHeading">Address</span>
            </div>
          </Link>

          <Link to="/profile/info" className="Link">
            <div className="userProfileDataBox">
              <img src={legalInfo} alt="" className="userProfileDataImage" />
              <span className="userProfileDataHeading">Legal Information</span>
            </div>
          </Link>

          <Link to="/profile/notification" className="Link">
            <div className="userProfileDataBox">
              <img src={NotificationsIcon} alt="" className="userProfileDataImage" />
              <span className="userProfileDataHeading">Notifications</span>
            </div>
          </Link>

          <Link to="/profile/change" className="Link">
            <div className="userProfileDataBox">
              <img src={ChangePasswordIcon} alt="" className="userProfileDataImage" />
              <span className="userProfileDataHeading">Change Password</span>
            </div>
          </Link>

          <Link to="/profile/bankdetail" className="Link">
            <div className="userProfileDataBox">
              <img src={bankDetailsIcon} alt="" className="userProfileDataImage" />
              <span className="userProfileDataHeading">Bank Details</span>
            </div>
          </Link>

          <Link to="/profile/wallet" className="Link">
            <div className="userProfileDataBox">
              <img src={WalletIcon} alt="" className="userProfileDataImage" />
              <span className="userProfileDataHeading">Wallet Info</span>
            </div>
          </Link>
        </div>


        {/* <div className="pDContainer"> */}
        <Routes>
          <Route path="/" element={<PersonalDetail />} />
          <Route path="/personal" element={<PersonalDetail />} />
          <Route path="/address" element={<Address />} />
          <Route path="/info/*" element={<Infopage />} />
          <Route path="/notification" element={<Notification />} />
          <Route path="/change" element={<Chanepassword />} />
          <Route path="/bankdetail" element={<Bankdetail />} />
          <Route path="/wallet" element={<Wallet />} />
        </Routes>
      </div>
      {/* </div> */}
    </>
  );
};

export default UserProfile;
