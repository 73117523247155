import React from "react";
import "./LearningCenter.css";
import Rect291 from "../assets/Rect291.png";
import Rect292 from "../assets/Rect292.png";
import Rect293 from "../assets/Rect293.png";
import Rect294 from "../assets/Rect294.png";
import Rect295 from "../assets/Rect295.png";
import Rect302 from "../assets/kharian.jpg";
import Rect303 from "../assets/SakTower.jpeg";
import Rect304 from "../assets/Rect304.png";


const LearningCenter = () => {
  return (
    <>


    <div className="learningCenterMainBox">
      <div className="learningCenterSectionBox">
        <h4>Construction Explained Learn the tips and tricks of Construction
          industry, straight from the experts.</h4>
          <div className="videoSectionBox">
          <iframe className="learningVideo" src="https://www.youtube.com/embed/mbwuj58UEPg" frameborder="0"></iframe>
          <img  src={Rect291} alt="Rectangle291" />
          <img  src={Rect292} alt="Rectangle292" />
          </div>
      </div>
      <div className="learningCenterSectionBox">
        <h4>Infra-Tech Master Class Catch real estate industry experts in
          conversation.</h4>
          <div className="videoSectionBox">
          <img src={Rect293} alt="Rectangle293" />
        <img  src={Rect294} alt="Rectangle294" />
        <img src={Rect295} alt="Rectangle295" />
          </div>
      </div>
      <div className="learningCenterSectionBox">
        <h4>Infra-Tech Explained Find out how Infra-Tech is building a real estate
          ecosystem for everyone</h4>
          <div className="videoSectionBox">
          <iframe className="learningVideo" src="https://www.youtube.com/embed/FFo-KeX_AAk" frameborder="0"></iframe>
          <img  src={Rect291} alt="Rectangle291" />
          <img  src={Rect292} alt="Rectangle292" />
          </div>
      </div>
      <div className="learningCenterSectionBox">
        <h4>Customer Series Watch what our existing, loyal customers have to say
          about their experience.</h4>
          <div className="videoSectionBox">
          <iframe className="learningVideo" src="https://www.youtube.com/embed/_hJIWyl-UgQ" frameborder="0"></iframe>
          <img  src={Rect291} alt="Rectangle291" />
          <img  src={Rect292} alt="Rectangle292" />
          </div>
      </div> 
      <div className="learningCenterSectionBox">
        <h4>SAK Towers DHA Phase 5</h4>
          <div className="videoSectionBox">
          <img  src={Rect302} alt="Rectangle302" />
        <img  src={Rect303} alt="Rectangle303" />
        <img  src={Rect304} alt="Rectangle304" />
          </div>
      </div>  

    </div>
    </>
  );
};
export default LearningCenter;
