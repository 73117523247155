import React from 'react'
import './MarketplaceOrderDetails.css'
import './InfraListings.css'
import { Link} from 'react-router-dom'
import { useState } from 'react'
import img from '../../assets/Flag.png'

const OrderDetails = () => {
    const [isChecked, setIsChecked] = useState(false);

    const handleDeleteCheck = ()=>{
        setIsChecked(!isChecked);
    }

    return (
        <div className="infraOrderDetailsMainBox">
            <div className="infraOrderHeadBox">
                <h4>InfraListings</h4>
                <p>A Your journey to financial freedom begins here.</p>
                <div className="infraListingBtnBox">
                    <Link to='/infralistings'>
                        <button>MarketPlace</button>
                    </Link>
                </div>
            </div>
            <div className="infraOrderDetailBox">
                <div className="orderDetailImage">
                    <img src={img} alt="THis Is Order Detail" />
                </div>
                <div className="orderDetailData">
                    <h4>MY ORDER</h4>
                    <div className="infraMyOrderData">
                        <div className="infraDataBox">
                            <span>ID Number</span>
                            <span>DATA</span>
                        </div>
                        <hr />
                        <div className="infraDataBox">
                            <span>Project Name</span>
                            <span>DATA</span>
                        </div>
                        <hr />
                        <div className="infraDataBox">
                            <span>Area (sq.ft)</span>
                            <span>DATA</span>
                        </div>
                        <hr />
                        <div className="infraDataBox">
                            <span>Demand Ammount</span>
                            <span>DATA</span>
                        </div>
                        <hr />
                        <div className="infraDataBox">
                            <span>Total Offers</span>
                            <span>DATA</span>
                        </div>
                        <hr />
                        <div className="infraDataBox">
                            <span>ID Number</span>
                            <span>DATA</span>
                        </div>
                        <hr />
                        <div className="infraDataBox">
                            <button>UPDATE</button>
                        </div>
                        <div className="infraDataBox" style={{border: "1px dotted grey"}}>
                            <span>
                                <input type="checkbox" 
                                checked={isChecked}
                                onChange={handleDeleteCheck}/>
                                <span>Are You Sure You Want To Delete This Order?</span>
                            </span>
                            <button disabled={!isChecked}>DELETE</button>
                        </div>

                    </div>
                </div>
            </div>

            <div className="listingOrderComponent">
                <h5 className="listingComponentHeading">OFFERS</h5>
                <div className="ComponentTableBox">
                    <table className='listingOrderTable'>
                        <tr>
                            <th className='listingTableHeading'>Date</th>
                            <th className='listingTableHeading'>Order ID</th>
                            <th className='listingTableHeading'>User Name</th>
                            <th className='listingTableHeading'>Area sq.ft</th>
                            <th className='listingTableHeading'>Demand</th>
                            <th className='listingTableHeading'>Offer</th>
                            <th className='listingTableHeading'>Action</th>
                        </tr>
                        <tr>
                            <td>DATE</td>
                            <td>DATE</td>
                            <td>DATE</td>
                            <td>DATE</td>
                            <td>DATE</td>
                            <td>DATE</td>
                            <td>
                                <Link to='/orderdetails'>
                                    <button className='orderSellNowBtn'>SELL NOW</button>
                                </Link>
                            </td>

                        </tr>
                    </table>

                </div>


            </div>
        </div>
    )
}

export default OrderDetails