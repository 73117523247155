import React, { useState, useEffect } from 'react';
import './CurrentProjects.css';
import projectLogo from "../assets/projectLogo.png";
import sakRating1 from "../assets/sakrating.jpg";
import statusImg from "../assets/projectStatusimg.png"
import areaImg from "../assets/areaImg.png"
import axios from 'axios';
import { Link } from "react-router-dom";
import { API_URL } from '../config';
// import { circularData, coveredData } from '../components/SakResidentialCardData';

// import { Link } from "react-router-dom";
import projectimg from "../assets/sakbgImg.jpg"
import All from '../assets/AllProjecticon.png';
import developmental from "../assets/developmentalicon.png"
import mature from "../assets/MatureIcon.png"
import RatingStars from '../components/RatingStars';
const CurrentProjects = () => {
  const [selectedButton, setSelectedButton] = useState('all');
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/Projects`);
        setProjects(response.data);
      } catch (error) {
        console.log('Error fetching projects:', error);
      }
    };

    fetchProjects();
  }, []);

  const handleButtonClick = (projectButtoname) => {
    setSelectedButton(projectButtoname);
  };
  // const handleButtonClick = (projectButtonName) => {
  //   if (projectButtonName === 'developmental' || projectButtonName === 'mature') {
  //     setSelectedButton('all');
  //   } else {
  //     setSelectedButton(projectButtonName);
  //   }
  // };

  const getContent = () => {
    switch (selectedButton) {
      case 'developmental':
        return <p>Comming Soon</p>;
      case 'mature':
        return <p>Comming Soon</p>;
      default:
        return <>
          <div className="currentProjectsDiv">
            {projects.map((project) => {
              return (
                <Link to={`/projectDetails/${project.id}`} key={project.id} className='ProjectCardLink'>
                  <div className="projectCards" >

                    <div className="cardDiv">
                      <img src={`${API_URL}/public/${project.project_logo}`} alt="" className=' projectImg' />
                      <div className="logoAndNameDiv">
                        <div className="projectLogoDiv">
                          <img className="projectLogo" src={projectLogo} />
                          {/* <img className="projectLogo" src={project.project_logo} /> */}
                        </div>
                        <div className="nameAndRating">
                          <p className="projectNameText">{project.name}</p>
                          <div className="imgNtxt">
                            <RatingStars rating={project.obtained_rating} />
                            <span> ( {project.obtained_rating} )</span>

                          </div>

                        </div>
                      </div>
                      <div className="projectDescriptionDiv">
                        <p className='projectDescriptionPara'>{project.description}</p>
                      </div>
                      <div className="projectStatusAndAreaDiv">
                        <div className="projectStatus">
                          <div className="statusImgAndTextDiv">
                            <div className="statusImgDiv">
                              <img src={statusImg} alt=" status" className='statusImg' />
                            </div>
                            <div className="statusTextDiv">
                              <p className='statusText'>Project Status</p>
                              <p className='statusTextPercent'>(20%)</p>
                            </div>
                          </div>
                        </div>
                        <div className="projectArea">
                          <div className="statusImgAndTextDiv">
                            <div className="statusImgDiv">
                              <img src={areaImg} alt=" area" className='statusImg' />
                            </div>
                            <div className="statusTextDiv">
                              <p className='statusText'>Total Area</p>
                              <p className='statusTextPercent'>{project.area}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>


                  </div>
                </Link>
              );
            })}
          </div>
        </>;
    }
  };

  return (
    <div className="currentProjectContainer">
      <div className='exploreProjectDiv'>
        <p className='ExploreProjectsText'> Explore Projects</p>

        <div className="buttonContainer">

          <div className="row">
            <div className="imageContainer">
              <img src={All} alt="Button Icon" className="buttonIcon" />
            </div>
            <button
              className={`projectButton ${selectedButton === 'all' ? 'projectBtnActive' : ''}`}
              onClick={() => handleButtonClick('all')}
            >
              All
            </button>
          </div>


          <div className="row">
            <div className="imageContainer">
              <img src={developmental} alt="Button Icon" className="buttonIcon" />
            </div>
            <button
              className={`projectButtonDevelopmental ${selectedButton === 'developmental' ? 'projectBtnActive' : ''}`}
              onClick={() => handleButtonClick('developmental')}

            >
              Developmental
            </button>
          </div>


          <div className="row">
            <div className="imageContainer">
              <img src={mature} alt="Button Icon" className="buttonIcon" />
            </div>
            <button
              className={`projectButton ${selectedButton === 'mature' ? 'projectBtnActive' : ''}`}
              onClick={() => handleButtonClick('mature')}

            >
              Mature
            </button>
          </div>

        </div>


      </div>


      {getContent()}
    </div>
  );
};

export default CurrentProjects;
