import React from "react";
import './Signup.css'
import { Link } from "react-router-dom";
import logo from "../assets/infra logo-01.png";
import { useNavigate } from "react-router-dom";

const Welcomeinfratech = () => {

  const navigate = useNavigate();
    const verify = () => {
    navigate('/Verifylogin');
  }

  return (
    <>
     
    <div className="sNavbar__img">
        <img className="sNavbar__logo" src={logo} alt="" />
    </div>

    <div className="sContainer">
        <div className="sContainer1">
          <h1 className="form-title"> Welcome to Infratech DAO</h1>
          <form action="#">
            <div className="main-user-info">
            <div className="wSubTitle">
              <h2 className="wSubTitleh2">Personal Information</h2>
              <hr  />
              <p className="wSubTitleP">Please enter your full name as on your Govt. issued ID</p>
            </div>

              <div className="input-box">
                <label htmlFor="fname">Full Name</label>
                <input type="text" placeholder="Full Name" name="fname" autofocus />
              </div>

              <div className="input-box">
                <label htmlFor="cnic">CNIC Number</label>
                <input type="text" id="cnic" name="cnic" placeholder="XXXXX-XXXXXXX-X" pattern="^[0-9]{5}-[0-9]{7}-[0-9]{1}$" />
              </div>

      
              <div className="input-box">
                <label htmlFor="ph">Phone Number</label>
                <input type="tel" id="ph" name="ph" placeholder="+92-0000000000" pattern="^((\+92)?(0092)?(92)?(0)?)(3)([0-9]{9})$/gm"/>
              </div>

            <div className="sign-btn verfBtn">
              <button onClick={verify}>Two-Step Verification&nbsp;&rarr;
              </button>
            </div>

            
              <p className="privacy-link">
                Your data is safe with us! &nbsp;  <Link>Privacy Policy&nbsp; </Link> and
               <Link>&nbsp;Security&nbsp; </Link>
              </p>
          
            </div>
          </form>
        </div>
      </div>

    </>
  );
};

export default Welcomeinfratech;
