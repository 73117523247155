import React from "react";
import "./Almostdone.css";
import { Link } from "react-router-dom";
import PakistanFlag from "../assets/image 3.png";

const Almostdone = () => {
  return (
    <>
      <div className="Almost">
        <form className="almostForm">
          <div>
            <h1 className="almostD">Almost Done</h1>
            <h3 className="authen">Set up a Two-Step authentication</h3>
            <span className="Apara">
              Two-factor authentication adds an extra layer of security. Please
              enter your phone number to enable two factor authentication.
            </span>
          </div>
          <h3 className="phoneNo">Phone Number</h3>
          <input className="phoneNum" placeholder="3156025653"></input>
          <select className="Aselect">
            <option placeholder="">+92</option>
          </select>
          <img className="pakistan" alt="" src={PakistanFlag} />
          <Link to="/welcominfr">
            <button className="bacK">Back</button>
          </Link>

          <Link to="/Verifylogin">
            <button className="Anext">Next</button>{" "}
          </Link>
          <p className="security">
            Your data is safe with us; Privacy Ploicy & Security{" "}
          </p>
        </form>
      </div>
    </>
  );
};

export default Almostdone;
