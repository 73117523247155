// MaintenanceMode.js
import MaintenanceIcon from "../images/maintenance_icon.png";
import "./MaintenanceMode.css"
const MaintenanceMode = () => {
    return (
        <div className="maintanance_mode_body">
            <img id="img360" src={MaintenanceIcon}></img>
            <h1 className="maintance_h1">Sorry we're down for maintenance.</h1>
            <h2>We'll be back shortly.</h2>
        </div>
    );
}

export default MaintenanceMode;
