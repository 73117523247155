import React from 'react'
import './flexibleOrderDetails.css'
import { useState, useEffect } from 'react'
import appartmentImg from "../../assets/appartment.jpg"
import { ImCancelCircle } from 'react-icons/im'
import { MdOutlineLibraryAdd } from 'react-icons/md'
import { BsDownload } from 'react-icons/bs'
import { useParams, Link } from 'react-router-dom'
import axios from "axios";
import { API_PUBLIC_PATH, API_URL } from '../../config'
import Cookies from 'js-cookie';
import { SyncLoader } from 'react-spinners';

const FlexibleOrderDetails = () => {

    const { orderId } = useParams()
    const [loading, setLoading] = useState(true);
    const [selectedImg, setSelectedImg] = useState(false);
    const [isPaymentPlan, setIsPaymentPlan] = useState(false);
    const [OrderDetail, setOrderDetail] = useState('')
    const [TransectionSlips, setTransectionSlips] = useState('')
    const paymentStatusStyle = {
        color: OrderDetail.payment_status === 'Pending' ? 'red' : 'green',
    };

    const fetchFlexibleOrder = async () => {
        const token = Cookies.get('_auth');
        try {
            const response = await axios.get(`${API_URL}/api/order/${orderId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            });
            setOrderDetail(response.data);
            setTransectionSlips(response.data.order_payment_slips);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    const closeModal = () => {
        setSelectedImg(null);
    }

    useEffect(() => {
        fetchFlexibleOrder();
    }, [])

    useEffect(() => {
        if (selectedImg) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [selectedImg]);

    const downloadFile = async (url, filename) => {
        const imageUrl = url.split(`${API_PUBLIC_PATH}`)
        if (imageUrl[1] !== 'null') {
          const response = await fetch(url);
          const blob = await response.blob();
          const blobUrl = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement('a');
          link.href = blobUrl;
          link.download = filename;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
    };


    return (
        <>

            <div className="unitOrderDetailMainBox">

                <h4 className='unitOrderDetailHeading'>Flexible Order Details</h4>

                {loading ? (<>
                    <div className='unitPaymentPlanLoadingBox'>
                        <span>Loading Please Wait  </span>
                        <SyncLoader
                            size={10}
                            color={'#03989E'}
                            loading={loading}
                        />
                    </div>

                </>) : (<>
                    <div className="unitOrderDetailDataMainBox">
                        <div className="unitPlanImageBox">
                            <img src={appartmentImg} alt="" />
                        </div>

                        <div className="unitOrderDataBox">
                            <div className="unitOrderDataColumn">
                                <span>Id No.</span>
                                <span>SAK-{OrderDetail.id} </span>
                            </div>
                            <div className="unitOrderDataColumn">
                                <span>Project Name</span>
                                <span>{OrderDetail.project.name}</span>
                            </div>

                            <div className="unitOrderDataColumn">
                                <span>Location</span>
                                <span>Islamabad</span>
                            </div>
                            <div className="unitOrderDataColumn">
                                <span>Total Area (sq.ft)</span>
                                <span>{OrderDetail.area_sq_feet} (sq.ft)</span>
                            </div>
                            <div className="unitOrderDataColumn">
                                <span>Status</span>
                                <span style={paymentStatusStyle}>{OrderDetail.payment_status}</span>
                            </div>
                            <hr />


                        </div>

                    </div>

                    <div className="unitOrderDataTableBox">
                        <table className='unitPlanDataTable'>
                            <tr>
                                <th>Total Amount</th>
                                <th>Paid Amount</th>
                                <th>Reaming Amount</th>
                                <th>Current Amount to Pay</th>
                            </tr>

                            <tr>
                                <td>{OrderDetail.amount_to_pay} (PKR)</td>
                                <td>{OrderDetail.amount_paid} (PKR)</td>
                                <td>{OrderDetail.amount_to_pay - OrderDetail.amount_paid} (PKR)</td>
                                <td>{OrderDetail.amount_to_pay} (PKR)</td>
                            </tr>
                        </table>
                    </div>

                    <div className="unitOrderSlipGernerateBox">
                        {isPaymentPlan ? (<>
                            <p>Your Order Status Is Pending. Please Generate Another Slip Amount You Want To Pay</p>

                            <button className='generateSlipBtn'>Generate Slip</button>
                        </>) : (<>
                            <p style={{ fontSize: '1.8rem' }}> Please Submit the Remaining <strong>{OrderDetail && OrderDetail.amount_to_pay.toLocaleString()}</strong> Amount and Upload Slip to The Admin for Verification</p>
                        </>)}
                    </div>

                    <div className="unitOrderSlipsMainBox">
                        <div className='unitOrderSlipsHeading'>
                            <h4>All Transection History</h4>
                            <Link to={`/flexiblePaymentDetails/${orderId}`}>
                                <span className='uploadSlipBtn'>
                                    Upload Slip <MdOutlineLibraryAdd className='uploadSlipIcon' />
                                </span>
                            </Link>
                        </div>
                        <table className='unitOrderSlipsTable'>
                            <tr>
                                <th>Transection Id</th>
                                <th>Bank </th>
                                <th>Date</th>
                                <th>Status</th>
                                <th>Details</th>
                            </tr>
                            {TransectionSlips && TransectionSlips.map((Slip) => (
                                <tr key={Slip.id}>
                                    <td>{Slip.id < 10 ? "0" + Slip.id : Slip.id}</td>
                                    <td>Askari Bank Ltd</td>
                                    <td>{new Date(Slip.createdAt).toLocaleDateString()}</td>
                                    <td>{Slip.title}</td>
                                    <td>
                                        <span className='unitOrderViewSlip' onClick={() => setSelectedImg(`${API_PUBLIC_PATH}${Slip.slipPath}`)}>
                                            View Slip
                                        </span>
                                    </td>
                                </tr>
                            ))}
                        </table>
                    </div>
                </>)
                }

            </div>

            {selectedImg && (
                <div className="modal" >
                    <ImCancelCircle className='modalCancelBtn' onClick={closeModal} />
                    <div className="modalMain">

                        <BsDownload className='downloadSlipIcon' onClick={() => downloadFile(selectedImg, 'downloaded_image.jpg')} />
                        <img src={selectedImg} alt="Your Slip Not Found. Please Contact Admin" className='unitOrderModalImg' />
                    </div>
                </div>
            )}


        </>
    )
}

export default FlexibleOrderDetails