import React, { useEffect, useState } from 'react'
import qr from "../../assets/qr.png";
import nalbu from "../../assets/nalbu.png";
import "./Wallet.css"
import axios from 'axios';
import { API_URL } from '../../config';
import { BiCopyAlt } from 'react-icons/bi'
import { useRef } from 'react';
import QRCode from 'qrcode.react';
import Cookies from 'js-cookie';


function Wallet() {

  const publicWallet = useRef(null);
  const privateWallet = useRef(null);
  const [PublicAddress, setPublicAddress] = useState('');
  const [PrivateKey, setPrivateKey] = useState('');

  useEffect(() => {
    loadTokenData();
  }, []);

  const handlePublicCopy = () => {
    if (publicWallet.current) {
      publicWallet.current.select();
      document.execCommand('copy');
      window.alert("Text copied to clipboard")

      // Optionally, you can display a success message or perform any other action
      // console.log('Text copied to clipboard');
    }
  };

  const handlePrivateCopy = () => {
    if (privateWallet.current) {
      privateWallet.current.select();
      document.execCommand('copy');
      window.alert("Text copied to clipboard")

      // Optionally, you can display a success message or perform any other action
      // console.log('Text copied to clipboard');
    }
  };

  const loadTokenData = async () => {
    const token = Cookies.get("_auth");

    const config = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    };

    // Make a request with the config object
    axios.get(`${API_URL}/api/currentuser`, config)
      .then(response => {
        setPublicAddress(response.data.wallets[0].public_address);
        setPrivateKey(response.data.wallets[0].private_key);
      })
      .catch(error => {
        console.log(error);
      });
  }

  return (
    <>
      <div className="personalDetailMainBox">
        <h4 className="walletHeading">Wallet info - Use This Address To Recieve Tokens For The Projects</h4>
        <div className="walletMainBox">
          <div className="walletBox">
            <div className="walletDataMainBox">
              <div className="walletDataBox">
                <QRCode value={PublicAddress} alt="Woops! Something is wrong :(" className='WalletQRCode' size={120} />
              </div>
              <div className="walletDataBox">
                <input type="text" className='walletAddress' readOnly ref={publicWallet} value={PublicAddress} />
                <BiCopyAlt className='walletcopyClipBoard' onClick={handlePublicCopy} />
              </div>
            </div>
            <p className="walletDescription">Capture this QR code on your phone to copy and share your wallet address directly</p>
          </div>


        </div>
      </div>


    </>
  )
}

export default Wallet
