import React from 'react'
import "./OrderSummary.css"
import { Link } from 'react-router-dom'
import helloImg from "../assets/hello.PNG"
import { useState, useEffect } from "react";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { API_URL } from '../config';
import Cookies from 'js-cookie';



const PurchaseArea = () => {

    const [sideDisplay, setsideDisplay] = useState('0')
    const [sideContent, setsideContent] = useState('0')
    const [uploadTxtF, setuploadTxtF] = useState('Upload Front')
    const [uploadTxtB, setuploadTxtB] = useState('Upload Back')


    const [Area, setArea] = useState("");


    const displayMenu = () => {
        if (sideDisplay === '0') {
            setsideDisplay('22rem');
            setsideContent('1');
        }
        else {
            setsideDisplay('0');
            setsideContent('0');

        }
    }


    const uploadFileNameF = (event) => {
        const fileList = event.target.files;
        const fileName = fileList[0].name.slice(0, 9) + "...";
        setuploadTxtF(fileName);
    }

    const uploadFileNameB = (event) => {
        const fileList = event.target.files;
        const fileName = fileList[0].name.slice(0, 9) + "...";
        setuploadTxtB(fileName);
    }

    useEffect(() => {
        loadPersonalData();
    }, []);


    const loadPersonalData = async () => {
        const token = Cookies.get("_auth");

        const config = {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        };

    }

    const [AmountToPay, setAmountToPay] = useState("");
    const [tower, setTower] = useState("SAK tower");
    const [round, setRound] = useState("round 1");
    const [OrderData, setOrderData] = useState({})
    const navigate = useNavigate();
    //   if (tower === 'Kharian' && round !== 'round 2') {
    //    setRound('round 2');
    // }

    const [order, setOrder] = useState({
        order_id: '',
        area_sq_feet: '',
        amount_paid: '',
        payment_slip: '',
        transaction_hash: '',
        bank_branch: '',
        project_id: null, // Initially set project ID to null
        bank_name_id: '',
        round_id: '',
        purchase_date: '',
        user_id: '',
    });

    const handleProjectChange = (event) => {
        setOrder({
            ...order,
            project_id: event.target.value // Update project ID when project is selected
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const response = await axios.post(`/api/orders`, order);
            console.log(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    const handleChange = (event) => {
        setOrder({
            ...order,
            [event.target.name]: event.target.value
        });
    };

    const handleTowerChange = (event) => {
        setTower(event.target.value);
        // setRound(''); // Reset round when tower is changed
    };

    const handleRoundChange = (event) => {
        setRound(event.target.value);
    };

    const createOrderAPI = async (e) => {
        e.preventDefault();

        try {
            const token = Cookies.get("_auth");
            const response = await axios.post(`${API_URL}/api/order`, {
                project_name: tower,
                area_sq_feet: Area,
                amount_to_pay: AmountToPay
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setOrderData(response.data.order_data);
            alert(response.data.message);
            // console.log(response.data.order_data, OrderData);
            // return;
            // console.log(response.data);
            navigate("/OrdersSummary", { state: { tower: response.data.project_name, order_data: response.data.order_data } });
        } catch (error) {
            console.log(error);
        }

    }

    const [field1, setField1] = useState("");
    const handleNumberChange = (event) => {
        const enteredNumber = event.target.value;
        if (enteredNumber <= 49) {
            setArea("50"); // Set the number state to 50 if the entered number is less than or equal to 49
            setField1("50");
            setAmountToPay(" ");
        }

        else {
            setArea(enteredNumber);
            // setField1(enteredNumber); // Set field1 state to the entered value
            setAmountToPay(enteredNumber * 8500);
        }
    }

    const roundData = {
        "round 1": {
            "Round Price": "8000pkr",
            "Total Area": "7000sq.ft",
            "Area Left": "5000sq.ft",
            "Area Locked": "4000sq.ft",
            "Expected Duration": "2 months",
        },
        "round 2": {
            "Round Price": "9000pkr",
            "Total Area": "8000sq.ft",
            "Area Left": "7000sq.ft",
            "Area Locked": "300sq.ft",
            "Expected Duration": "3 months",
        },
        "round 3": {
            "Round Price": "10000pkr",
            "Total Area": "10000sq.ft",
            "Area Left": "7000sq.ft",
            "Area Locked": "300sq.ft",
            "Expected Duration": "4 months",
        },
        "round 4": {
            "Round Price": 9000,
            "Total Area": "8000sq.ft",
            "Area Left": "6000sq.ft",
            "Area Locked": "3000sq.ft",
            "Expected Duration": "5 months",
        },
    };

    const kharianRoundData = {
        "round 1": {
            "Round Price": 9000,
            "Total Area": "8000sq.ft",
            "Area Left": "6000sq.ft",
            "Area Locked": "3000sq.ft",
            "Expected Duration": "5 months",
        },
        "round 2": {
            "Round Price": "10000pkr",
            "Total Area": "10000sq.ft",
            "Area Left": "7000sq.ft",
            "Area Locked": "300sq.ft",
            "Expected Duration": "9 months",
        },
        "round 3": {
            "Round Price": "8000pkr",
            "Total Area": "7000sq.ft",
            "Area Left": "5000sq.ft",
            "Area Locked": "4000sq.ft",
            "Expected Duration": "2 months",
        },
    };

    const roundDetails =
        tower === "SAK tower" ? roundData[round] : kharianRoundData[round];


    return (
        <>


            {/* ================================
------------MAIN CONTENT--------------
================================== */}
            <div className="order-container">

                <div className="order-box1">
                    <div className="box1-first">
                        <img src={helloImg} alt="ELEMENT" />
                        <span>Selected Project</span>
                        {/* <label htmlFor="tower-select"></label> */}
                        <select id="tower-select" value={tower} onChange={handleTowerChange}>
                            <p>Development Round</p>
                            <option value="">Select Project</option>
                            <option value="SAK tower"> SAK tower</option>
                            <option value="Kharian">Kharian</option>
                        </select>
                    </div>


                    <div
                        className="box1-second">


                        <div className='box1-second-div2'>
                            <p className='box1-second-p'>Select Area</p>
                        </div>

                        <div className='box1-line1'></div>

                        <div className='box1-second-div1'>
                            <p className='box1-second-p'>Summary</p>
                        </div>

                        <div className='box1-line2'></div>

                        <div className='box1-second-div3'>
                            <p className='box1-second-p'>Confirmation</p>
                        </div>

                    </div>
                </div>


                <form enctype="multipart/form-data">
                    <div className="order-box2">
                        <h3> <b >Start Your Investment Journey </b></h3>
                        <hr style={{ borderColor: '#066b6e' }} />
                        <h4>Developement Round</h4>
                        <p>Check out the current rates and developement round at which you can invest in the project</p>

                        <hr style={{ borderColor: '#066b6e' }} />

                        {tower && (
                            <div className='selectoption'>
                                {/* <label htmlFor="round-select">Select a round:</label> */}
                                <select className='Developement-Round-selector' id="round-select-purchase-area" value={round} onChange={handleRoundChange}>
                                    <option value="">Select Development Round</option>
                                    {tower === 'SAK tower' ? (
                                        <>
                                            <option value="round 1"> Round 1 (Current) </option>
                                            <option value="round 2"> Round 2</option>
                                            <option value="round 3"> Round 3</option>
                                            <option value="round 4"> Round 4</option>
                                        </>
                                    ) : (
                                        <>
                                            <option value="round 1"> Round 1</option>
                                            <option value="round 2"> Round 2 (Current)</option>
                                            <option value="round 3"> Round 3</option>
                                        </>
                                    )}
                                </select>


                                {roundDetails && (
                                    <table id="table-round-selection" className="table table-hover table-bordered border-primary ">
                                        <thead>
                                            <tr>
                                                <th className='head'>Round Price</th>
                                                <th>Total Area</th>
                                                <th>Area Left</th>
                                                <th>Area Locked</th>
                                                <th>Expected Duration</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{roundDetails['Round Price']}</td>
                                                <td>{roundDetails['Total Area']}</td>
                                                <td>{roundDetails['Area Left']}</td>
                                                <td>{roundDetails['Area Locked']}</td>
                                                <td>{roundDetails['Expected Duration']}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                )}

                            </div>

                        )}
                    </div>
                </form>

                <div className="order-box3">
                    <h3> <b>Area To Invest </b></h3>
                    <hr style={{ borderColor: '#066b6e' }} />

                    <div className="order-detail">

                        <h5></h5>
                        <p>Please type the amount of area you want to invest in the project</p>
                        <h5>Enter Square Foot (Atleast 50)</h5>
                        <input type="number" id="ph" name="ph" placeholder="Minimum 50" onChange={handleNumberChange}
                            required className='order-inputs' />

                        <h5>Total Bill in Pakistani Rupees</h5>
                        <input type="number" id="cnic" name="cnic" placeholder="PKR" value={AmountToPay} readOnly className='order-inputs' />

                        <button className='order-btn' onClick={createOrderAPI}>PURCHASE NOW
                        </button>

                        <div className="order-checkbox">
                            <input type="checkbox" />
                            <p>I agree to all <Link>Terms of Service </Link> of this Project.</p>
                        </div>
                        <hr style={{ borderColor: '#066b6e' }} />





                    </div>



                </div>


            </div>

        </>
    )
}

export default PurchaseArea