// import React from 'react'
import { useParams } from "react-router-dom";
import { FiDownload } from "react-icons/fi"
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import slide_1 from "../assets/building-Slide-1.jpg";
import slide_2 from "../assets/building-Slide-2.jpg";
import slide_3 from "../assets/building-Slide-3.jpg";
import slide_4 from "../assets/building-Slide-4.jpg";
import ratingImg from "../assets/ratings.png";
import projectImg from "../assets/Rectangle 241.png";
import projectLogo from "../assets/VectorD.png";
import developmentImg from "../assets/development.png";
import coWorkingImg from "../assets/co-working-space.png";
import roundsImg from "../assets/rounds.png";
import locationImg from "../assets/location.png";
import dollerValueImg from "../assets/doller-value.png";
import projectTimeImg from "../assets/projectTimeImg.png";
import completionImg from "../assets/projectCompletionImg.png";
import avgTicketImg from "../assets/projectAverageTicket.png";
import lgTicketImg from "../assets/projectLargestTicket.png";
import projectAreaImg from "../assets/projectArea.png";
import areaLockedImg from "../assets/projectAreaLocked.png";
import areaReservedImg from "../assets/projectAreaReserved.png";
import areaLeftImg from "../assets/projectAreaLeft.png";
import projectCompleteImg from "../assets/projectComplete.png";
import inProgress from "../assets/projectInProgress.png";
import "./ProjectDetailss.css";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { API_URL } from "../config";
import RatingStars from "../components/RatingStars";
import {BiSolidNews} from "react-icons/bi"

const ProjectDetailss = () => {
  const [progress, setProgress] = useState(0);

  // const increaseProgress = () => {
  //   if (progress < 100) {
  //     setProgress(60);
  //   }
  // };


  const [project, setProject] = useState("");
  const [roundData, setRoundData] = useState([]);
  const [selectedCard, setSelectedCard] = useState("projectStats");
  const [selectedRound, setSelectedRound] = useState('');
  const { projectId } = useParams();

  const settings = {
    fade: true,
    autoplay: true,
    autoplaySpeed: 0.1,
    infinite: true,
    speed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: true

  };

  const handleInfoButtonClick = (button) => {
    setSelectedCard(button);
  };

  const handleRoundChange = async (event) => {
    const selectedRoundValue = event.target.value;
    setSelectedRound(selectedRoundValue);
    console.log(selectedRound);

    await axios
      .get(`${API_URL}/api/rounds/${selectedRoundValue}`)
      .then((response) => {
        setRoundData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

  };

  // useEffect(() => {
  //   // if (selectedOption === "") {
  //   //   // Do nothing if no option has been selected yet
  //   //   return;
  //   // }

  //   axios
  //     .get(`${API_URL}/api/rounds/${selectedRound}`)
  //     .then((response) => {
  //       setRoundData(response.data);
  //       console.log(roundData);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }, [selectedOption]);
  useEffect(() => {
    setProgress(60)
    axios
      .get(`${API_URL}/api/projects/${projectId}`)
      .then((response) => setProject(response.data))
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const renderCard = () => {
    switch (selectedCard) {
      case 'projectStats':
        return (
          <>
            <h4 className="projectDetailComponentHeading">PROJECT STATS</h4>
            <div className="projectComponentInfoMainBox">
              <div className="projectComponentInfoItems">
                <div className="componentItemsLogoBox">
                  <img src={projectTimeImg} alt="" className="projectComponentItemsLogo" />
                </div>
                <div className="componentItemsDetailsBox">
                  <h5 className="projectComponentItemsHeading">STARTED ON</h5>
                  <p className="projectComponentItemsDetail">({new Date(project.started_on).toLocaleDateString()})</p>
                </div>
              </div>
              <div className="projectComponentInfoItems">
                <div className="componentItemsLogoBox">
                  <img src={completionImg} alt="" className="projectComponentItemsLogo" />
                </div>
                <div className="componentItemsDetailsBox">
                  <h5 className="projectComponentItemsHeading">COMPLETION ON</h5>
                  <p className="projectComponentItemsDetail">({new Date(project.expected_completion).toLocaleDateString()})</p>
                </div>
              </div>
              <div className="projectComponentInfoItems">
                <div className="componentItemsLogoBox">
                  <img src={avgTicketImg} alt="" className="projectComponentItemsLogo" />
                </div>
                <div className="componentItemsDetailsBox">
                  <h5 className="projectComponentItemsHeading">AVG. TICKET</h5>
                  <p className="projectComponentItemsDetail">(DATA)</p>
                </div>
              </div>
              <div className="projectComponentInfoItems">
                <div className="componentItemsLogoBox">
                  <img src={lgTicketImg} alt="" className="projectComponentItemsLogo" />
                </div>
                <div className="componentItemsDetailsBox">
                  <h5 className="projectComponentItemsHeading">LARGEST TICKET</h5>
                  <p className="projectComponentItemsDetail">(DATA)</p>
                </div>
              </div>
              <div className="projectComponentInfoItems">
                <div className="componentItemsLogoBox">
                  <img src={projectAreaImg} alt="" className="projectComponentItemsLogo" />
                </div>
                <div className="componentItemsDetailsBox">
                  <h5 className="projectComponentItemsHeading">PROJECT AREA</h5>
                  <p className="projectComponentItemsDetail">(DATA)</p>
                </div>
              </div>
              <div className="projectComponentInfoItems">
                <div className="componentItemsLogoBox">
                  <img src={areaLockedImg} alt="" className="projectComponentItemsLogo" />
                </div>
                <div className="componentItemsDetailsBox">
                  <h5 className="projectComponentItemsHeading">AREA LOCKED</h5>
                  <p className="projectComponentItemsDetail">({project.area_locked})</p>
                </div>
              </div>
              <div className="projectComponentInfoItems">
                <div className="componentItemsLogoBox">
                  <img src={areaReservedImg} alt="" className="projectComponentItemsLogo" />
                </div>
                <div className="componentItemsDetailsBox">
                  <h5 className="projectComponentItemsHeading">AREA RESERVED</h5>
                  <p className="projectComponentItemsDetail">({project.area_reserved})</p>
                </div>
              </div>
              <div className="projectComponentInfoItems">
                <div className="componentItemsLogoBox">
                  <img src={areaLeftImg} alt="" className="projectComponentItemsLogo" />
                </div>
                <div className="componentItemsDetailsBox">
                  <h5 className="projectComponentItemsHeading">AREA LEFT</h5>
                  <p className="projectComponentItemsDetail">({project.area_left})</p>
                </div>
              </div>
            </div>
          </>
        )
        break;
      case 'roundStats':
        return (
          <>
            <h4 className="projectDetailComponentHeading">ROUND STATS</h4>
            <div className="componentRoundsSelectionBox">
              <label>Select Round</label>
              <select value={selectedRound} onChange={handleRoundChange}>
                <option >Select Round</option>
                {project.rounds.map((round) => (
                  <option key={round.id} value={round.id}>Round {round.round_number}</option>
                ))}
              </select>
            </div>
            <div className="projectComponentInfoMainBox">
              <div className="projectComponentInfoItems">
                <div className="componentItemsLogoBox">
                  <img src={projectTimeImg} alt="" className="projectComponentItemsLogo" />
                </div>
                <div className="componentItemsDetailsBox">
                  <h5 className="projectComponentItemsHeading">STARTED ON</h5>
                  <p className="projectComponentItemsDetail">({new Date(roundData.start_date).toLocaleDateString()})</p>
                </div>
              </div>
              <div className="projectComponentInfoItems">
                <div className="componentItemsLogoBox">
                  <img src={completionImg} alt="" className="projectComponentItemsLogo" />
                </div>
                <div className="componentItemsDetailsBox">
                  <h5 className="projectComponentItemsHeading">COMPLETION ON</h5>
                  <p className="projectComponentItemsDetail">({new Date(roundData.expected_completion).toLocaleDateString()})</p>
                </div>
              </div>
              <div className="projectComponentInfoItems">
                <div className="componentItemsLogoBox">
                  <img src={avgTicketImg} alt="" className="projectComponentItemsLogo" />
                </div>
                <div className="componentItemsDetailsBox">
                  <h5 className="projectComponentItemsHeading">AVG. TICKET</h5>
                  <p className="projectComponentItemsDetail">({roundData.avg_ticket_size})</p>
                </div>
              </div>
              <div className="projectComponentInfoItems">
                <div className="componentItemsLogoBox">
                  <img src={lgTicketImg} alt="" className="projectComponentItemsLogo" />
                </div>
                <div className="componentItemsDetailsBox">
                  <h5 className="projectComponentItemsHeading">LARGEST TICKET</h5>
                  <p className="projectComponentItemsDetail">({roundData.largest_ticket_size})</p>
                </div>
              </div>
              <div className="projectComponentInfoItems">
                <div className="componentItemsLogoBox">
                  <img src={projectAreaImg} alt="" className="projectComponentItemsLogo" />
                </div>
                <div className="componentItemsDetailsBox">
                  <h5 className="projectComponentItemsHeading">PROJECT AREA</h5>
                  <p className="projectComponentItemsDetail">({ })</p>
                </div>
              </div>
              <div className="projectComponentInfoItems">
                <div className="componentItemsLogoBox">
                  <img src={areaLockedImg} alt="" className="projectComponentItemsLogo" />
                </div>
                <div className="componentItemsDetailsBox">
                  <h5 className="projectComponentItemsHeading">AREA LOCKED</h5>
                  <p className="projectComponentItemsDetail">({roundData.area_locked})</p>
                </div>
              </div>
              <div className="projectComponentInfoItems">
                <div className="componentItemsLogoBox">
                  <img src={areaReservedImg} alt="" className="projectComponentItemsLogo" />
                </div>
                <div className="componentItemsDetailsBox">
                  <h5 className="projectComponentItemsHeading">AREA RESERVED</h5>
                  <p className="projectComponentItemsDetail">({roundData.area_pleged})</p>
                </div>
              </div>
              <div className="projectComponentInfoItems">
                <div className="componentItemsLogoBox">
                  <img src={areaLeftImg} alt="" className="projectComponentItemsLogo" />
                </div>
                <div className="componentItemsDetailsBox">
                  <h5 className="projectComponentItemsHeading">ROUND RATE</h5>
                  <p className="projectComponentItemsDetail">({roundData.rate})</p>
                </div>
              </div>
            </div>
          </>
        )
        break;
      case 'letestNews':
        return (
          <>
            <h4 className="projectDetailComponentHeading">LATEST NEWS</h4>
            <div className="componentLatestNewsMainBox">
              <div className="componentLatestNewsData">
                <div className="latestNewsLogoBox">
                  {/* <img src={projectLogo} alt="Latest News" className="latestNewsLogoImage" /> */}
                  <BiSolidNews className="latestNewsLogoImage"> </BiSolidNews>
                  
                </div>
                <div className="latestNewsDescription">
                  Check out latest update regarding the tower developement
                </div>
              </div>
              <div className="componentLatestNewsData">
                <div className="latestNewsLogoBox">
                  {/* <img src={projectLogo} alt="Latest News" className="latestNewsLogoImage" /> */}
                  <BiSolidNews className="latestNewsLogoImage"> </BiSolidNews>
                
                </div>
                <div className="latestNewsDescription">
                  Piles Work has been done. Check our latest video
                </div>
              </div>
              <div className="componentLatestNewsData">
                <div className="latestNewsLogoBox">
                  {/* <img src={projectLogo} alt="Latest News" className="latestNewsLogoImage" /> */}
                  <BiSolidNews className="latestNewsLogoImage"> </BiSolidNews>
                
                </div>
                <div className="latestNewsDescription">
                  Ground floor construction ongoing work - developement update.
                </div>
              </div>

            </div>
          </>
        )
        break;
      case 'mileStones':
        return (
          <>
            <h4 className="projectDetailComponentHeading">MILESTONES</h4>
            <div className="componentMilestonesDataMainBox">
              <div className="projectComponentMilestoneData">
                <img src={projectCompleteImg} alt="" className="projectComponentItemsLogo" />
                <h4 className="componentMilestoneHeading">Name</h4>
              </div>
              <div className="projectComponentMilestoneData">
                <img src={inProgress} alt="" className="projectComponentItemsLogo" />
                <h4 className="componentMilestoneHeading">Description</h4>
              </div>
              <div className="projectComponentMilestoneData">
                <img src={projectTimeImg} alt="" className="projectComponentItemsLogo" />
                <h4 className="componentMilestoneHeading">Progress</h4>
              </div>
            </div>
            {project.milestones.map((milestone, index) => (
              <div className="componentMilestonesDataMainBox">
                <div className="projectComponentMilestoneData">
                  <p className="componentMilestoneData">{index +1 } ) {milestone.name}</p>
                </div>
                <div className="projectComponentMilestoneData">
                  <p className="componentMilestoneData">{milestone.description}</p>
                </div>
                <div className="projectComponentMilestoneData">
                  <p className="componentMilestoneData">{milestone.progress_done}%</p>
                </div>
              </div>
            ))}




          </>
        )
        break;
      default:
        return null;
    }
  };


  return (
    <>
      <div className="ProjectDetailMainBox">
        <div className="projectDetailsHeadBox">
          <div className="projectDetailLogoBox">
            <img src={`${API_URL}/public/${project.project_logo}`} alt="Project Logo" className="projectDetailsProjectLogo" />
          </div>

          <div className="ProjectDetailHeadingDetails">

            <div className="projectDetailsInvestBtnBox">
              <Link to = {`/ownershipjourney/${project.id}`} key={project.id}>
                <button className="projectDetailInvestBtn">INVEST NOW</button>
              </Link>
            </div>

            <div className="projectDetailsNameBox">
              <h3 className="projectDetailsNameHeading">{project.name}</h3>
              <div className="projectDetailsratingBox">
                <RatingStars rating={project.obtained_rating} className="projectDetailsRatingImg" />
                <h5 className="projectDetailsRating">({project.obtained_rating})</h5>
              </div>
            </div>

            <div className="projectDetailsDevelopmentBox">
              <div className="projectDetailsDevelopmentItems">
                <img src={developmentImg} alt="" className="projectDetailsDevelopmentLogos" />
                <p className="projectDetailsDevelopmentData">Developmental</p>

              </div>

              <div className="projectDetailsDevelopmentItems">
                <img src={coWorkingImg} alt="" className="projectDetailsDevelopmentLogos" />
                <p className="projectDetailsDevelopmentData">Building</p>
              </div>

              <div className="projectDetailsDevelopmentItems">
                <img src={locationImg} alt="" className="projectDetailsDevelopmentLogos" />
                <p className="projectDetailsDevelopmentData">{project.area}</p>
              </div>

              <div className="projectDetailsDevelopmentItems">
                <img src={roundsImg} alt="" className="projectDetailsDevelopmentLogos" />
                <p className="projectDetailsDevelopmentData">Round {project.project_round}</p>
              </div>

              <div className="projectDetailsDevelopmentItems">
                <img src={dollerValueImg} alt="" className="projectDetailsDevelopmentLogos" />
                <p className="projectDetailsDevelopmentData">{project.price} PKR/sq.ft</p>
              </div>

            </div>

            <div className="projectDevelopmentDetailsBox">
              <div className="projectDevelopmentDescriptionBox">
                <p className="projectDevelopmentDescription">{project.description}</p>
              </div>
              <div className="ProjectCompletionStatus">
                <h4 className="projectCompletionStatusHeading">Completion Status</h4>

                <div className="projectProgressBar">
                  <label>{progress}%</label>
                  <progress value={progress} max="100" className="progressBarStatus" />
                </div>
              </div>
            </div>


          </div>

        </div>

        <div className="projectDetailMidBtnBox">

          <button className="projectDetailsInfoBtn"
            onClick={() => handleInfoButtonClick('projectStats')}
          >PROJECT STATS</button>

          <button className="projectDetailsInfoBtn"
            onClick={() => handleInfoButtonClick('roundStats')}
          >ROUND STATS</button>

          <button className="projectDetailsInfoBtn"
            onClick={() => handleInfoButtonClick('letestNews')}
          >LATEST NEWS</button>

          <button className="projectDetailsInfoBtn"
            onClick={() => handleInfoButtonClick('mileStones')}
          >MILESTONES</button>
        </div>
        <div className="ProjectDetailComponentBox">
          {renderCard()}
        </div>

        <div className="projectDetailComponentSliderBox">
          <Slider {...settings} className="projectDetailSlider">
            <div>
              <img src={slide_1} alt="Slide 1" className="projectDetailSlide" />
            </div>
            <div>
              <img src={slide_2} alt="Slide 2" className="projectDetailSlide" />
            </div>
            <div>
              <img src={slide_3} alt="Slide 3" className="projectDetailSlide" />
            </div>
            <div>
              <img src={slide_4} alt="Slide 3" className="projectDetailSlide" />
            </div>
          </Slider>
        </div>


        <div className="componentHandBookBox">
          <h5 className="componentHandbookHeading">Where Millennial's Feel At Home</h5>
          <p className="componentHandBookDescription">SAK Tower, an avant-garde marvel poised on an expansive area, harmoniously merges the essence of a dwelling with a touch of opulence. Its meticulously designed entrance and a cutting-edge 21st-century facade seamlessly blend to create a sleek and contemporary exterior. SAK Tower represents the epitome of architectural finesse.

            At SAK Tower, verdant landscapes flourish, breathing life into the surroundings while providing residents with cooler temperatures and pristine air quality. The lush green spaces enhance the overall ambiance and contribute to a serene and rejuvenating living environment..</p>
          <a href={projectAreaImg} download="handbook" >
            <button className="componentHandBookDownloadBtn">HANDBOOK <FiDownload /> </button>
          </a>
        </div>

        <div className="componentProjectBenifitsMainBox">
          <h5 className="componentHandbookHeading">Why invest through InfraTech?</h5>
          <p>InfraTech is an innovative infrastructure technology company with a mission to make infrastructural investments and assets accessible to the masses. Founded in
            2022, we envision a world that is being driven by the masses.</p>
          <p>At InfraTech DAO, we are creating a revolutionary blockchain-based real estate platform that will revolutionize the way people buy, sell, and manage real estate. With our innovative technology, users can securely and transparently purchase, store, and transfer real estate assets, all while keeping their transactions secure and private.</p>
          <h5 className="componentHandbookHeading">Benefits of investing through us:</h5>
          <ul>

            {/* line added */}
            <li>With InfraTech,  the entire process is digital. Making your investment journey convenient, transparent and efficient.</li>
            <li>Smooth Liquidity,Although we recommend a holding period of 5 years, we also give investors the opportunity to sell their shares to other investors in the platform twice a year.</li>
            <li>Low and transparent fees on your investments.</li>
            <li>Construction Visibility.</li>
            <li>A professional team is available to assist you anytime</li>
          </ul>
        </div>
        {/* LOCATION COMPONET */}
        <div className="componentProjectLocationBox">
          <h5 className="componentHandbookHeading">Located in Bahria Oriental Garden, Islamabad</h5>
          <p>The project is ideally located in the city center surrounded by all essential amenities such as hospitals, schools,
            recreational places, and commercial centers.</p>
          <div className="projectComponentMapBox">
            <iframe width="100%" height="400" id="gmap_canvas" src="https://maps.google.com/maps?q=dha phase 2&t=&z=10&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>



          </div>
        </div>


      </div>

    </>
  );
};

export default ProjectDetailss;
