import React, { useEffect, useState } from "react";
import "./PurchaseUnit.css";
import { circularData, coveredData } from "./SakResidentialCardData";

import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../config";


const PurchaseUnit = () => {
  const [activeButton, setActiveButton] = useState(null);
  const [content, setContent] = useState("");
  const [selectedProject, setSelectedProject] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState(" ");
  const [showDetailButtons, setShowDetailButtons] = useState(false);
  const [projectName , setProjectName] = useState('');
  const [ProjectNames, setProjectNames] = useState(null);
  const [CategoryNames, setCategoryNames] = useState(null);
  const [FloorNames, setFloorNames] = useState(null);
  const [UnitTypeNames, setUnitTypeNames] = useState(null);
  const [CurrentFloor, setCurrentFloor] = useState("");
  const [PricePerSqft, setPricePerSqft] = useState(20000);

  const navigate = useNavigate();

  async function fetchProjects() {
    const response = await axios.get(`${API_URL}/api/Projects`);
    setProjectNames(response.data);
  }

  const handlePurchaseLinkClick = (id) => {
    // e.preventDefault(); // Prevent the default link behavior
    // console.log(id);
    axios.get(`${API_URL}/api/unittype/${id}`)
      .then(response => {
        const unit_type = response.data;
        // console.log(`Cover area is: ${unit_type.unit_remaining_covered_area}`);
        const covered_area = unit_type.unit_remaining_covered_area;
        // Handle the response data
        // console.log(response.data);
        navigate('/purchaseUnitPlanN', { state: { project_id: selectedProject,project_name: projectName, unit_type_id: id, unit_type_name: unit_type.name, covered_area: covered_area, floor_name: CurrentFloor, price_per_sqft: PricePerSqft, total_price: covered_area * PricePerSqft, unit_type: response.data } });
      })
      .catch(error => {
        // Handle errors
        console.error('API error:', error);
      });
  };

  const handleCategoryClick = (categoryId) => {
    // console.log(categoryId);

    // Remove "active" class from all buttons
    const buttons = document.querySelectorAll('.buttonCategory');
    buttons.forEach(button => {
      button.classList.remove('active');
    });

    // Add "active" class to the clicked button
    const clickedButton = document.getElementById(categoryId);
    clickedButton.classList.add('active');

    // Other logic here
    axios.get(`${API_URL}/api/category/${categoryId}`)
      .then(response => {
        const floors = response.data.floors;
        // Handle the response data
        // console.log(floors);
        setFloorNames(floors);
        setUnitTypeNames(null);
      })
      .catch(error => {
        // Handle errors
        console.error('API error:', error);
      });
  };

  const handleFloorButtonClick = (id) => {
    axios.get(`${API_URL}/api/floor/${id}`)
      .then(response => {
        const unit_types = response.data.unit_types;
        setCurrentFloor(response.data.name);
        // Handle the response data
        // console.log(unit_types);
        setUnitTypeNames(unit_types);
        // setFloorNames(unit_types);
      })
      .catch(error => {
        // Handle errors
        console.error('API error:', error);
      });
  };



  useEffect(() => {
    fetchProjects();
  }, []);

  // useEffect(() => {
  //   console.log("ProjectNames:", ProjectNames);
  // }, [ProjectNames]);

  // useEffect(() => {
  //   console.log("Unit Type Names:", UnitTypeNames);
  // }, [UnitTypeNames]);

  // useEffect(() => {
  //   console.log('CategoryNames:', CategoryNames);
  // }, [CategoryNames]);

  // function handleClick(event) {
  //   const button = event.target;
  //   if (button.id !== activeButton) {
  //     setActiveButton(button.id);
  //     setSelectedCategory(button.innerText);
  //     setShowDetailButtons(true);
  //     setContent("");
  //   } else {
  //     setActiveButton(null);
  //     setShowDetailButtons(false);
  //     setContent("");
  //   }
  // }

  function handleProjectChange(event) {
    const selectedProjectId = event.target.value;
    setSelectedProject(selectedProjectId);

    if (selectedProjectId > 0) {
      // Make API request to localhost:5000/api/projects/{id}
      axios.get(`${API_URL}/api/projects/${selectedProjectId}`)
        .then(response => {
          // Handle the response data
          const categories = response.data.categories;
          setProjectName(response.data.name);

          // console.log('Before setting CategoryNames:', CategoryNames);

          setCategoryNames(categories);
          setFloorNames(null);
          setUnitTypeNames(null);

          // console.log('After setting CategoryNames:', CategoryNames);
        })
        .catch(error => {
          // Handle errors
          console.error('API error:', error);
        });
    }
  }



  function handleDetailButtonClick(category) {
    if (selectedProject === 0) {
      setContent("");
      return;
    }

    
  }

  const formatNumber = (value) => {
    if (value >= 10000000) {
      const croreValue = (value / 10000000).toFixed(2);
      return croreValue.endsWith('.00')
        ? croreValue.slice(0, -3) + ' Crore'
        : croreValue + ' Crore';
    } else if (value >= 100000) {
      const lakhValue = (value / 100000).toFixed(2);
      return lakhValue.endsWith('.00')
        ? lakhValue.slice(0, -3) + ' Lakh'
        : lakhValue + ' Lakh';
    } else {
      return value.toString();
    }
  };// formatting large numbers

  const formatNumberWithCommas = (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  return (
    <div className="purchaseUnitContainer">

      <div className="purchaseUnitTopDiv">
        <p className="selectProjectText">Step 1: Please Select A Project</p>
        <select
          className="styledSelectButton"
          value={selectedProject}
          onChange={handleProjectChange}
        >
          <option value="0">Select Project</option>
          {ProjectNames && ProjectNames.map(project => (
            <option key={project.id} value={project.id}>{project.name}</option>
          ))}
        </select>
        {selectedProject !== 0 && (
          <>
            <p className="selectCategoryPara">Step 2: Choose A Category Where You Would Like To Invest</p>
          </>
        )}
        <div className="buttonRow">

          {CategoryNames && CategoryNames.map(category => (
            <>

              <button
                className={`buttonCategory ${activeButton === category.id ? "active" : ""}`}
                id={category.id}
                onClick={() => handleCategoryClick(category.id)}
                key={category.id}
              >
                {category.name
                  .split(" ")
                  .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(" ")}
              </button>
            </>
          ))}
        </div>



        {FloorNames !== null && (
          <>
            <p className="selectAreaText">Step 3: Please Select Any Available Floor Where Units Will Be Listed</p>
            <div className="bottomDiv">
              {FloorNames && FloorNames.map(floor => (
                <button
                  key={floor.id}
                  className={`buttonCategory ${selectedCategory === floor.name ? 'active' : ''}`}
                  onClick={() => handleFloorButtonClick(floor.id)}
                >
                  {floor.name
                    .split(" ")
                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" ")}
                </button>
              ))}
            </div>
          </>
        )}
      </div>
      <div>
        {/* <h4>Circular</h4> */}
        <div className="residentailContetns">
          <div className="parent-div-categories">
            {UnitTypeNames && UnitTypeNames.map((unit_type) => (
              <div className="childDiv">
                <div className="bgImgDiv">
                  <img className="sakbgImage" src="/static/media/sakbgImg.161fa8e086da604e1d6c.jpg" />
                  <div className="logoAndNameParentDiv">
                    <div className="logoDiv">
                      <img className="sakLogImg" src="/static/media/SakLogo.fb495fa637850f5a0fb6.png" />
                    </div>
                    <div className="nameAndRatingDiv">
                      <p className="nameText">Unit Type: {unit_type.name}</p>
                      <div className="imgNtxt">
                        <img className="sakRatingImg" src="/static/media/sakrating.a7dc8a3fe9504c0f4b3d.jpg" />
                        <p className="ratingTxt">5</p>
                      </div>
                      <p className="premiumText">{unit_type.unit_label}</p>
                    </div>
                  </div>
                  <p
                    style={{
                      fontSize: "14px",
                      margin: "2px 0px 10px 4px",
                      color: "#4E4E4E",
                      fontWeight: "700",
                    }}
                  >
                    {unit_type.unit_unique_specifier}
                  </p>
                  <div className="my-parent">
                    <div className="my-child-1">
                      <div className="bookedDiv">
                        <div className="contentsBooking">
                          <div className="bookingImgDiv">
                            <img className="bookingImg" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADQAAABGCAYAAACDkrchAAAACXBIWXMAACxLAAAsSwGlPZapAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAASoSURBVHgB7ZvvVdswEMAvfXxvOgHaoGECzASlE2AmaJgAM0GzAWGCwgSYCQgT2J2AdILrHZJi1djxSZZFPvT3nhLHOcs+/bk7nRKARCDigsotlQobXs05BZGYQSTooeb0dk5FUflM5Q+V0nx9SqUYqGJF5YHK3NRxbOrYzGaze0gJt3Cr5WNTmQYb5BPEYQG6VadCUckkgrEUKqnUMB01lQ2kxAy7R4zPI0Y0GiGKFRiPAg4BepAcx5PDITFSqSUEEs0PdUEPxn5JZG4dtsn9zn8SEjv0YQf7DbQj5OI73Fy2oH3PDQ3BGlLBilC5Rh1oTkGFwrAnhjIZThvDWRbSZzrCfufF1mbVowi32DWVYPPqQQ0+YdWeVqk6ZHl4LTFNrzA8jHPw4EgiRJVyb2SgJ/2Y8cz+ZSuUfaGyplEildfsaZ3KkXnEOGQwMaIeioi4tc2oUALRJ+rFtf2QWqGaX0xPXbe+e7BGiL7/CXKDwzGjomsL/pBSoa0zHzJ4vwJ9co59recFmJxFrBWrBHfF+dVDVsJuKKdU6M457nKUp87xJch9z9bIv5FSoZJfUHt9NSDL30vdg017vZFKobUTYP7okXHnEBsFH3+3MzCpFLrhF9TJjkwgH+y8Uyjkhv9sjVSP3LF7DfjxsDvCaSOFlVOHGpB9bslKlyPP6KS5puyhO+oZ1588Dsi7c+gW5MNuYeTfmEqhK1Imtx+oBfmGauAaNyzKwA9lD2IrVFI5cddRRplccO28VY/vfXc37EM6h3isr7AVSZt58IxyXlvXSucQyyl7rTSW+w2N5944n0vqjXdhCt2AfU0BfubXzcVdgJ9jPQe9v+S3Yt0HNsmSCsMonLp8Ey67Zx0VbaPuam4dTl2NXc2OYWdQvBUiJX5BnLxbGzc4vQLHFA/Ayuwc8YwesM8/1DQ/Lt0TqCf+kD8ZwxndszT3UiBbsW688w4WnGZD65+5gCM3t8SpYHMjL0MRCLf2GnR8VgvkFejh+mR7VwTq3xMcMgU/p08PVTDtTvdYOGfxRaQQ6lXmM/hTQhN0WtPeRQ3NEp2XETmEcSKSQp3+DYEd5NzUsS+cyZx7VRjOUhqcnkIYNqkPZpHXtXBbO6ZamlzsYyibpMHx5jrrqatCY6ZxeAEoQTYtcPxuQ4XN0Fs45/OI92BepQqFUO2pr0C9Tur7fo2BpM5tWzjUnySQlSq0DXgAXk4U7ZOcVDex11v8hXrYqZaYbHKHgnF37HKn3gzjUknNtm/y/KOofZbgvvCQ+t5xftM6PuuQ4SX8OfjzIpIKHBrBkTmGW7lznyEXtohKSykacmyVSHtWKgM5qqeXruyvrVBHEF3+KMSk85bm1scPcRyWgR+q49xcIBPCml/EmVMTQJZwmNS2131Twb7bHKlosj7gCeosUQbhlNDkCmzWcwz31Ds79xCikAK9ev2opKJLDTr1VdsT3rsP5uJLOAyuov04EOP+PjuEAmLzgUoVMBUYnkAJZQlTg9P/3QZN/RmkAvX+0Aqngev9GKuKureCcwIO0f/ONgqjWI5+KbBXI78M7ZFJ//vggnr8K2g2yuz/9HhZUoNeotTBez2Gv0Aih3QY1s4wAAAAAElFTkSuQmCC" />
                          </div>
                          <div className="bookedTextDiv">
                            <p className="bookedText"> Available</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="my-child-2">
                      <div className="remainingDiv">
                        <div className="contentsBooking">
                          <div className="bookingImgDiv">
                            <img className="bookingImg" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAAA4CAYAAABez76GAAAACXBIWXMAACxLAAAsSwGlPZapAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAASZSURBVHgB7VuLddMwFH3lMAAjhA3KBmYD2CBM0DJBygSUCdQNygYOE9ANZCYoG1z0Grl15Ke/4qQt9xydk6a2rvWkq/d87RCdCAC8M+3WthX9xxNMQC5Mu8c+Nhw0es0wAehM+w0/tGlrem2wclJIh3o1svPIKRUbeqlAXE6p0FhQdm9oOQy2nUo/xwfPtLt32O808sGyvHT64r3sEz03WDn1PknYgV0hHddwUr7l0D6Ok4Qd+HfPIHkQK+f4FXaFoQ+8Z3XCOb3neIVTzXZIz07fEZedT06bRI7TyXbYl1MqNPyyUwjLqZhjUSAsp1SwhFYBjpCcUqGwtOywC06LmobBkjk/MEdwIg4ZqJxMJCF4547dCrpGHfgaD3vTiyc5acz3Dh7EDfKgMc9O4/2ZhpztNPLQC/2M+6VCqxWFXYZxM4dCWQHI/VwJHBeNOdbOedJ+yceVZzvEN0iRAH7Z9ZBnNMvuQHjFSgWlNMEux3lqXKb1Rio0nHLfGQT/vxM4cjLgbJPFvux6d5D8N/IyoEJMdjxQlN0niQS2P3dGa+wOqcjsKoM/hYZToE5no0c9tEhAxQWlj2Pt4YjJKYdjxX1O7Y4/1AZ3nu+HRhwD+e2OrWl/qQ3HHChLp7CzNqs3sJDd4eHVyIeGI1nuTMpEOQQ9/PXGSHopcOQUmQqZdgfy6jPfBF/QhOBTJgGf09F8RkN2x3kmR67dMavMEVdFj0D5Qc7BKpFAjDbSNkiFI9gdAgd/7igywSR0fh8gkGaoJDtpCJkIu8D7ODTyoOGXXfIEU4TA6/eijd2hcWS7A5GCkkoJ8LzsDuZYlUxwSoAY2hP9S5RX3gyFgBWBfHNfwrXEgcSVmR0gyJt4ajod4ctOvP9o5Jv7EnrMs+a7JQLEM6owHwRrWUf6mWUne+4Gbe0OKQOynNRSARovRMp2PtmVZCf+31oYrE92Mbtj0QBNB5Frd+RKxmd3jH30AoeUnY4SoEdyYRBSdtrgeHZHUYBSdJ0SIIbXxkRZsSdeC8rtjpwAPexlb87Ozm7M8R9N+0H1YP1fQbYxB9N+UT0GcuwI2ILSfFT2GmrBsXhvYnNNApFUnKWuoCnWEjMa2h2TPrvEPmIriL/rpn3vvR9kIjaY9sF8/EIN38HBRHa8Yk17bz5+y+hiNqPY+UDpZnsYbLJ9Nf1/NG2bdAb2H9pVraCxD+RbKj3Cdkdnv6tZQQo1DxbtBd02CBAmF7RyOFzZheyOKbqKACm47mELNAjQGADfM7XYy1JNApSKkncUB6rHNNs9ys7o/8q0S9MejHc8ZSduK6pHqwcTYcCf7VJX0GxmMZfdBvGCsrPHxlaQxiHkFAPCKXs9OS4FJYmgo3CAxHcBclD1GnDjIrM1fpr2gWVLFah+T9rWTpxxuLa5o+NjMI3rmc98bVSJZi+SH6rIzABv7N+4CE0u9hLwlhqDZWd0v6VlMdCu0m7x2HkPzQPEaLG0T4Vvqd9qtJbdKKctvRQg4VH25NhQmu/xkn87hnJLReMIxd6SP4d6QEG2e5AT7WqaLb0mIG6p9C9aTqnA3FLhW5iT+B3YP1ATqtO0oIOPAAAAAElFTkSuQmCC" />
                          </div>
                          <div className="bookedTextDiv">
                            <p className="bookedText">Total Area {unit_type.unit_total_circulation_area+unit_type.unit_total_covered_area} Sqft</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="my-parent">
                    <div className="my-child-1">
                      <div className="bookedDiv">
                        <div className="contentsBooking">
                          <div className="bookingImgDiv">
                            <img className="bookingImg" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAAAxCAYAAABqF6+6AAAACXBIWXMAACxLAAAsSwGlPZapAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAIlSURBVHgB7ZrNUcMwEIWfAweO6QClAtIBoYKkA8KNI50AFRAqgFSQUEHSgc2RU+hA7FqCCbJsi7GdYEnfzI4zsiJ7X7T6WSWBA1LKCV2mZHwdGreHlrJjstW2TJLkta5yUnWTHB/T5R7K8T6SkV2REFlZhUHZDXL+mi4b9Nd5RpCl2hcr1h6gf/kN/IJ7wtosLBMghVLPJzISYGQWFkKAnJ/DP+cZQb7NzELbGDCFv8zNgkIIkEo7/K9prU0KYWATQMJjSIBfPg8QOFEABE4UAIFzimZkDnWa7BYzhzrt7kZlPSveHpM5P5Tr8gqTl9gO7ae6biftuzRWxQINoO+LmpdM/+K4pX0WYlPlgEsjnbzcXvuTimcINESL3IkAT2gJqcLIZIWWKGnfKoDLIPip7RHtsURxx9lm+89wTOTY9gK8ZeSuznk1QXapGxPo1ybpOzf4BjWb5O/ukifMcwLSbcTuC6lUeY4CieE4q/SCfucBq1iT3ewnSX8E0M7zQDSG32TYyxTvL4V5lPfdeUZA+ZqTC6DjY4Zw4LXIHX/IQ0D6mQWug6f20YlU094twuOM7INDwOcscB0TFiCEga+Mi4RXCQiYmBJD4EQBEDhRAAROFACBEwVA4EQBEDjxdNhSxpkSPljY4jB09V/jc8tzimk/Sw69/uCgp0jLkZktBDL4y7tZwAKYXf1QXf8YFH2V6jiZz9R3ZA/wGKn+P7DQvZ/DQXwBU4ckD+Ing7oAAAAASUVORK5CYII=" />
                          </div>
                          <div className="bookedTextDiv">
                            <p className="bookedText">Luxury</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="my-child-2">
                      <div className="remainingDiv">
                        <div className="contentsBooking">
                          <div className="bookingImgDiv">
                            <img className="bookingImg" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAAA7CAYAAADLjIzcAAAACXBIWXMAACxLAAAsSwGlPZapAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAL2SURBVHgB7ZuBddowEIZ/52UAsoGzAZ2gzgbpBJgJSiYITJB0gpAJmg3wBqUTWJ0g7gTXu0hJZcUBjGM4EN97F7APE99vWTqdRQIPIsr5ZcQ2ZBvguKjYlmyPSZLMX3cm8ocDl2Af2K4RB09sYxaiOnM7bhFP8ILEeidvEr76Gb8uECdX0gJGiJdraQGEeCnOEDdp7AIgegHOV/gkcRjDJg/bILmF+uF1VSd4w4nCPTrgEqwSerNK0+stIJkWlLNKgBFfwRQd4OO/Q/mcYl0eUDnblhS6MedrPjDA8c0Ka6wTQBtLZ3/YDLpTHYIABdsj21MfnapmAQq2GQddoEe0CiCBT7EDNAow9ktWIW5oHjrriro+oDF4V7SRuoWk1Z85KhlNAszC4N3Vllplhp7QUhBZcvBf/B0ueCnVpegPo2U6/M3f+CD4gu2K7YLFkmq2CCazVYMu0P5ZNJxT6fmfyfYBq2KY0naUGgTIg2DywJ9teCHvqT2lhj7gws/w5KTwv+nP2Tfe5Etou9rD3vuAZRB8hvp9P/M/zP4h24LsLfLggn7Bfc8PtGTfw2CY26e+j4Mygf+n95kcdlI09fyty3faiqJ+860F09A6hPChzsEL4LeINPA1BWeC7dZZ4r4FSINtP8i04R73+wTZvgmObz8/oP0zCM7n2fNNGs5XhMnC45xvQe1QkQdMgiAmnq+khkA/uJB5+3+tQ4DFmiv5i9aIQHZ4LKk9KgQQMrxv5qXnL6khI+R9A7Zbqt82bVCRCQqbzgYNbEdZuf1d1zIZLS1AuAvPjmxLmFN/lJryAOn8ah2iZIJsOb+9hK0MG3wyGleIrHwoy6f72uxTdKfSukRmyiLMsAM0rxEysEXSAj1yCIukpNeXaW7RMDvszKGtEjOeyVD4Fx2JfZncaZHUSQBEzkkARM5JAETOSQDEzcujsQLxUsT+m6HLMzfd3MncWxmz2uySbF192+rqISExvpXeEl8SspXYjO0rjpPfsGsO3p5B/gNjfKuA1uEotwAAAABJRU5ErkJggg==" />
                          </div>
                          <div className="bookedTextDiv">
                            <p className="bookedText">Area Left  &nbsp; {unit_type.unit_remaining_covered_area+unit_type.unit_remaining_circulation_area} sqft</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="my-parent">
                    <div className="my-child-1">
                      <div className="bookedDiv">
                        <div className="contentsBooking">
                          <div className="bookingImgDiv">
                            <img className="bookingImg" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAABFCAYAAAAW0YV7AAAACXBIWXMAACxLAAAsSwGlPZapAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAJVSURBVHgB7Zv/UcIwFMe/9fxfNxAnUDfADXQCdQLdQJlA3QAmQCegG4gTECcAJ4jvXVOvPFpoCy3NI5+7XE1avXzMz9cAQFhre5QmthozSs/wEar42NbnFB5x5K7bVPoEHnGEAyMIaycIaycIaycIaycIaycIaycIaycIaycIaycIaycIaycIaycIa+cYDcCnkXS5wXZnVrtgGEWRyRbsXNjJfmH/sswz1eeapOO0oIku/YRuyKbcZTOp8DfqYei/9yPKOn1enHbpFyQVPXP5XonfNZTeEeg2kSygQd7HagsvXPKRKQ27/7ovCbsZdgZdXJHwNM3IWfoS+jDZjBTuQxdL3ZmRwhfQhVwyV4S9+sxVCT5kgXbhWBZojpZWAgdGs/Aor1AK+7q5kAyzEVIWKVw3iOgS3GiDoptS2MB/BnljN0UKx/Cbd5J9W/eA3EvzsjSHn4xI9n7TQ0st7LZhMfyjlCyTtyx9wi8GZWWZvHiYuzWHiF3fdRlKD0XLTxErLey6dZdbOV12rqrKMlFeoXvrMUF3YEkO4vkdWixDvipERTdIegjxirMFUjFD6dddOT/dRrIUPJYpzW273KNhonU3qQL8Uv0V7WHglkVq0Qc0QLTpAZLmncsjWoaEN9at1t8t8xBJ8wTWR4s0JVw2Hr5FMnl4TylhN0NeoyCoVg117xfbAmiIWuPEJicUPK57aIh9j+ElOMCmdE4/8tJhcGjwhsEmX6Dufpe2ybe8+6hO3oniLj/XMUB9ik47Fyw8RlLRg6DyxGCTeLmJWLmH3SPrav4A4z0oGO7yvsgAAAAASUVORK5CYII=" />
                          </div>
                          <div className="bookedTextDiv">
                            <p className="bookedText"> Affordable </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <p className="premiumParagrapgh">Introducing SAK Tower, a stunning architectural marvel redefining modern living. 
                  Experience luxury and sophistication in the heart of the city.</p>
                  <div className="premiumFooterDiv">
                    <div className="totalPriceDiv">
                      <p className="totalPriceText">
                        Rate Per sq.ft <br />
                        {formatNumberWithCommas(unit_type.unit_price_per_sqft_covered_area)} PKR
                      </p>
                    </div>
                    <div className="BookWithDiv">
                      <Link onClick={() => handlePurchaseLinkClick(unit_type.id)}>
                        <p className="totalPriceText">
                        Book With <br /> {formatNumber(Math.ceil(unit_type.unit_remaining_covered_area * PricePerSqft))} PKR
                        </p>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

    </div>
  );
};

export default PurchaseUnit;
