import React, { useState } from "react";
import "./GlobalTransaction.css";
import Select from 'react-select';

const options = [
  { value: 'one', label: 'SAK Tower', link: "https://mumbai.polygonscan.com/token/0xe682fa083bc3298bae2625c261c9f6de0d758d30" },
  // { value: 'two', label: 'Kharian Motorway', link: "https://mumbai.polygonscan.com/token/0x52c62ad342583642c68eeaee841aa58acc61c7ab#balances" }
];

const getOptionLabel = ({ label, link }) => (
  <a href={link} target="_blank" rel="noopener noreferrer" style={{ color: 'black' }}>
    {label}
  </a>
);

const MySelect = ({ onChange }) => (
  <Select
    name="form-field-name"
    options={options}
    getOptionLabel={getOptionLabel}
    className="my-select"
    onChange={onChange}
  />
);

const GlobalTransaction = () => {
  const [selectValue, setSelectValue] = useState('');

  const logChange = (val) => {
    setSelectValue(val);
    window.open(val.link, "_blank");
  };

  return (
    
    <>
      <div className="main-container-globaltransactions-page">
        <div className="main-content-box">
        <h4 className="global-transactions-title">Global Transactions Section</h4>
        <h5>Please Choose a Project to view its public transacations </h5>
        <p> Select Any Project to check its blockchain public ledger </p>
        <p>Select an option:</p>
        <MySelect onChange={logChange} />
        </div>
      </div>
    
    </>
  );
};

export default GlobalTransaction;
