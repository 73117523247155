import React, { useState } from "react";
import "./Changepassword.css";
import axios from "axios";
import { API_URL } from '../../config';
import Cookies from 'js-cookie';


function Changepassword() {
  const [showSetPassword, setShowSetPassword] = useState(false);


  const handleSetPasswordClick = () => {
    setShowSetPassword(true);
  };

  const handleCancelClick = () => {
    setShowSetPassword(false);
  };

  if (showSetPassword) {
    return <SetPassword handleCancelClick={handleCancelClick} />;
  }

  return (
    <>
      <div className="personalDetailMainBox">
        <h4 className="changePasswordHeading">Change Password</h4>
        <p className="changePasswordDescription">Set up a new login password for your account email(info@email.com)</p>
        <button className="changePasswordSetupBtn" onClick={handleSetPasswordClick}>
          {" "}
          Set up password
        </button>
      </div>
    </>
  );
}

function SetPassword({ handleCancelClick }) {
  const [currentPassword, setCurrentPassword] = useState('');
  const [NewPassword, setNewPassword] = useState('');
  const [ConfirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    if (NewPassword !== ConfirmPassword) {
      alert(`Passwords do not match`);
    }
    else if (NewPassword === ConfirmPassword) {
      try {
        const token = Cookies.get("_auth");

        if(!NewPassword){
          alert("Please Enter Field")
        }
        else{
          const response = await axios.patch(`${API_URL}/api/updateCurrentUser`, {
            password: currentPassword,
            new_password: NewPassword,
          }, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
          alert(response.data.message);
          handleCancelClick();
        }
        
      } catch (error) {
        if (error.response) {
          const status = error.response.status;
          if (status === 400 || status === 403 || status === 404 || status === 500) {
            // alert(error.response.data.message);
            setError(error.response.data.message);
            
            setTimeout(() => {
              setError('');
            }, 2000);
          }
        } else {
          console.error(error);
        }
      }
    }

  };

  return (
    <>
      <div className="personalDetailMainBox">
        <h4 className="changePasswordHeading">Change Your Password</h4>
        <p className="changePasswordDescription">Setup a new password. Your Data is completly secured with SSH hashing Algorithem</p>
        {error && <p style={{color:'red'}}>{error}</p>}
        <div className="changePasswordInputMainBox">
        <div className="changePasswordInputBox">
            <label className="Label">Current Password:</label>
            <input type="password" onChange={(e) => setCurrentPassword(e.target.value)} className="changePasswordInput"
              placeholder="New Password" />
          </div>
          <div className="changePasswordInputBox">
            <label className="Label">New Password:</label>
            <input type="password" onChange={(e) => setNewPassword(e.target.value)} className="changePasswordInput"
              placeholder="New Password" />
          </div>
          <div className="changePasswordInputBox">
            <label className="Label">Confirm Password:</label>
            <input type="password" onChange={(e) => setConfirmPassword(e.target.value)} className="changePasswordInput"
              placeholder="Confirm Password" />
          </div>
          
        </div>
        <a href="" style={{color: "blue", marginLeft: "80%"}}>Forgot Password?</a>
        <div className="changePasswordBtnBox">
          <button className="changePasswordCancelBtn" onClick={handleCancelClick}>Cancel</button>
          <button className="changePasswordUpdateBtn" onClick={handlePasswordSubmit}>Update</button>
        </div>

      </div>

    </>


    // <div className="Container">
    //   <span className="pCSpan">Change Password</span>
    //   <div className="Mini">
    //     <p className="para1">
    //       Set up a new login password for your account email(info@email.com)
    //     </p>
    //     <button className="button" onClick={handleCancelClick}>
    //       Cancel
    //     </button>
    //   </div>
    //   <div className="large">
    //     <p className="mya">Change Password</p>
    //     <p className="pa">
    //       {" "}
    //       Your personal detail is completely secure and we dont't share it to
    //       anyone.
    //     </p>
    //     <span className="addresss"> New Password</span>
    //     <span className="country">Confirm Password </span>
    //     <br></br>
    //     <input type="password" onChange={(e) => setNewPassword(e.target.value)} className="fulladd" />
    //     <input type="password" onChange={(e) => setConfirmPassword(e.target.value)} className="fullcountry" />
    //     <button className="btn111" onClick={handleCancelClick}>
    //       Cancel
    //     </button>
    //     <button className="btn222" onClick={handlePasswordSubmit}>Update</button>
    //   </div>
    // </div>
  );
}

export default Changepassword;
