import {React, useEffect, useState} from "react";
import { Link, useParams } from "react-router-dom";
import "./Ownershipjourney.css";
import flexible from "../assets/ownerShip-Img1.png";
import purchaseUnit from "../assets/ownerShip-Img2.png";
import axios from "axios";
import { API_URL } from "../config";


const Ownershipjouney = () => {

const { projectId } = useParams();
const [project, setProject] = useState([]);

  useEffect(() => {
    axios
      .get(`${API_URL}/api/projects/${projectId}`)
      .then((response) => setProject(response.data))
      .catch((error) => console.log(error));
  }, []);


  return (
    <>

      <div className="ownerShipMainBox">
        <h4 className="ownerShipHeading">Start Your Assets Ownership journey</h4>
        <p className="ownershipdescription">How would you like to invest?</p>

        <div className="ownerShipChooseInvestmentBox">
          <div className="ownershipSelectInvestBox">
            <img src={flexible} alt="Flexible Investment" className="investmentImage1" />
            <h5 className="ownershipInvestBoxHeading">Choose Area: Ease Your Way</h5>
            <p className="ownershipInvestmentBoxDescription">Purchase a Residental or Commercial unit</p>
            <Link to="/purchaseArea">
              <button className="ownershipInvestmentBtn">Proceed Now</button>
            </Link>
          </div>
          <div className="ownershipSelectInvestBox">
            <img src={purchaseUnit} alt="Flexible Investment" className="investmentImage2" />
            <h5 className="ownershipInvestBoxHeading">Purchase Entire Unit</h5>
            <p className="ownershipInvestmentBoxDescription">Purchase a Residental or Commercial unit</p>
            <Link to="/purchaseUnit">
              <button className="ownershipInvestmentBtn">Purchase Unit</button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Ownershipjouney;
