import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Dashboard.css";
import dashboardImg1 from "../assets/dashboard-1.png";
import dashboardImg2 from "../assets/dashboard-2.png";
import dashboardImg3 from "../assets/dashboard-3.png";
import dashboardImg4 from "../assets/dashboard-4.png";
import projectLogo from "../assets/projectLogo.png";
import sakRating1 from "../assets/sakrating.jpg";
import statusImg from "../assets/projectStatusimg.png"
import areaImg from "../assets/areaImg.png"
import axios from 'axios';
import { API_URL } from '../config';
import jwtDecode from 'jwt-decode';
import Cookies from 'js-cookie';
import { useSignOut } from "react-auth-kit";




const Dashboard = () => {
  const signOut = useSignOut();
  const navigate = useNavigate();
  const [token, setToken] = useState(null);


  const handleLogout = async (e) => {
    // Remove the 'token' variable from local storage
    //   localStorage.removeItem('token');
    Cookies.remove('_auth');
    alert("Your Session Expired. Please Login Again.");
    signOut();    //signed out using react-auth-kit
    // Navigate to "/"
    navigate("/");
  }

  useEffect(() => {
    // Retrieve the token from cookies
    const storedToken = Cookies.get('_auth');

    if (storedToken) {
      // Check if the token has expired
      const decodedToken = jwtDecode(storedToken);
      const currentTime = Date.now() / 1000; // Convert milliseconds to seconds

      if (decodedToken.exp < currentTime) {

        handleLogout();
        // window.location.href = '/'; // Redirect to the login page
      } else {
        // Token is still valid
        setToken(storedToken);
      }
    }
  }, []);


  const [selectedButton, setSelectedButton] = useState('all');
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/Projects`);
        setProjects(response.data);
      } catch (error) {
        console.log('Error fetching projects:', error);
      }
    };

    fetchProjects();
  }, []);

  const handleButtonClick = (projectButtoname) => {
    setSelectedButton(projectButtoname);
  };

  const [investNowButtonText, setInvestNowButtonText] = useState('Begin your investment journey');

  useEffect(() => {
    const handleInvestNowButtonResize = () => {
      if (window.innerWidth < 500) {
        setInvestNowButtonText('Start Investments');
      } else {
        setInvestNowButtonText('Begin your investment journey');
      }
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleInvestNowButtonResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleInvestNowButtonResize);
    };
  }, []);

  return (
    <>
      <div className="main-container">
        <div className="cards-section">

          <div className="dashboard-cards">
            <Link className="dashboard-card-link" to="/profile">
              <img src={dashboardImg1} alt="LOGO" className="cards-section-logo" />
              <h5 className="cards-section-title">My Profile</h5>
              <p className="cards-section-description">  Fulfill Your Profile Details For Enabling All Features In The Platform.</p>
              <button className="cards-section-button"> Edit Your Profile</button>
            </Link>
          </div>
          <div className="dashboard-cards">
            <Link className="dashboard-card-link" to="/CurrentProjects">
              <img src={dashboardImg2} alt="LOGO" className="cards-section-logo" />
              <h5 className="cards-section-title">Explore Properties</h5>
              <p className="cards-section-description">  Start Your Journey Towards Financial Freedom Through An Empowered Platform.</p>
              <button className="cards-section-button"> Explore Projects</button>
            </Link>
          </div>
          <div className="dashboard-cards">
            <Link className="dashboard-card-link" to="/learningCenter">
              <img src={dashboardImg3} alt="LOGO" className="cards-section-logo" />
              <h5 className="cards-section-title">Learning Hub</h5>
              <p className="cards-section-description"> Learn About Our Platform And
                Strategy In Democratizing Infrastructure.</p>
              <button className="cards-section-button"> Discover</button>
            </Link>
          </div>
          <div className="dashboard-cards">
            <Link className="dashboard-card-link" to="/UserPortfolio">
              <img src={dashboardImg4} alt="LOGO" className="cards-section-logo" />
              <h5 className="cards-section-title">My Portfolio</h5>
              <p className="cards-section-description">  Check Status Of All Your Investments at the Infratech platform</p>
              <button className="cards-section-button"> Check Portfolio</button>
            </Link>
          </div>
        </div>
        <div className="invest-now-button-div">
          <button className="invest-now-button">
            {investNowButtonText}
          </button>
        </div>
        <hr style={{ borderColor: '#066b6e' }} />
        <div className="project-showcase-div">
          <div className="current-projects-div">
            <h4 className="current-projects-heading">Active Projects </h4>
            {projects.map((project) => {
              return (
                <Link to={`/projectDetails/${project.id}`} key={project.id} className="ProjectCardLink">
                  <div className="projectCards" >

                    <div className="cardDivDashboard">
                      <img src={`${API_URL}/public/${project.project_logo}`} alt="" className=' projectImgDashboard' />
                      <div className="logoAndNameDiv">
                        <div className="projectLogoDiv">
                          <img className="projectLogo" src={projectLogo} />
                          {/* <img className="projectLogo" src={project.project_logo} /> */}
                        </div>
                        <div className="nameAndRating">
                          <p className="projectNameText">{project.name}</p>
                          <div className="imgNtxt">
                            <img
                              className="sakRatingImg"
                              src={sakRating1}
                            />
                            <p className="ratingTxt">
                              4.5
                              {/* {project.obtained_rating} */}

                            </p>
                          </div>

                        </div>
                      </div>
                      <div className="projectDescriptionDiv">
                        <p className='projectDescriptionPara'>{project.description}</p>
                      </div>
                      <div className="projectStatusAndAreaDiv">
                        <div className="projectStatus">
                          <div className="statusImgAndTextDiv">
                            <div className="statusImgDiv">
                              <img src={statusImg} alt=" status" className='statusImg' />
                            </div>
                            <div className="statusTextDiv">
                              <p className='statusText'>Project Status</p>
                              <p className='statusTextPercent'>(20%)</p>
                            </div>
                          </div>
                        </div>
                        <div className="projectArea">
                          <div className="statusImgAndTextDiv">
                            <div className="statusImgDiv">
                              <img src={areaImg} alt=" area" className='statusImg' />
                            </div>
                            <div className="statusTextDiv">
                              <p className='statusText'>Total Area</p>
                              <p className='statusTextPercent'>{project.area}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>


                  </div>
                </Link>
              );
            })}
          </div>
          <div className="latestUpdate">
            <div className="latestUpdaetHeading">
              <h4 className="latest-news-heading">Site Updates</h4>
              <p className="">
                View The Latest News And Updates From The Infratech DAO Platform And The Team
              </p>
            </div>
            <div className="videoSection">

              {/* <img className="updateVideo" src={updateVideo} alt="" /> */}
              <iframe className="responsive-iframe" frameborder="0" scrolling="no" marginleft="20px" marginwidth="0" width="100%" height="400px" type="text/html" src="https://www.youtube.com/embed/Ni_sQlE-phk?autoplay=0&fs=0&iv_load_policy=3&showinfo=0&rel=0&cc_load_policy=0&start=0&end=0&origin=https://youtubeembedcode.com"><div><small><a href="https://youtubeembedcode.com/en">youtubeembedcode.com/en/</a></small></div><div><small><a href="https://xn--snabbln5000-28a.com/">låna 5000 utan uc</a></small></div><div><small><a href="https://youtubeembedcode.com/en">youtubeembedcode en</a></small></div><div><small><a href="https://xn--snabbln5000-28a.com/">https://snabblån5000.com</a></small></div></iframe>

              {/* <YoutubePlaylist />      */}
            </div>



          </div>
        </div>
      </div>

    </>
  );
};

export default Dashboard