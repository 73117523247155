import React, { useEffect, useState } from 'react';
import axios from 'axios';
import "./PersonalDetail.css"
import flag from "../../assets/Flag.png";
import { API_URL, API_PUBLIC_PATH } from '../../config';
import { MdCloudUpload } from "react-icons/md";
import Cookies from 'js-cookie';
import Loading from '../../components/Loading';


function PersonalDetail() {
  const [loading, setLoading] = useState(true)
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [dob, setDob] = useState('');
  const [gender, setGender] = useState('');
  const [ProfilePic, setProfilePic] = useState(null);
  const [uploadTxt, setuploadTxt] = useState('Upload Picture')
  const [profileimageUrl, setprofileImageUrl] = useState('');

  useEffect(() => {
    loadPersonalData();
  }, []);



  const loadPersonalData = async () => {
    const token = Cookies.get("_auth");
    const config = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    };

    try {
      const response = await  axios.get(`${API_URL}/api/currentuser`, config)
      setFullName(response.data.full_name);
        setEmail(response.data.email);
        setPhone(response.data.phone);
        setDob(response.data.date_of_birth);
        setGender(response.data.gender);
        setProfilePic(`${API_PUBLIC_PATH}${response.data.profile_picture}`);
        setprofileImageUrl(`${API_PUBLIC_PATH}${response.data.profile_picture}`);
    } catch (error) {
      console.log(error);
    } finally{
      setLoading(false);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const token = Cookies.get("_auth");
      const formData = new FormData(); // Create a FormData object to send the file
      formData.append('profile_picture', ProfilePic); // Append the Profile Pic file to the form data
      formData.append('full_name', fullName);
      formData.append('email', email);
      formData.append('phone', phone);
      dob && formData.append('date_of_birth', dob);
      formData.append('gender', gender);
      const response = await axios.patch(`${API_URL}/api/updateCurrentUser`, formData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      alert(response.data.message);
      loadPersonalData();

    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        if (status === 400 || status === 403 || status === 404 || status === 500) {
          alert(error.response.data.message);
        }
      } else {
        console.error(error);
      }
    }
  };


  const uploadFileName = (event) => {
    const file = event.target.files[0];
    setuploadTxt(event.target.files[0].name.slice(0, 9) + "...");
    setProfilePic(file);
  }

  return (
    <>
      <div className="personalDetailMainBox">
        <h4 className="personalDetailHeading">Personal Details</h4>
        <p className="PersonalDetailDiscription">Please keep your personal information up-to-date at all times. We do not share your information with any third party.</p>

       {loading ? (<>
       <Loading loading={loading} />
       </>):(<>
        <div className="personalDetailDataMainBox">
          <div className="personalDetailDataBox">
            <label>Full Name:</label>
            <input
              type="text"
              placeholder="Full Name"
              name='full_name'
              className="personalDetailInputBox"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
            />
          </div>
          <div className="personalDetailDataBox">
            <label>Profile Picture:</label>
            <div className='personalDetialProfilePicBox'>
              <img src={profileimageUrl} className='personalDetailUserProfilePic' alt="" />
              <button className='profile-pic-upload-btn'>
                <MdCloudUpload style={{ fontSize: '2.2rem', marginRight: '0.5rem', position:"relative", top:"5px" }} />{uploadTxt}
                <input type="file" id="profilePic" name="profilePic" accept=".png, .jpg, .pdf" max-size="5000000" multiple="false" onChange={uploadFileName} />
              </button>
            </div>
          </div>
          <div className="personalDetailDataBox">
            <label>Email:</label>
            <input
              type="text"
              placeholder="info@gmail.com"
              className="personalDetailInputBox"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="personalDetailDataBox">
            <label>Phone No:</label>

              <input
              type='number'
                placeholder="000-1122333"
                className="personalDetailInputBox"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
          </div>
          <div className="personalDetailDataBox">
            <label>Date of Birth:</label>
            <input
              type="date"
              placeholder="DD/MM/YYYY"
              className="personalDetailInputBox"
              value={dob}
              onChange={(e) => setDob(e.target.value)}
            />
          </div>
          <div className="personalDetailDataBox">
            <label>Gender:</label>


            <select
              className="personalDetailInputBox"
              value={gender}
              onChange={(e) => setGender(e.target.value)}
              required>
              <option value="male">Male</option>
              <option value="female">Female</option>
            </select>

          </div>
        </div>
        <div className="personalDetailSubmitBtnBox">
          <button className="personalDetailUpdateBtn" onClick={handleSubmit}>Update</button>
        </div>

       </>)}
      </div>



    </>
  );
}

export default PersonalDetail;
