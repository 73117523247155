import React, { useState } from 'react'
import "./OrderSummary.css"
import { Link, useLocation } from 'react-router-dom'
import logo from "../assets/infra logo-01.png";
import { MdOutlineWest } from "react-icons/md";
import { MdOutlineMenu } from "react-icons/md";
import { CgProfile } from "react-icons/cg";
import { TbBuildingSkyscraper } from "react-icons/tb";
import { AiOutlineCreditCard } from "react-icons/ai";
import { AiOutlineFileText } from "react-icons/ai";
import { SlGraduation } from "react-icons/sl";
import { IoWalletOutline } from "react-icons/io5";
import { FiBell } from "react-icons/fi";
import { IoSettingsOutline } from "react-icons/io5";
import { MdCloudUpload } from "react-icons/md";
import elementImg from "../assets/SAK Logo.png"


const OrderSummary = () => {
  // const location = useLocation();
  // const { tower, order_data, price_to_pay } = location.state;
  // console.log(tower, order_data, price_to_pay);

  const [sideDisplay, setsideDisplay] = useState('0')
  const [sideContent, setsideContent] = useState('0')
  const [uploadTxtF, setuploadTxtF] = useState('Upload Front')
  const [uploadTxtB, setuploadTxtB] = useState('Upload Back')



  const displayMenu = () => {
    if (sideDisplay === '0') {
      setsideDisplay('22rem');
      setsideContent('1');
    }
    else {
      setsideDisplay('0');
      setsideContent('0');

    }
  }


  const uploadFileNameF = (event) => {
    const fileList = event.target.files;
    const fileName = fileList[0].name.slice(0, 9) + "...";
    setuploadTxtF(fileName);
  }

  const uploadFileNameB = (event) => {
    const fileList = event.target.files;
    const fileName = fileList[0].name.slice(0, 9) + "...";
    setuploadTxtB(fileName);
  }




  return (
    <>

      {/* ================================
------------NAV BAR--------------
================================= */}
      <div className="order-nav">
        <MdOutlineMenu onClick={displayMenu} className='menu-icon-mobile' />

        <span className='heading-nav'>
          <img className="logo-nav" src={logo} alt="InfraTech" />
          <MdOutlineWest className='back-icon' />
          <span >Purchase Now</span>
        </span>
      </div>







      {/* ================================
------------SIDE BAR--------------
================================== */}

      <div className="order-side" style={{ width: sideDisplay }}>
        <div className='side-icons' style={{ opacity: sideContent }}>
          <CgProfile className='menu-icon' />
          <div>
            <span>Dashboard</span>
          </div>
        </div>
        <div className='side-icons' style={{ opacity: sideContent }}>
          <TbBuildingSkyscraper className='menu-icon buils' />
          <div>
            <span>Projects</span>
          </div>
        </div>
        <div className='side-icons' style={{ opacity: sideContent }}>
          <AiOutlineCreditCard className='menu-icon' />
          <div>
            <span>Transactions</span>
          </div>
        </div>
        <div className='side-icons' style={{ opacity: sideContent }}>
          <AiOutlineFileText style={{ fontSize: '3.rem' }} className='menu-icon' />
          <div>
            <span>E-Reports</span>
          </div>
        </div>
        <div className='side-icons' style={{ opacity: sideContent }}>
          <SlGraduation style={{ fontSize: '3.5rem' }} className='menu-icon' />
          <div>
            <span>Explore and Learn</span>
          </div>
        </div>
        <div className='side-icons' style={{ opacity: sideContent }}>
          <IoWalletOutline className='menu-icon' />
          <div>
            <span>Wallet</span>
          </div>
        </div>
        <div className='side-icons' style={{ opacity: sideContent }}>
          <FiBell className='menu-icon' />
          <div>
            <span>Notifications</span>
          </div>
        </div>
        <div className='side-icons setting-icon' style={{ opacity: sideContent }}>
          <IoSettingsOutline className='menu-icon' />
          <div>
            <span>Settings</span>
          </div>
        </div>
      </div>


      {/* ================================
------------MAIN CONTENT--------------
================================== */}
      <div className="order-container">

        <div className="order-box1">
          <div className="box1-first">
            <img src={elementImg} alt="ELEMENT" />
            <span>SAK Tower</span>
          </div>


          <div
            className="box1-second">


            <div className='box1-second-div1'>
              <p className='box1-second-p'>Select Area</p>
            </div>

            <div className='box1-line1'></div>

            <div className='box1-second-div2'>
              <p className='box1-second-p'>Summay</p>
            </div>

            <div className='box1-line2'></div>

            <div className='box1-second-div3'>
              <p className='box1-second-p'>Confirmation</p>
            </div>

          </div>
        </div>


        <form enctype="multipart/form-data">
          <div className="order-box2">
            <h4>Documents & Summary</h4>
            <p>Please fill out and provide the required documents to have a smoother investment experience</p>

            <hr style={{ borderColor: '#066b6e' }} />

            <h5>Verify your Billing Address</h5>
            <p>A copy of your purchase will be sent to your registered billing address.</p>
            <input type="address" id="address" name='address' placeholder='Primary Address' className='order-inputs' />

            <h5>Contact Information</h5>
            <p>Verified phone number for confirmation purposes</p>
            <input type="number" id="ph" name="ph" placeholder="+92-0000000000" pattern="^((\+92)?(0092)?(92)?(0)?)(3)([0-9]{9})$/gm" maxLength={14} className='order-inputs' />

            <h5>CNIC</h5>
            <p>A verified CNIC for confirmation</p>
            <input type="number" id="cnic" name="cnic" placeholder="XXXXX-XXXXXXX-X" pattern="^[0-9]{5}-[0-9]{7}-[0-9]{1}$" maxLength={15} className='order-inputs' />

            <h5>CNIC Front & Back</h5>
            <p>PNG, JPG, PDF - less than 5 mb</p>
            <div className="upload-div">
              <button className='upload-btn'>
                <MdCloudUpload style={{ fontSize: '2.2rem', marginRight: '0.5rem' }} />{uploadTxtF}
                <input type="file" id="cnicF" name="cnicF" accept=".png, .jpg, .pdf" max-size="5000000" multiple="false" className='order-inputs-upload' onChange={uploadFileNameF} />
              </button>
              <button className='upload-btn'>
                <MdCloudUpload style={{ fontSize: '2.2rem', marginRight: '0.5rem' }} />{uploadTxtB}
                <input type="file" id="cnicB" name="cnicB" accept=".png, .jpg, .pdf" max-size="5000000" multiple="false" className='order-inputs-upload' onChange={uploadFileNameB} />
              </button>
            </div>
          </div>
        </form>

        <div className="order-box3">
          <h4>Order Details</h4>
          <hr style={{ borderColor: '#066b6e' }} />

          <div className="order-detail">

            <h5>ROUND</h5>
            <p>Round 3</p>

            <h5>Area Pledged</h5>
            <p>100 sq. ft.</p>

            <h5>Price / sq.ft.</h5>
            <p>8,500 PKR</p>

            <h5>EST.Market Price / sq.ft</h5>
            <p>9,741 PKR</p>

            <h5>Total Market Price</h5>
            <p>974,100 PKR</p>
            <hr />

            <h5>TOTAL AMOUNT</h5>
            <p>850,000 PKR</p>
            <hr />

            <button className='order-btn'>PURCHASE NOW
            </button>

            <div className="order-checkbox">
              <input type="checkbox" />
              <p>I agree to all <Link>Terms of Service </Link> of this Project.</p>
            </div>





          </div>



        </div>


      </div>

    </>
  )
}

export default OrderSummary