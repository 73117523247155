import React, { useState, useEffect } from 'react';
import "./Bankdetail.css";
import axios from 'axios';
import { API_URL } from '../../config';
import Cookies from 'js-cookie';
import Loading from '../../components/Loading';


function BankDetail() {
  const [loading, setLoading] = useState(true)
  const [addBankDetails, setAddBankDetails] = useState(false);
  const [userBankData, setUserBankData] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [BankList, setBankList] = useState([]);
  const [BankID, setBankID] = useState('');
  const [AccountNumber, setBankAccountNumber] = useState('');
  const [AccountTitle, setAccountTitle] = useState('');
  const [IBAN, setIBAN] = useState('');

  const token = Cookies.get("_auth");

  // CHECKBOX CLICK HANDLE
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  // LOAD USER BANK DETAILS
  const BankDetails = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/currentuser`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        }
      })
      const Data = response.data.bank_details;
      setUserBankData(Data);
      
    } catch (error) {
      console.log(error);
    }finally{
      setLoading(false)
    }
      
    
  };

  // LOAD BANK LIST DATA
  const loadBankList = async () => {

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    // Make a request with the config object
    axios.get(`${API_URL}/api/bank_name`, config)
      .then(response => {
        setBankList(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  };

  // CREATE BANK DETAILS
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!BankID || !AccountNumber || !AccountTitle) {
      alert("Please enter your complete information");
      return;
    }

    try {
      const token = Cookies.get("_auth");
      const response = await axios.post(`${API_URL}/api/bank`, {
        bank_id: BankID,
        account_number: AccountNumber,
        account_title: AccountTitle,
        iban: IBAN,
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      BankDetails();
      alert(response.data.message);
      setAddBankDetails(false);
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        if (status === 400 || status === 403 || status === 404 || status === 500) {
          alert(error.response.data.message);
        }
      } else {
        console.error(error);
      }
    }
  };

  // DELETE BANK DATA 
  const deleteBankData = async(value) => {
    try {
      await axios.delete(`${API_URL}/api/bank/${value}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        }
      });
      BankDetails();
      window.alert("BANK DATA Sucessfully DELETED");

    } catch (error) {
      console.log(error);
    }
  };

  // // UPDATE BANK DATA
  // const updateBankData = (value) => {
  //   try {
  //     axios.patch(`${API_URL}/api/bank/${value}`, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //         "Content-Type": "application/json",
  //       }
  //     });
  //     BankDetails();
  //     window.alert("BANK DATA SUCESSFULLY UPDATED");

  //   } catch (error) {
  //     console.log(error);
  //   }

  // };


  useEffect(() => {
    BankDetails();
    loadBankList();

  }, []);

  return (

    <>

      <div className="personalDetailMainBox">
        <h4 className="bankDetailHeading">Bank Details</h4>
        <p className="bankdetailDescription">Your personal information is completely secure.</p>
        {addBankDetails ? (
          <>
            {/* FORM START HERE */}
            <form onSubmit={handleSubmit} >
              <div className="bankDetailInputMainBox">
                <div className="addBankDetailInputBox">
                  <label className="Label">Bank Name:</label>
                  <select onChange={(e) => setBankID(e.target.value)} name="bankName" className='bankDetailInput' required>
                    <option value="">-- Select a bank --</option>
                    {BankList.map(item => (
                      <option key={item.id} value={item.id}>{item.bank_name}</option>
                    ))}
                  </select>
                </div>
                <div className="addBankDetailInputBox">
                  <label className="Label">Account Title:</label>
                  <input type="text" name="accountTitle" className='bankDetailInput' onChange={(e) => setAccountTitle(e.target.value)}
                    placeholder='Enter Account Title' required />
                </div>
                <div className="addBankDetailInputBox">
                  <label className="Label">Account Number:</label>
                  <input type="number" name="accountNumber" className='bankDetailInput' onChange={(e) => setBankAccountNumber(e.target.value)}
                    placeholder='Enter Account Number' required />
                </div>
                <div className="addBankDetailInputBox">
                  <label className="Label">IBAN:</label>
                  <input type="text" name="iban" className='bankDetailInput' onChange={(e) => setIBAN(e.target.value)}
                    placeholder='Enter IBAN' required />
                </div>
              </div>

              <div className="bankDetailCheckBox">
                <label htmlFor="agreed" id='checkbox-custom'>
                  <input type="checkbox" name="agreed" className='checkbox' checked={isChecked} onChange={handleCheckboxChange} />
                  All information is accurate &amp; I am responsible for the information provided above
                </label>
              </div>

              <div className="bankDetailBtnBox">
                <button className='bankDetailsCancelBtn' onClick={() => setAddBankDetails(false)}>Cancel</button>
                <button className='bankDetailsBtn' disabled={!isChecked} type='submit'>Submit</button>
              </div>
            </form>
            {/* FORM END HERE */}

          </>
        ) : (<>

          <div className="userBankDetailslist">

          {loading && <Loading loading={loading} />}
            {userBankData && userBankData.map((bankData) => (
              <div className="bankDetailCard" key={bankData.id}>
                <div className="cardDataBox">
                  <label >Bank Name</label>
                  <input type="text" placeholder='Bank Name' value={bankData.bank_list
                    .bank_name} disabled />
                </div>
                <div className="cardDataBox">
                  <label >Account Title</label>
                  <input type="text" placeholder='Account Title' value={bankData.account_title
                  } onChange={(e) => setAccountTitle(e.target.value)} />
                </div>
                <div className="cardDataBox">
                  <label >Account Number</label>
                  <input type="number" placeholder='Account Number' value={bankData.account_number} onChange={(e) => setBankAccountNumber(e.target.value)} />
                </div>
                <div className="cardDataBox">
                  <label >IBAN</label>
                  <input type="text"
                    placeholder='IBAN'
                    value={bankData.iban}
                    onChange={(e) => { setIBAN(e.target.value) }} />
                </div>
                <div className="CardDataBox">
                  <button className='bankDetailCardBtn' onClick={() => deleteBankData(bankData.id)} >Delete</button>
                  {/* <button className='bankDetailCardBtn' onClick={() => updateBankData(bankData.id)}>Update</button> */}
                </div>
              </div>
            ))}
          </div>

          <button className='bankDetailsBtn' onClick={() => setAddBankDetails(true)}>ADD BANK DETAILS</button>

        </>)}


      </div>

    </>


  )
}

export default BankDetail;
