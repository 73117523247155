import React from 'react'
import './unitOrderDetails.css'
import { useState, useEffect } from 'react'
import appartmentImg from "../../assets/appartment.jpg"
import { ImCancelCircle } from 'react-icons/im'
import { MdOutlineLibraryAdd } from 'react-icons/md'
import { BsDownload } from 'react-icons/bs'
import { useParams, Link } from 'react-router-dom'
import axios from "axios";
import { API_PUBLIC_PATH, API_URL } from '../../config'
import Cookies from 'js-cookie';
import Loading from '../../components/Loading'

const UnitOrderDetails = () => {
  const { orderId } = useParams()
  const [loading, setLoading] = useState(true);
  const [selectedImg, setSelectedImg] = useState(false);
  const [isPaymentPlan, setIsPaymentPlan] = useState(false);
  const [OrderDetail, setOrderDetail] = useState('')
  const [UnitType, setUnitType] = useState('')
  const [TransectionSlips, setTransectionSlips] = useState('')
  const [Project, setProject] = useState('')
  const [Floor, setFloor] = useState('')
  const [Category, setCategory] = useState('')

  const paymentStatusStyle = {
    color: OrderDetail.payment_status === 'Pending' ? 'red' : 'green',
  };

  const fetchUnitOrder = async () => {
    const token = Cookies.get('_auth');
    try {
      const response = await axios.get(`${API_URL}/api/unit_order/${orderId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      setOrderDetail(response.data);
      setProject(response.data.project);
      setTransectionSlips(response.data.unit_order_payment_slips);
      setUnitType(response.data.unit_type);
      setFloor(response.data.unit_type.floor);
      setCategory(response.data.unit_type.floor.category)
      if (response.data.amount_to_pay === 0) {
        setIsPaymentPlan(true);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const closeModal = () => {
    setSelectedImg(null);
  }

  useEffect(() => {
    fetchUnitOrder();
  }, [])
  useEffect(() => {
    if (selectedImg) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [selectedImg]);

  const downloadFile = async (url, filename) => {
    const imageUrl = url.split(`${API_PUBLIC_PATH}`)
    if (imageUrl[1] !== 'null') {
      const response = await fetch(url);
      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <>

      <div className="unitOrderDetailMainBox">

        <h4 className='unitOrderDetailHeading'>Unit Order Details</h4>

        {loading ? (<>
          <Loading loading={loading} />

        </>) : (<>
          <div className="unitOrderDetailDataMainBox">
            <div className="unitPlanImageBox">
              <img src={appartmentImg} alt="" />
            </div>

            <div className="unitOrderDataBox">
              <div className="unitOrderDataColumn">
                <span>Id No.</span>
                <span>{UnitType.unit_unique_specifier}</span>
              </div>
              <div className="unitOrderDataColumn">
                <span>Project Name</span>
                <span>{Project.name}</span>
              </div>

              <div className="unitOrderDataColumn">
                <span>Location</span>
                <span>Islamabad</span>
              </div>

              <div className="unitOrderDataColumn">
                <span>Category</span>
                <span>{Category.name}</span>
              </div>

              <div className="unitOrderDataColumn">
                <span>Floor</span>
                <span>{Floor.name}</span>
              </div>

              <div className="unitOrderDataColumn">
                <span>Covered Area (sq.ft)</span>
                <span>{UnitType.unit_total_covered_area} (sq.ft)</span>
              </div>
              <div className="unitOrderDataColumn">
                <span>Circulation Area (sq.ft)</span>
                <span>{UnitType.unit_total_circulation_area} (sq.ft)</span>
              </div>
              <div className="unitOrderDataColumn">
                <span>Total Area (sq.ft)</span>
                <span>{UnitType.unit_total_circulation_area + UnitType.unit_total_covered_area} (sq.ft)</span>
              </div>
              <div className="unitOrderDataColumn">
                <span>Status</span>
                <span style={paymentStatusStyle}>{OrderDetail.payment_status}</span>
              </div>
              <hr />


            </div>

          </div>

          <div className="unitOrderDataTableBox">
            <table className='unitPlanDataTable'>
              <tr>
                <th>Total Amount</th>
                <th>Paid Amount</th>
                <th>Remaining Amount</th>
                <th>Current Amount to Pay</th>
              </tr>

              <tr>
                <td>{OrderDetail && OrderDetail.total_bill.toLocaleString()} (PKR)</td>
                <td>{OrderDetail && OrderDetail.amount_paid ? OrderDetail.amount_paid.toLocaleString() : 0} (PKR)</td>
                <td>{OrderDetail && OrderDetail.amount_left.toLocaleString()} (PKR)</td>
                <td>{OrderDetail && OrderDetail.amount_to_pay.toLocaleString()} (PKR)</td>
              </tr>
            </table>
          </div>

          <div className="unitOrderSlipGernerateBox">
            {isPaymentPlan ? (<>
              <p>Your Order Status Is Pending. Please Generate Another Slip Amount You Want To Pay</p>
              <Link to={`/unitPaymentPlan/${orderId}`}>
                <button className='generateSlipBtn'>Generate Slip</button>
              </Link>
            </>) : (<>
              <p style={{ fontSize: '1.6rem' , textAlign: 'center' }}> Please Submit the Remaining Amount of PKR <strong>{OrderDetail && OrderDetail.amount_to_pay.toLocaleString()}</strong> Rupees and Upload Slip to The Admin for Verification</p>
            </>)}
          </div>

          <div className="unitOrderSlipsMainBox">
            <div className='unitOrderSlipsHeading'>
              <h4>All Transactions History</h4>
              <Link to={`/unitpaymentDetails/${orderId}`}>
                <span className='uploadSlipBtn'>
                  Upload Slip <MdOutlineLibraryAdd className='uploadSlipIcon' />
                </span>
              </Link>
            </div>
            <table className='unitOrderSlipsTable'>
              <tr>
                <th>Transaction Id</th>
                <th>Bank </th>
                <th>Date</th>
                <th>Status</th>
                <th>Details</th>
              </tr>
              {TransectionSlips && TransectionSlips.map((Slip) => (
                <tr key={Slip.id}>
                  <td>{Slip.id < 10 ? "0" + Slip.id : Slip.id}</td>
                  <td>Askari Bank Ltd</td>
                  <td>{new Date(Slip.createdAt).toLocaleDateString()}</td>
                  <td>{Slip.title}</td>
                  <td>
                    <span className='unitOrderViewSlip' onClick={() => setSelectedImg(`${API_PUBLIC_PATH}${Slip.slipPath}`)}>
                      View Slip
                    </span>
                  </td>
                </tr>
              ))}
            </table>
          </div>
        </>)
        }

      </div>

      {selectedImg && (
        <div className="modal" >
          <ImCancelCircle className='modalCancelBtn' onClick={closeModal} />
          <div className="modalMain">
            <BsDownload className='downloadSlipIcon' onClick={() => downloadFile(selectedImg, 'Transation_Slip.jpg')} />
            <img src={selectedImg} alt="Your Slip Not Found. Please Contact Admin" className='unitOrderModalImg' />
          </div>
        </div>
      )}

    </>
  )
}

export default UnitOrderDetails