import React , {useState} from 'react'
import './PurchaseUnitPlanNew.css'
import appartmentImg from "../assets/appartment.jpg"
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios, { Axios } from 'axios';
import Cookies from 'js-cookie';
import { API_URL } from "../config";


const PurchaseUnitPlanNew = () => {
    const location = useLocation();
    const navigate = useNavigate();

    
    const {
        unit_type_id,
        unit_type_name,
        covered_area,
        floor_name,
        price_per_sqft,
        total_price,
        unit_type,
        project_id,
        project_name,
      } = location.state || {};

    const [isPurchase, setIsPurchase] = useState(false);
    const [ischecked , setIsChecked] = useState(false)
    const [payementCheck, setPayementCheck] = useState(false)
    const [message, setMessage] = useState('')
    const [payementOption , setPayementOption] = useState(0);
    const [pricePercentage, setPricePercentage] = useState(0);
    const [pendingAmount, setPendingAmount] = useState(total_price);
    const totalArea = unit_type.unit_total_covered_area + unit_type.unit_total_circulation_area;

    
    const handleCheckboxChange = (payementOption) => {
        let percentage = (payementOption / 100) * total_price;
        setPricePercentage(percentage);
        setPayementOption(payementOption);
        let amountPending =  total_price - percentage;
        setPendingAmount(amountPending);
        setPayementCheck(true);
      };

    const handleUnitBooking = async(e)=>{
        e.preventDefault();
        if(payementCheck){
            try {
                let paidAmount = 0;
                const token = Cookies.get('_auth');
                const formData = new FormData();
                
                const response = await axios.post(`${API_URL}/api/unit_order`, {
                    area_sq_feet: totalArea, 
                    total_bill: total_price,
                    unit_type_id:  unit_type_id,
                    amount_to_pay: pricePercentage,
                    amount_left: pendingAmount,
                    purchase_date: Date.now(),
                    project_id: project_id,
                    amount_paid: paidAmount,
                    payment_status: "Pending",
                  },{
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                });
                alert(response.data.message);
                navigate('/transactions')
                
            } catch (error) {
                if (error.response) {
                    const status = error.response.status;
                    if (
                      status === 400 ||
                      status === 403 ||
                      status === 404 ||
                      status === 500
                    ) {
                      alert(error.response.data.message);
                    }
                  } else {
                    console.error(error);
                  }
                
            }
        }else{
            setMessage('Please Choose Payement Plan ')
            setInterval(() => {
                setMessage('')
            }, 4000);
        }

    }

  return (
    <>
    {!isPurchase ? (<>
        <div className="purchaseUnitPlanMainBox">
        
        <h4 className='purchaseUnitPlanHeading'> {floor_name} ({unit_type_name})
        
        </h4>
        
        <div className="unitPlanDataMainBox">
            <div className="unitPlanImageBox">
                <img src={appartmentImg} alt=""  />
            </div>
            
            <div className="unitPlanDataBox">
                <div className="unitPlanDataColumn">
                   <span>Id No.</span>
                   <span>{unit_type.unit_unique_specifier}</span>
                </div>
                <div className="unitPlanDataColumn">
                   <span>Project Name</span>
                   <span>{project_name}</span>
                </div>

                <div className="unitPlanDataColumn">
                   <span>Location</span>
                   <span>Islamabad</span>
                </div>

                <div className="unitPlanDataColumn">
                   <span>Covered Area (sq.ft)</span>
                   <span>{unit_type.unit_total_covered_area} (sq.ft)</span>
                </div>
                <div className="unitPlanDataColumn">
                   <span>Circulation Area (sq.ft)</span>
                   <span>{unit_type.unit_total_circulation_area} (sq.ft)</span>
                </div>
                <div className="unitPlanDataColumn">
                   <span>Total Area (sq.ft)</span>
                   <span>{totalArea} (sq.ft)</span>
                </div>
                <hr />
                

            </div>

        </div>
        <div className="unitPlanDataTableBox">
            <table className='unitPlanDataTable'>
                <tr>
                    <th>Bed Rooms</th>
                    <th>Bathrooms</th>
                    <th>Kitchens</th>
                    <th>Elevators</th>
                    <th>Sitting Rooms</th>
                    <th>Balcony</th>
                    
                </tr>

                <tr>
                    <td>{unit_type.unit_living_rooms === 0 ? "N/A": unit_type.unit_living_rooms}</td>
                    <td>{unit_type.unit_bathrooms === 0 ? "N/A": unit_type.unit_bathrooms}</td>
                    <td>N/A</td>
                    <td>Yes</td>
                    <td>Yes</td>
                    <td>Yes</td>
                </tr>
            </table>
        </div>
        <div className="unitPlanPurchaseBtnBox">
            <span>Total Amount: { total_price.toLocaleString()} PKR</span>
            <span>
                <button className='purchaseUnitBuyButton' onClick={()=> setIsPurchase(true)}>PROCEED BOOKING</button>
            </span>
        </div>

    </div>


    </>):(<>

    <div className="purchaseUnitPlanMainBox">
        <h4>Discovering Ecosystem Interconnections: Unit Plan</h4>
      <p style={{color: 'red'}}>{message}</p>
        <div className="payementUnitPlanBox">
            <div className="choosePayementPlanBox">
                <h4 className='payemnetPlanHeading'>Choose Your Unit Payement Plan</h4>
                <div className="payementPlanDataBox">
                    <input type="checkbox" 
                    checked={payementOption === 100}
                    onChange={() => handleCheckboxChange(100)}
                    />
                    <span>100% Advance Payement Plan</span>
                </div>

                <div className="payementPlanDataBox">
                    <input type="checkbox" 
                    checked={payementOption === 75}
                    onChange={() => handleCheckboxChange(75)}
                    />
                    <span>75% Advance Payement Plan</span>
                </div>

                <div className="payementPlanDataBox">
                    <input type="checkbox" 
                    checked={payementOption === 50}
                    onChange={() => handleCheckboxChange(50)}
                    />
                    <span>50% Advance Payement Plan</span>
                </div>

                <div className="payementPlanDataBox">
                    <input type="checkbox" 
                    checked={payementOption === 25}
                    onChange={() => handleCheckboxChange(25)}
                    />
                    <span>25%  Advance Payement Plan</span>
                </div>
            </div>

            <div className="summaryUnitPlanBox">
                <h4 className='payemnetPlanHeading'>Summary</h4>
                <div className='unitSummaryDataBox'>
                    <span>ID No.</span>
                    <span>{unit_type.unit_unique_specifier}</span>
                </div>
                <div className='unitSummaryDataBox'>
                    <span>Project Name</span>
                    <span>{project_name}</span>
                </div>  
                <div className='unitSummaryDataBox'>
                    <span>Total Area (sq.ft)</span>
                    <span>{totalArea} (sq.ft)</span>
                </div>
                <div className='unitSummaryDataBox'>
                    <span>Total Price</span>
                    <span>{total_price.toLocaleString()} PKR</span>
                </div>
                <div className='unitSummaryDataBox'>
                    <span>Amount To Pay</span>
                    <span>{pricePercentage.toLocaleString()} PKR</span>
                </div>
                <div className='unitSummaryDataBox'>
                    <span>Amount Left</span>
                    <span>{pendingAmount.toLocaleString()} PKR</span>
                </div>
            </div>
        </div>
        <div className="unitBookNowBtnBoxMain">
            <div className='unitTermsAndConditonBox'>
            <input type="checkbox"
            checked={ischecked}
            onChange={(e)=> setIsChecked(e.target.checked)}/>
            <span>I accept to terms & condition of SAK Tower.</span>
            </div>
            <div className="unitBookNowBtnBox">
                <button onClick={()=> setIsPurchase(false)}>Cancel</button>
                <button disabled={!ischecked} onClick={handleUnitBooking}>BOOK NOW</button>
            </div>
            
        </div>
    </div>

    
    </>)}
    
    </>
  )
}

export default PurchaseUnitPlanNew