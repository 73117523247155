import React from 'react';
import './loading.css'

import { SyncLoader } from 'react-spinners';

const Loading = ({loading}) => {
  return (
    <div className='unitPaymentPlanLoadingBox'>
                        <span>Loading Please Wait  </span>
                        <SyncLoader
                            size={10}
                            color={'#03989E'}
                            loading={loading}
                        />
                    </div>
  )
}

export default Loading;