import React from "react";
import { Route, Navigate } from "react-router-dom";
import Cookies from 'js-cookie';


const LogInRoute = ({ children }) => {
    // const isLogin = localStorage.getItem("token");
    const isLogin = Cookies.get("_auth");
    return isLogin ? <Navigate to="/dashboard"> </Navigate> : children;
};

export default LogInRoute;