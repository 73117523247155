import React from 'react'
import { Link } from 'react-router-dom'
import './Verifylogin.css'
import logo from "../assets/infra logo-01.png";

const Verifylogin = () => {


  // OTP: FOCUS ON NEXT INPUT
  const nextfocus = (e) => {
    const input = e.target;
    const maxLength = parseInt(input.getAttribute("maxlength"));
    const currentLength = input.value.length;
    if (currentLength === maxLength) {
      const nextInput = input.nextElementSibling;
      if (nextInput) {
        nextInput.focus();
      }
    }
  }

  return (
    <>

      <div className="sNavbar__img">
        <img className="sNavbar__logo" src={logo} alt="" />
      </div>

      <div className="sContainer">
        <div className="sContainer1 lcont">
          <h1 className="form-title-v">Two-step authentication</h1>
          <form action="#">
            <div className="main-user-info">
              <div className="wSubTitle">
                <h2 className="wSubTitleVerfh2">We’ve sent you a code</h2>
                <hr />
                <p className="wSubTitleP">Please enter the unique code we’ve sent to your mobile number</p>

              </div>

              <div className="input-box-code">
                <input type="text" maxlength="1" onInput={nextfocus} />
                <input type="text" maxlength="1" onInput={nextfocus} />
                <input type="text" maxlength="1" onInput={nextfocus} />
                <input type="text" maxlength="1" onInput={nextfocus} />
                <input type="text" maxlength="1" onInput={nextfocus} />
                <input type="text" maxlength="1" onInput={nextfocus} />
              </div>

              <div className="sign-btn verfBtn">
                <button>Verify & Login&nbsp;&rarr;
                </button>
              </div>

              <div className="wSubTitle">
                <h2 className="wSubTitleVerf-Code-h2">Didn’t receive the code?</h2>
                <p className="wSubTitleP">Resend in 5:00 (1 of 3 attempts)</p>

              </div>
              <hr />
              <br />
              <p className="privacy-link"><Link to="/Welcomeinfratech">Use another Phone</Link>
              </p>

            </div>
          </form>
        </div>
      </div>

    </>
  )
}

export default Verifylogin