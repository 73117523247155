import React, { useState, useEffect } from 'react'
import "./OrderSummary.css"
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { MdCloudUpload } from "react-icons/md";
import elementImg from "../assets/SAK Logo.png"
import { API_URL } from '../config';
import axios from 'axios';
import Cookies from 'js-cookie';



const OrdersSummary = () => {

  const location = useLocation();
  const { tower, order_data, submit_disabled } = location.state || {};
  // console.log(tower, order_data, price_to_pay);

  const [phone, setPhone] = useState('');
  const [Cnic, setCnic] = useState('');
  const [SubmitButtonDisabled, setSubmitButtonDisabled] = useState(submit_disabled ? submit_disabled : true);
  const [CnicFront, setCnicFront] = useState(null);
  const [CnicBack, setCnicBack] = useState(null);
  const [UserAddress, setUserAddress] = useState('');

  const [AreaSqFt, setAreaSqFt] = useState(order_data ? order_data.area_sq_feet : "Failed to load resource");
  const [UserID, setUserID] = useState(order_data ? order_data.user_id : "Failed to load resource");
  const [ProjectID, setProjectID] = useState(order_data ? order_data.project_id : "Failed to load resource");
  const [AmountToPay, setAmountToPay] = useState(order_data ? `${order_data.amount_to_pay} PKR` : "Failed to load resource");
  const [id, setid] = useState('');                     //referring to order id 
  const [towerName, setTowerName] = useState(tower ? tower : "Failed to load resource");
  const navigate = useNavigate();
  const [Price, setPrice] = useState("");


  useEffect(() => {
    loadPersonalData();
  }, []);



  const loadPersonalData = async () => {
    const token = Cookies.get("_auth");

    const config = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    };

    // Make a request with the config object
    axios.get(`${API_URL}/api/currentuser`, config)
      .then(response => {
        setPhone(response.data.phone);
        setCnic(response.data.cnic);
        setUserAddress(response.data.address);


      })
      .catch(error => {
        console.log(error);
      });
  }


  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const token = Cookies.get("_auth");
      const formData = new FormData(); // Create a FormData object to send the file
      formData.append('phone', phone);
      formData.append('userAddress', UserAddress);
      formData.append('cnic_front_picture', CnicFront); // Append the CnicFront file to the form data
      formData.append('cnic_back_picture', CnicBack); // Append the CnicBack file to the form data
      formData.append("cnic", Cnic);      //CNIC text


      const response = await axios.patch(`${API_URL}/api/updateCurrentUser`, formData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      alert(response.data.message);
      loadPersonalData();
      navigate("/purchaseArea/purchaseDetails",
        { state: { tower: tower, order_data: order_data, submit_disabled: false } }
      );

    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        if (status === 400 || status === 403 || status === 404 || status === 500) {
          alert(error.response.data.message);
        }
      } else {
        console.error(error);
      }
    }
  };




  const [uploadTxtF, setuploadTxtF] = useState('Upload Front')
  const [uploadTxtB, setuploadTxtB] = useState('Upload Back')


  const uploadFileNameF = (event) => {
    const file = event.target.files[0];
    setuploadTxtF(event.target.files[0].name.slice(0, 9) + "...")
    setCnicFront(file);
    // console.log(event, file, CnicFront);
  }

  const uploadFileNameB = (event) => {
    const file = event.target.files[0];
    setuploadTxtB(event.target.files[0].name.slice(0, 9) + "...");
    setCnicBack(file);
  }



  return (
    <>

      {/* ================================
------------MAIN CONTENT--------------
================================== */}
      <div className="order-container">

        <div className="order-box1">
          <div className="box1-first">
            <img src={elementImg} alt="ELEMENT" />
            <span>{towerName}</span>
          </div>


          <div
            className="box1-second">


            <div className='box1-second-div1'>
              <p className='box1-second-p'>Select Area</p>
            </div>

            <div className='box1-line1'></div>

            <div className='box1-second-div2'>
              <p className='box1-second-p'>Summay</p>
            </div>

            <div className='box1-line2'></div>

            <div className='box1-second-div3'>
              <p className='box1-second-p'>Confirmation</p>
            </div>

          </div>
        </div>


        <form enctype="multipart/form-data">
          <div className="order-box2">
            <h4>Documents & Summary</h4>
            <p>Please fill out and provide the required documents to have a smoother investment experience</p>

            <hr style={{ borderColor: '#066b6e' }} />

            <h5>Verify your Billing Address</h5>
            <p>A copy of your purchase will be sent to your registered billing address.</p>
            <input type="address" id="address" name='address' placeholder='Primary Address' className='order-inputs' value={UserAddress}
              onChange={(e) => setUserAddress(e.target.value)} />

            <h5>Contact Information</h5>
            <p>Verified phone number for confirmation purposes</p>
            <input type="number" id="ph" name="ph" placeholder="+92-0000000000" pattern="^((\+92)?(0092)?(92)?(0)?)(3)([0-9]{9})$/gm" maxLength={14} className='order-inputs'
              value={phone} onChange={(e) => setPhone(e.target.value)} />

            <h5>CNIC</h5>
            <p>A verified CNIC for confirmation</p>
            <input type="number" id="cnic" name="cnic" placeholder="XXXXX-XXXXXXX-X" pattern="^[0-9]{5}-[0-9]{7}-[0-9]{1}$" maxLength={15} className='order-inputs'
              value={Cnic} onChange={(e) => setCnic(e.target.value)} />

            <h5>CNIC Front & Back</h5>
            <p>PNG, JPG, PDF - less than 5 mb</p>
            <div className="upload-div">
              <button className='upload-btn'>
                <MdCloudUpload style={{ fontSize: '2.2rem', marginRight: '0.5rem' }} />{uploadTxtF}
                <input type="file" id="cnicF" name="cnicF" accept=".png, .jpg, .pdf" max-size="5000000" multiple="false" className='order-inputs-upload' onChange={uploadFileNameF} />
              </button>
              <button className='upload-btn'>
                <MdCloudUpload style={{ fontSize: '2.2rem', marginRight: '0.5rem' }} />{uploadTxtB}
                <input type="file" id="cnicB" name="cnicB" accept=".png, .jpg, .pdf" max-size="5000000" multiple="false" className='order-inputs-upload' onChange={uploadFileNameB} />
              </button>
            </div>
          </div>
        </form>

        <div className="order-box3">
          <h4>Order Details</h4>
          <hr style={{ borderColor: '#066b6e' }} />

          <div className="order-detail">

            <h5>ROUND</h5>
            <p>Current</p>

            <h5>Your Infratech DAO ID</h5>
            <p>{UserID}</p>

            <h5>Area Pledged</h5>
            <p>{AreaSqFt}</p>

            <h5>Price / sq.ft.</h5>
            <p>8,500 PKR</p>

            {/* <h5>EST.Market Price / sq.ft</h5>
            <p>9,741 PKR</p> */}

            <h5>Total Market Price</h5>
            <p>{AmountToPay} </p>
            <hr />

            <h5>TOTAL AMOUNT</h5>
            <p>{AmountToPay} </p>
            <hr />

            <button className='order-btn' type='submit' onClick={handleSubmit} >PURCHASE NOW
            </button>

            <div className="order-checkbox">
              <input type="checkbox" />
              <p>I agree to all <Link>Terms of Service </Link> of this Project.</p>
            </div>





          </div>



        </div>


      </div>

    </>
  )
}

export default OrdersSummary