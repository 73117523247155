import React from 'react'
import './PayementUnitPlan.css'
import { useState } from 'react'
import { useNavigate } from "react-router-dom";

const PayementUnitPlan = () => {

    const navigate = useNavigate()
    const [selectedOption, setSelectedOption] = useState('payement100');

    const handlePurchaseNow = ()=>{
        window.alert("Sucessfully Done")
        navigate('/dashboard')
    }

    const handleCheckboxChange = (payementOption) => {
        setSelectedOption(payementOption);
    };

    return (
        <>
            <div className="payementUnitPlanMainBox">
                <h4 className="payementPlanHeading">Choose Unit Plan</h4>
                <div className="payementPlanDetailMainBox">
                    <div className="selectPayementPlanBox">
                        <div className="checkPayementPlanBox">
                            <input type="checkbox" id='payement100' className='payementPlanCheckBox'
                                checked={selectedOption === 'payement100'}
                                onChange={() => handleCheckboxChange('payement100')} />
                            <label for='payement100'>Payement Plan 100%</label>

                        </div>

                        <div className="checkPayementPlanBox">
                            <input type="checkbox" id="payement75" className='payementPlanCheckBox'
                                checked={selectedOption === 'payement75'}
                                onChange={() => handleCheckboxChange('payement75')} />
                            <label for='payement75'>Payement Plan 75%</label>
                        </div>

                        <div className="checkPayementPlanBox">
                            <input type="checkbox" id="payement50" className='payementPlanCheckBox'
                                checked={selectedOption === 'payement50'}
                                onChange={() => handleCheckboxChange('payement50')} />
                            <label for='payement50'>Payement Plan 50%</label>
                        </div>

                        <div className="checkPayementPlanBox">
                            <input type="checkbox" id="payement25" className='payementPlanCheckBox'
                                checked={selectedOption === 'payement25'}
                                onChange={() => handleCheckboxChange('payement25')} />
                            <label for='payement25'>Payement Plan 25%</label>
                        </div>
                    </div>
                    <div className="payementPlanSummaryBox">
                        <h4 className="payementPlanHeading">SUMMARY</h4>
                        <div className="summaryDetailMainBox">
                            <div className="summaryDetailDataBox">
                                <p className="summaryPayementLabel">Lower GR-Floors</p>
                                <p className="summaryPayementData">Data</p>
                            </div>
                            <div className="summaryDetailDataBox">
                                <p className="summaryPayementLabel">Price Per Sq.ft</p>
                                <p className="summaryPayementData">Data</p>
                            </div>
                            <div className="summaryDetailDataBox">
                                <p className="summaryPayementLabel">Total Price </p>
                                <p className="summaryPayementData">Data</p>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="payementPlanBtnBox">
                    <div className="payementTermsAndConditonsBox">
                        <input type="checkbox" id='termsAndConditons'/>
                        <label for="termsAndConditons">I accept to terms & condition of SAK Tower.</label>
                    </div>
                    <div className="payementDoneBtnBox">
                        <button className='payementSubmitBtn' onClick={handlePurchaseNow}>PURCHASE NOW</button>
                    </div>
                </div>
            </div>


        </>
    )
}

export default PayementUnitPlan