import React, { useState, useEffect } from 'react';
import { useParams, Route, useNavigate } from 'react-router-dom';
import "./flexiblePaymentDetails.css"
import { API_URL } from '../../config';
import axios from 'axios';
import Cookies from 'js-cookie';


const FlexiblePaymentDetails = () => {
  const { orderId } = useParams(); // Retrieve the order ID from URL parameters
  const navigate = useNavigate()
  const [selectedBank, setSelectedBank] = useState('AskariBank');
  const [orderDetails, setOrderDetails] = useState(null);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [imageURL, setImageURL] = useState(''); // for displaying image in the container

  const token = Cookies.get("_auth");

  const handleBankChange = (event) => {
    const selectedBankValue = event.target.value;
    setSelectedBank(selectedBankValue);
  };



  const uploadFileName = (event) => {
    const file = event.target.files[0];
    // setUploadedImageText(event.target.files[0].name.slice(0, 9) + "...")
    setUploadedImage(file);
    setImageURL(URL.createObjectURL(file)); // set up the image URL for displaying
    // console.log(event, file, uploadedImage);
  }

  const handleFileUpload = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData();
      formData.append('slipPath', uploadedImage);
      formData.append('title', "Being Verified");
      formData.append('orderId', orderId);

      const response = await axios.post(`${API_URL}/api/orderPayments`, formData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      alert(response.data.message);
      navigate(`/flexibleOrderDetails/${orderId}`)

    }
    catch (error) {
      if (error.response) {
        const status = error.response.status;
        if (status === 400 || status === 403 || status === 404 || status === 500) {
          alert(error.response.data.message);
        }
      } else {
        console.error(error);
      }
    }
  }

  const fetchOrderDetails = () => {
    axios
      .get(`${API_URL}/api/order/${orderId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then((response) => {
        setOrderDetails(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchOrderDetails();
  }, []);


  let bankData;

  switch (selectedBank) {
    case 'AskariBank':
      bankData = {
        accountTitle: 'SAK Technologies (Private) Ltd',
        accountNumber: '0175-0420000628',
        IBAN: 'PK40ASCM0001750420000628',
        bank: 'Askari Bank Ltd',
      };
      break;
    default:
      bankData = {};
      break;
  }

  if (!orderDetails) {
    return <p>Loading...</p>;
  }

  return (
    <div className="paymentDetailsMain">
      <div className="makepaymentContainer">
        <div className="selectBankContainer">
          <h4 className="makepaymentHeading">1) Make payment</h4>
          <div className="selectBankOption">
            <h4 className="selectBankHeading">Select Bank</h4>
            <select
              name="Select Bank"
              id="selectBank"
              value={selectedBank}
              onChange={handleBankChange}
            >
              <option value="AskariBank">Askari Islamic Bank</option>
            </select>
          </div>
        </div>
        <div className="bankDetailsContainer">
          <div className="bankAccountDetails">
            <div className="paymentAccountDetails">
              <h5 className="accountTitles">Account Title:</h5>
              <h5 className="accountData">{bankData.accountTitle}</h5>
            </div>
            <div className="paymentAccountDetails">
              <h5 className="accountTitles">Account Number:</h5>
              <h5 className="accountData">{bankData.accountNumber}</h5>
            </div>
            <div className="paymentAccountDetails">
              <h5 className="accountTitles">IBAN:</h5>
              <h5 className="accountData">{bankData.IBAN}</h5>
            </div>
            <div className="paymentAccountDetails">
              <h5 className="accountTitles">Branch:</h5>
              <h5 className="accountData">{bankData.branch}</h5>
            </div>
          </div>
        </div>
      </div>

      {/* MID BOX */}
      <div className="paymentDetailMidBox">
        <div className="uploadDepositSlipBox">
          <h4 className="uploadDepositHeading">2) Upload payment Verification/ Scanned Deposit Slip</h4>
          <h4 className="depositStepsHeading">Step 1</h4>

          <ul className="depositStepsDetail">
            Please upload a scanned copy of the payment slip so our team can check and verify your transaction.!
          </ul>
          <div className="uploadDepositSlip">
            <label htmlFor="depositSlip" className="depositSlip">
              Upload Deposit Slip
            </label>
            <input type="file" id="depositSlip" name="depositSlip" accept=".png, .jpg, " max-size="5000000" multiple="false" onChange={uploadFileName} />
          </div>
        </div>

        {/* IMAGE SIDE BOX */}
        <div className="depositSlipImageBox">
          <div className="depositSlipImage">
            {uploadedImage ? (
              <img src={imageURL} alt="Upload Deposit Slip" className="uploadSlipBox" />
            ) : (
              <div style={{ marginTop: '40px' }}>Upload Deposit Slip</div>
            )}
          </div>
        </div>
      </div>

      {/* BOTTOM BOX */}
      <div className="paymentDetailsBottomBox">
        <div className="paymentStatusVerifyBox">
          <h4 className="statusVerificationHeading">3) Status: Verified</h4>
          <p className="paymentStatusVerifyDiscription">
            After Successfull Verification, Your Transaction will be marked verified and Our Team will transfer the tokens in your wallet. The process may take some time.
          </p>
        </div>
        <div className="paymentStatusVerifiedBox">
          <div className="verifiedStatusBox">
            <h5 className="verfiedStatusHeading">Status Verfied</h5>
            <p className="verfiedStatusDiscription">
              We will notify you about the status of the payment verification
            </p>
          </div>
          <div className="invesmentGrowBox">
            <h5 className="investmentGrowHeading">See Your Investment Grow</h5>
            <p className="investmentGrowDiscription">
              Afterwards, You may head over to your portfolio and watch your investments grow!
            </p>
          </div>
        </div>
        <div className="ConfirmPaymentBox">

          <button className='confirm-payment-btn' onClick={handleFileUpload} >
            Confirm Payment
          </button>

        </div>
      </div>
    </div>
  );
}

export default FlexiblePaymentDetails;
