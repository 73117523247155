import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Address.css";
import axios from "axios";
import { API_URL } from '../../config';
import Cookies from 'js-cookie';
import Loading from "../../components/Loading";

function Address() {

  // USE STATES DEFINE
  const [loading, setLoading] = useState(true)
  const [showMyAddress, setShowMyAddress] = useState(true);
  const [address, setAddress] = useState({});
  const [UserAddress, setUserAddress] = useState('');
  const [Country, setCountry] = useState('');
  const [City, setCity] = useState('');


  useEffect(() => {
    loadAddress();
  }, []);


  const loadAddress = async () => {
    const token = Cookies.get("_auth");
    const config = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    };
    try {
      const response = await axios.get(`${API_URL}/api/currentuser`, config);
      setUserAddress(response.data.address);
      setCountry(response.data.country);
      setCity(response.data.city);
    } catch (error) {
      console.log(error);
    } finally{
      setLoading(false)
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const token = Cookies.get("_auth");
      const response = await axios.patch(`${API_URL}/api/updateCurrentUser`, {
        country: Country,
        address: UserAddress,
        city: City
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      alert(response.data.message);
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        if (status === 400 || status === 403 || status === 404 || status === 500) {
          alert(error.response.data.message);
        }
      } else {
        console.error(error);
      }
    }
  }

  const handleAddAddressClick = () => {
    setShowMyAddress(true);
  };

  const handleCancelClick = () => {
    setShowMyAddress(false);
  };

  const handleAddClick = () => {
    const token = Cookies.get("_auth");
    // Make a PATCH request to the API endpoint with the address data and ID
    fetch(`${API_URL}/api/user/2`, {
      method: "PATCH",
      headers: {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(address),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Updated address:", data);
        setShowMyAddress(false);
      })
      .catch((error) => {
        console.error("Error updating address:", error);
      });
  };


  return (
      <>
        <div className="personalDetailMainBox">
          <h4 className="addressHeading">Address</h4>
          <p className="addressDiscription">Your personal information is completely secure and we don't share it to anyone.</p>


          {showMyAddress ? (
            <>
            {loading ? (<>
            <Loading loading={loading} />
            </>):(<>
              <form onSubmit={handleSubmit}>
              <div className="addAddressBox">
                <h5 className="addAddressHeading">Add Your Address From Here</h5>
                <p className="addAddressDiscription">We may from time to time, send you account statements or the information in the post. Please ensure your information is up to date all time.</p>
                <div className="addAddressInputMainBox">
                  <div className="addAddressdataInputBox">
                    <label>Home Address:</label>
                    <input
                      placeholder="address"
                      className="addAddressInputs"
                      name="address"
                      value={UserAddress}
                      onChange={(e) => setUserAddress(e.target.value)}
                    />
                  </div>
                  <div className="addAddressdataInputBox">
                  <label>City:</label>
                  <input
                  required
                    placeholder="City"
                    className="addAddressInputs"
                    name="city"
                    value={City}
                    onChange={(e) => setCity(e.target.value)}
                  />
                  </div>
                  <div className="addAddressdataInputBox">
                  <label>Country:</label>
                  <select name="countery" id="country"className="addAddressInputs" onChange={(e)=> setCountry(e.target.value)} value={Country}>
                    <option value="">Select Country</option>
                    <option value="Pakistan">Pakistan</option>
                  </select>
                  </div>
                </div>

                <div className="addAddressButtonSection">
                  <button className="addAddressButton" onClick={handleCancelClick}>Cancel</button>
                  <button className="addAddressButton" type="submit">Submit</button>
                </div>
              </div>
              </form>
            </>)}
            </>
          ) : (
            <button className="addAddressButton" onClick={handleAddAddressClick}>Add Address</button>
          )}

        </div>
      </>
  );
}

export default Address;

