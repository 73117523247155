import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./PurchaseN.css";
import ElementImg from "../assets/elementR.png";
import StandardImg from "../assets/standard.png";
import WalletImg from "../assets/wallett.png";
import BedImg from "../assets/bed.png";
import WashroomImg from "../assets/washroom.png";
import CarImg from "../assets/car.png";
import ViewImg from "../assets/view.png";

const ApartmentSelector = () => {
  const [selectedOption, setSelectedOption] = useState("");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <>
      <div className="purchaseContanierK">
        <div className="residential"></div>
        <span className="residentialText">Residential</span>
        <div className="commerical"></div>
        <span className="commericalT">Commerical</span>
        <div className="managerial"></div>
        <span className="managerialT">Managerial</span>

        <label>
          <div className="container">
            <img className="elementimg" alt="" src={ElementImg} />
            <div className="containkhar">
              <select
                value={selectedOption}
                className="selectKhar"
                onChange={handleOptionChange}
              >
                <option value="allkharian">ALL</option>
                <option value="allk">Kharian</option>
                <option value="Sak">SAK Tower</option>
              </select>
            </div>

            {/* <select
              value={selectedOption}
              className="selectK"
              onChange={handleOptionChange}
            >
              <option value="">All</option>
              <option value="allk">Kharian</option>
              <option value="Sak">SAK Tower</option>
            </select> */}
            y          </div>
        </label>
      </div>

      {selectedOption === "allk" && (
        <>
          {[...Array(3)].map((_, index) => (
            <div className="footerK1" key={index}>
              <img className="standardimg" alt="" src={StandardImg} />
              <p className="TwobedAppartment">3 Bed Appartment</p>
              <div className="booked">
                <p className="book">BOOKED</p>
                <div className="wallett">
                  <img className="walletimg" alt="" src={WalletImg} />
                  <p className="sqft">679.77sq.ft</p>
                </div>
                <p className="standard">Standard</p>
              </div>
              <div className="twoBedRoom">
                <p className="paratwobedroom"> 2 BedRoom</p>
                <img className="bedimg" alt="" src={BedImg} />
              </div>

              <div className="twoWashroom">
                <p className="paratwobedroom"> 2 Washroom</p>
                <img className="washroomimg" alt="" src={WashroomImg} />
              </div>

              <div className="car">
                <p className="parkingt"> 1 Parking</p>
                <img className="carimg" alt="" src={CarImg} />
              </div>

              <div className="view">
                <p className="viewt"> View</p>
                <img className="viewimg" alt="" src={ViewImg} />
              </div>
              <div className="fortyPercent">
                <p className="fortyPercentPara">-40%</p>
              </div>
              <span className="price">
                <p className="totalPricePara">75.25 Lac</p>
              </span>

              <span className="bookWith10Lac">
                <p className="bookwith10LacPara">Book with 10 Lac</p>
              </span>
            </div>
          ))}
          {[...Array(3)].map((_, index) => (
            <div className="footerK2" key={index}>
              <img className="standardimg" alt="" src={StandardImg} />
              <p className="TwobedAppartment">2 Bed Appartment</p>
              <div className="booked">
                <p className="book">BOOKED</p>
                <div className="wallett">
                  <img className="walletimg" alt="" src={WalletImg} />
                  <p className="sqft">679.77sq.ft</p>
                </div>
                <p className="standard"></p>
              </div>
              <div className="twoBedRoom">
                <p className="paratwobedroom"> 2 BedRoom</p>
                <img className="bedimg" alt="" src={BedImg} />
              </div>

              <div className="twoWashroom">
                <p className="paratwobedroom"> 2 Washroom</p>
                <img className="washroomimg" alt="" src={WashroomImg} />
              </div>

              <div className="car">
                <p className="parkingt"> 1 Parking</p>
                <img className="carimg" alt="" src={CarImg} />
              </div>

              <div className="view">
                <p className="viewt"> View</p>
                <img className="viewimg" alt="" src={ViewImg} />
              </div>
              <div className="fortyPercent">
                <p className="fortyPercentPara">-40%</p>
              </div>
              <span className="price">
                <p className="totalPricePara">75.25 Lac</p>
              </span>

              <span className="bookWith10Lac">
                <p className="bookwith10LacPara">Book with 10 Lac</p>
              </span>
            </div>
          ))}

        </>
      )}
      {selectedOption === "Sak" && (
        <>
          {[...Array(3)].map((_, index) => (
            <div className="footerS1" key={index}>
              <img className="standardimg" alt="" src={StandardImg} />
              <p className="TwobedAppartment">3 Bed Appartment</p>
              <div className="booked">
                <p className="book">BOOKED</p>
                <div className="wallett">
                  <img className="walletimg" alt="" src={WalletImg} />
                  <p className="sqft">679.77sq.ft</p>
                </div>
                <p className="standard">Standard</p>
              </div>
              <div className="twoBedRoom">
                <p className="paratwobedroom"> 1 BedRoom</p>
                <img className="bedimg" alt="" src={BedImg} />
              </div>

              <div className="twoWashroom">
                <p className="paratwobedroom"> 2 Washroom</p>
                <img className="washroomimg" alt="" src={WashroomImg} />
              </div>

              <div className="car">
                <p className="parkingt"> 1 Parking</p>
                <img className="carimg" alt="" src={CarImg} />
              </div>

              <div className="view">
                <p className="viewt"> View</p>
                <img className="viewimg" alt="" src={ViewImg} />
              </div>
              <div className="fortyPercent">
                <p className="fortyPercentPara">-40%</p>
              </div>
              <span className="price">
                <p className="totalPricePara">75.25 Lac</p>
              </span>

              <span className="bookWith10Lac">
                <p className="bookwith10LacPara">Book with 10 Lac</p>
              </span>
            </div>
          ))}
          {[...Array(3)].map((_, index) => (
            <div className="footerK2" key={index}>
              <img className="standardimg" alt="" src={StandardImg} />
              <p className="TwobedAppartment">1 Bed Appartment</p>
              <div className="booked">
                <p className="book">BOOKED</p>
                <div className="wallett">
                  <img className="walletimg" alt="" src={WalletImg} />
                  <p className="sqft">679.77sq.ft</p>
                </div>
                <p className="standard"></p>
              </div>
              <div className="twoBedRoom">
                <p className="paratwobedroom"> 2 BedRoom</p>
                <img className="bedimg" alt="" src={BedImg} />
              </div>

              <div className="twoWashroom">
                <p className="paratwobedroom"> 2 Washroom</p>
                <img className="washroomimg" alt="" src={WashroomImg} />
              </div>

              <div className="car">
                <p className="parkingt"> 1 Parking</p>
                <img className="carimg" alt="" src={CarImg} />
              </div>

              <div className="view">
                <p className="viewt"> View</p>
                <img className="viewimg" alt="" src={ViewImg} />
              </div>
              <div className="fortyPercent">
                <p className="fortyPercentPara">-40%</p>
              </div>
              <span className="price">
                <p className="totalPricePara">75.25 Lac</p>
              </span>

              <span className="bookWith10Lac">
                <p className="bookwith10LacPara">Book with 10 Lac</p>
              </span>
            </div>
          ))}

        </>
      )}


      <label>
        <div className="selcontain">
          <select
            value={selectedOption}
            className="select"
            onChange={handleOptionChange}
          >
            <option value="all">ALL</option>
            <option value="2bed">Standard</option>
            <option value="3bed">Premium</option>
          </select>
        </div>
      </label>

      {selectedOption === "all" && (
        <>

          <div className="footer1">
            <img className="standardimg" alt="" src={StandardImg} />
            <p className="TwobedAppartment">2 Bed Appartment</p>
            <div className="booked">
              <p className="book">BOOKED</p>
              <div className="wallett">
                <img className="walletimg" alt="" src={WalletImg} />
                <p className="sqft">679.77sq.ft</p>
              </div>
              <p className="standard"></p>
            </div>
            <div className="twoBedRoom">
              <p className="paratwobedroom"> 2 BedRoom</p>
              <img className="bedimg" alt="" src={BedImg} />
            </div>

            <div className="twoWashroom">
              <p className="paratwobedroom"> 2 Washroom</p>
              <img className="washroomimg" alt="" src={WashroomImg} />
            </div>

            <div className="car">
              <p className="parkingt"> 1 Parking</p>
              <img className="carimg" alt="" src={CarImg} />
            </div>

            <div className="view">
              <p className="viewt"> View</p>
              <img className="viewimg" alt="" src={ViewImg} />
            </div>
            <div className="fortyPercent">
              <p className="fortyPercentPara">-40%</p>
            </div>
            <span className="price">
              <p className="totalPricePara">75.25 Lac</p>
            </span>

            <span className="bookWith10Lac">
              <p className="bookwith10LacPara">Book with 10 Lac</p>
            </span>
          </div>


        </>
      )}
      {selectedOption === "2bed" && (
        <>
          <div className="footer2">
            <img className="standardimg" alt="" src={StandardImg} />
            <p className="TwobedAppartment">2 Bed Appartment</p>
            <div className="booked">
              <p className="book">BOOKED</p>
              <div className="wallett">
                <img className="walletimg" alt="" src={WalletImg} />
                <p className="sqft">679.77sq.ft</p>
              </div>
              <p className="standard">2 Bed</p>
            </div>
            <div className="twoBedRoom">
              <p className="paratwobedroom"> 2 BedRoom</p>
              <img className="bedimg" alt="" src={BedImg} />
            </div>

            <div className="twoWashroom">
              <p className="paratwobedroom"> 2 Washroom</p>
              <img className="washroomimg" alt="" src={WashroomImg} />
            </div>

            <div className="car">
              <p className="parkingt"> 1 Parking</p>
              <img className="carimg" alt="" src={CarImg} />
            </div>

            <div className="view">
              <p className="viewt"> View</p>
              <img className="viewimg" alt="" src={ViewImg} />
            </div>
            <div className="fortyPercent">
              <p className="fortyPercentPara">-40%</p>
            </div>
            <span className="price">
              <p className="totalPricePara">75.25 Lac</p>
            </span>

            <span className="bookWith10Lac">
              <p className="bookwith10LacPara">Book with 10 Lac</p>
            </span>
          </div>
        </>
      )}



      {selectedOption === "3bed" && (
        <div className="footer3">
          <img className="standardimg" alt="" src={StandardImg} />
          <p className="TwobedAppartment">2 Bed Appartment</p>
          <div className="booked">
            <p className="book">BOOKED</p>
            <div className="wallett">
              <img className="walletimg" alt="" src={WalletImg} />
              <p className="sqft">679.77sq.ft</p>
            </div>
            <p className="premium">3 Bed</p>
          </div>
          <div className="twoBedRoom">
            <p className="paratwobedroom"> 2 BedRoom</p>
            <img className="bedimg" alt="" src={BedImg} />
          </div>

          <div className="twoWashroom">
            <p className="paratwobedroom"> 2 Washroom</p>
            <img className="washroomimg" alt="" src={WashroomImg} />
          </div>

          <div className="car">
            <p className="parkingt"> 1 Parking</p>
            <img className="carimg" alt="" src={CarImg} />
          </div>

          <div className="view">
            <p className="viewt"> View</p>
            <img className="viewimg" alt="" src={ViewImg} />
          </div>
          <div className="fortyPercent">
            <p className="fortyPercentPara">-40%</p>
          </div>
          <span className="price">
            <p className="totalPricePara">75.25 Lac</p>
          </span>

          <span className="bookWith10Lac">
            <p className="bookwith10LacPara">Book with 10 Lac</p>
          </span>
        </div>
      )}

      <label>
        <div className="selectF">
          <select
            value={selectedOption}
            className="selectfloor"
            onChange={handleOptionChange}
          >
            {/* <option value="all">ALL</option> */}
            <option value="allfloors">ALL Floors</option>
            <option value="1st">1st Floor</option>
            <option value="2nd">2nd Floor</option>
            <option value="3rd">3rd Floor</option>
            <option value="4th">4th Floor</option>
          </select>
          {/* <select
            value={selectedOption}
            className="floor"
            onChange={handleOptionChange}
          >
            <option value="allfloors">ALL Floors</option>
            <option value="1st">1st Floor</option>
            <option value="2nd">2nd Floor</option>
            <option value="3rd">3rd Floor</option>
            <option value="4th">4th Floor</option>
          </select> */}
        </div>
      </label>

      {selectedOption === "allfloors" && (
        <div className="footer4">
          <img className="standardimg" alt="" src={StandardImg} />
          <p className="TwobedAppartment">2 Bed Appartment</p>
          <div className="booked">
            <p className="book">BOOKED</p>
            <div className="wallett">
              <img className="walletimg" alt="" src={WalletImg} />
              <p className="sqft">679.77sq.ft</p>
            </div>
            <p className="premium">All Floors</p>
          </div>
          <div className="twoBedRoom">
            <p className="paratwobedroom"> 2 BedRoom</p>
            <img className="bedimg" alt="" src={BedImg} />
          </div>

          <div className="twoWashroom">
            <p className="paratwobedroom"> 2 Washroom</p>
            <img className="washroomimg" alt="" src={WashroomImg} />
          </div>

          <div className="car">
            <p className="parkingt"> 1 Parking</p>
            <img className="carimg" alt="" src={CarImg} />
          </div>

          <div className="view">
            <p className="viewt"> View</p>
            <img className="viewimg" alt="" src={ViewImg} />
          </div>
          <div className="fortyPercent">
            <p className="fortyPercentPara">-40%</p>
          </div>
          <span className="price">
            <p className="totalPricePara">75.25 Lac</p>
          </span>

          <span className="bookWith10Lac">
            <p className="bookwith10LacPara">Book with 10 Lac</p>
          </span>
        </div>
      )}
      {selectedOption === "1st" && (
        <div className="footer5">
          <img className="standardimg" alt="" src={StandardImg} />
          <p className="TwobedAppartment">2 Bed Appartment</p>
          <div className="booked">
            <p className="book">BOOKED</p>
            <div className="wallett">
              <img className="walletimg" alt="" src={WalletImg} />
              <p className="sqft">679.77sq.ft</p>
            </div>
            <p className="premium">1st Floor</p>
          </div>
          <div className="twoBedRoom">
            <p className="paratwobedroom"> 2 BedRoom</p>
            <img className="bedimg" alt="" src={BedImg} />
          </div>

          <div className="twoWashroom">
            <p className="paratwobedroom"> 2 Washroom</p>
            <img className="washroomimg" alt="" src={WashroomImg} />
          </div>

          <div className="car">
            <p className="parkingt"> 1 Parking</p>
            <img className="carimg" alt="" src={CarImg} />
          </div>

          <div className="view">
            <p className="viewt"> View</p>
            <img className="viewimg" alt="" src={ViewImg} />
          </div>
          <div className="fortyPercent">
            <p className="fortyPercentPara">-40%</p>
          </div>
          <span className="price">
            <p className="totalPricePara">75.25 Lac</p>
          </span>

          <span className="bookWith10Lac">
            <p className="bookwith10LacPara">Book with 10 Lac</p>
          </span>
        </div>
      )}
      {selectedOption === "2nd" && (
        <div className="footer6">
          <img className="standardimg" alt="" src={StandardImg} />
          <p className="TwobedAppartment">2 Bed Appartment</p>
          <div className="booked">
            <p className="book">BOOKED</p>
            <div className="wallett">
              <img className="walletimg" alt="" src={WalletImg} />
              <p className="sqft">679.77sq.ft</p>
            </div>
            <p className="premium">2nd Floor</p>
          </div>
          <div className="twoBedRoom">
            <p className="paratwobedroom"> 2 BedRoom</p>
            <img className="bedimg" alt="" src={BedImg} />
          </div>

          <div className="twoWashroom">
            <p className="paratwobedroom"> 2 Washroom</p>
            <img className="washroomimg" alt="" src={WashroomImg} />
          </div>

          <div className="car">
            <p className="parkingt"> 1 Parking</p>
            <img className="carimg" alt="" src={CarImg} />
          </div>

          <div className="view">
            <p className="viewt"> View</p>
            <img className="viewimg" alt="" src={ViewImg} />
          </div>
          <div className="fortyPercent">
            <p className="fortyPercentPara">-40%</p>
          </div>
          <span className="price">
            <p className="totalPricePara">75.25 Lac</p>
          </span>

          <span className="bookWith10Lac">
            <p className="bookwith10LacPara">Book with 10 Lac</p>
          </span>
        </div>
      )}
      {selectedOption === "3rd" && (
        <div className="footer7">
          <img className="standardimg" alt="" src={StandardImg} />
          <p className="TwobedAppartment">2 Bed Appartment</p>
          <div className="booked">
            <p className="book">BOOKED</p>
            <div className="wallett">
              <img className="walletimg" alt="" src={WalletImg} />
              <p className="sqft">679.77sq.ft</p>
            </div>
            <p className="premium">3rd Floor</p>
          </div>
          <div className="twoBedRoom">
            <p className="paratwobedroom"> 2 BedRoom</p>
            <img className="bedimg" alt="" src={BedImg} />
          </div>

          <div className="twoWashroom">
            <p className="paratwobedroom"> 2 Washroom</p>
            <img className="washroomimg" alt="" src={WashroomImg} />
          </div>

          <div className="car">
            <p className="parkingt"> 1 Parking</p>
            <img className="carimg" alt="" src={CarImg} />
          </div>

          <div className="view">
            <p className="viewt"> View</p>
            <img className="viewimg" alt="" src={ViewImg} />
          </div>
          <div className="fortyPercent">
            <p className="fortyPercentPara">-40%</p>
          </div>
          <span className="price">
            <p className="totalPricePara">75.25 Lac</p>
          </span>

          <span className="bookWith10Lac">
            <p className="bookwith10LacPara">Book with 10 Lac</p>
          </span>
        </div>
      )}
      {selectedOption === "4th" && (
        <div className="footer8">
          <img className="standardimg" alt="" src={StandardImg} />
          <p className="TwobedAppartment">2 Bed Appartment</p>
          <div className="booked">
            <p className="book">BOOKED</p>
            <div className="wallett">
              <img className="walletimg" alt="" src={WalletImg} />
              <p className="sqft">679.77sq.ft</p>
            </div>
            <p className="premium">4th Floor</p>
          </div>
          <div className="twoBedRoom">
            <p className="paratwobedroom"> 2 BedRoom</p>
            <img className="bedimg" alt="" src={BedImg} />
          </div>

          <div className="twoWashroom">
            <p className="paratwobedroom"> 2 Washroom</p>
            <img className="washroomimg" alt="" src={WashroomImg} />
          </div>

          <div className="car">
            <p className="parkingt"> 1 Parking</p>
            <img className="carimg" alt="" src={CarImg} />
          </div>

          <div className="view">
            <p className="viewt"> View</p>
            <img className="viewimg" alt="" src={ViewImg} />
          </div>
          <div className="fortyPercent">
            <p className="fortyPercentPara">-40%</p>
          </div>
          <span className="price">
            <p className="totalPricePara">75.25 Lac</p>
          </span>

          <span className="bookWith10Lac">
            <p className="bookwith10LacPara">Book with 10 Lac</p>
          </span>
        </div>
      )}
      <label>
        <div className="selectbeds">
          <select
            value={selectedOption}
            className="selectbed"
            onChange={handleOptionChange}>
            {/* <select
            value={selectedOption}
            className="bedsapp"
            onChange={handleOptionChange}
          > */}

            <option value="allbeds">Select Bed</option>
            <option value="1bed">1 Bed</option>
            <option value="2bed">2 Bed</option>
            <option value="3bed">3 Bed</option>
            <option value="4bed">4 Bed</option>
          </select>
        </div>
      </label>
      {selectedOption === "allbeds" && (
        <div className="footer9">
          <img className="standardimg" alt="" src={StandardImg} />
          <p className="TwobedAppartment">2 Bed Appartment</p>
          <div className="booked">
            <p className="book">BOOKED</p>
            <div className="wallett">
              <img className="walletimg" alt="" src={WalletImg} />
              <p className="sqft">679.77sq.ft</p>
            </div>
            <p className="premium"></p>
          </div>
          <div className="twoBedRoom">
            <p className="paratwobedroom"> 2 BedRoom</p>
            <img className="bedimg" alt="" src={BedImg} />
          </div>

          <div className="twoWashroom">
            <p className="paratwobedroom"> 2 Washroom</p>
            <img className="washroomimg" alt="" src={WashroomImg} />
          </div>

          <div className="car">
            <p className="parkingt"> 1 Parking</p>
            <img className="carimg" alt="" src={CarImg} />
          </div>

          <div className="view">
            <p className="viewt"> View</p>
            <img className="viewimg" alt="" src={ViewImg} />
          </div>
          <div className="fortyPercent">
            <p className="fortyPercentPara">-40%</p>
          </div>
          <span className="price">
            <p className="totalPricePara">75.25 Lac</p>
          </span>

          <span className="bookWith10Lac">
            <p className="bookwith10LacPara">Book with 10 Lac</p>
          </span>
        </div>
      )}
      {selectedOption === "1bed" && (
        <div className="footer10">
          <img className="standardimg" alt="" src={StandardImg} />
          <p className="TwobedAppartment">2 Bed Appartment</p>
          <div className="booked">
            <p className="book">BOOKED</p>
            <div className="wallett">
              <img className="walletimg" alt="" src={WalletImg} />
              <p className="sqft">679.77sq.ft</p>
            </div>
            <p className="premium">1 Bed</p>
          </div>
          <div className="twoBedRoom">
            <p className="paratwobedroom"> 2 BedRoom</p>
            <img className="bedimg" alt="" src={BedImg} />
          </div>

          <div className="twoWashroom">
            <p className="paratwobedroom"> 2 Washroom</p>
            <img className="washroomimg" alt="" src={WashroomImg} />
          </div>

          <div className="car">
            <p className="parkingt"> 1 Parking</p>
            <img className="carimg" alt="" src={CarImg} />
          </div>

          <div className="view">
            <p className="viewt"> View</p>
            <img className="viewimg" alt="" src={ViewImg} />
          </div>
          <div className="fortyPercent">
            <p className="fortyPercentPara">-40%</p>
          </div>
          <span className="price">
            <p className="totalPricePara">75.25 Lac</p>
          </span>

          <span className="bookWith10Lac">
            <p className="bookwith10LacPara">Book with 10 Lac</p>
          </span>
        </div>
      )}

    </>
  );
};
export default ApartmentSelector;
