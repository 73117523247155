import React from "react";
import "./UserPortfolio.css";



const Reports = () => {
  return (
    <>
      {/* MAIN SECTION START FROM HERE */}
      <div className="report-main">

        <div className="report-main-section">
          {/* CARD 1 */}
          <div className="report-card-section">
            <div className="report-paragraph">
              <p className="report-para">
                View And Download Reports Of your Investments from here.
              </p>
              <p className="report-para">  Reports update after each Quarter</p>
            </div>
          </div>
        </div>
      </div>

      {/* REPORT TABLE SECTION */}
      <div className="report-table-container">
        <div className="report-table-header">
          <div className="report-cell" >Date Of Order</div>
          <div className="report-cell"  >Order Id</div>
          <div className="report-cell" >Project Name</div>
          <div className="report-cell" >Report Date</div>
          <div className="report-cell" >Download Report </div>
        </div>
        {/* <div className="report-table-row">
          <div className="report-cell">05/10/2020</div>
          <div className="report-cell">#SAK123</div>
          <div className="report-cell">SAK TOWER</div>
          <div className="report-cell">03</div>
          <div className="report-cell">Click Here</div>

        </div> */}
        {/* <div className="report-table-row">
          <div className="report-cell">01/10/2019</div>
          <div className="report-cell">#SAK123</div>
          <div className="report-cell">SAK TOWER</div>
          <div className="report-cell">04</div>
          <div className="report-cell">Click Here</div>
        </div> */}
        {/* <div className="report-table-row">
          <div className="report-cell">04/06/2020</div>
          <div className="report-cell">#SAK123</div>
          <div className="report-cell">SAK TOWER</div>
          <div className="report-cell">05</div>
          <div className="report-cell">Click Here</div>
        </div> */}
        {/* <div className="report-table-row">
          <div className="report-cell"></div>
          <div className="report-cell"></div>
          <div className="report-cell"></div>
          <div className="report-cell"></div>
          <div className="report-cell"></div>
          <div className="report-cell"></div>
          <div className="report-cell"></div>
        </div> */}
      </div>

    </>
  );
};

export default Reports;
