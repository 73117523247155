import React from 'react'
import { useParams, Link, useNavigate} from 'react-router-dom';
import { useSignIn } from 'react-auth-kit';
import Cookies from 'js-cookie';
import { useState , useEffect} from 'react';

const GoogleDashboard = () => {
    const { token } = useParams();
    const navigate = useNavigate()
    const signIn = useSignIn();

    useEffect(() => {
        
        signIn({
            token: token,
            expiresIn: 3600,
            tokenType:"Bearer",
            authState: "email",
    
          })
          Cookies.set('_auth', token);
          navigate('/dashboard');
       
      }, []);
    

    // console.log(token);


  return (
    <>
    <div className="googleDahboardMainBox">
    </div>
    
    </>
  )
}

export default GoogleDashboard